import React from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';

const RecoverPassword = () => {
    const onFinish = async (values) => {
        try {
            await axios.post('https://api.hireveda.com/recover-password', values);
            message.success('Password recovery email sent. Please check your email.');
        } catch (error) {
            message.error('Password recovery failed: ' + (error.response?.data?.message || error.message));
        }
    };

    return (
        <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
            <h2>Password Recovery</h2>
            <Form onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="email"
                    rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Recover Password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default RecoverPassword;
