import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

const EmailFilters = ({ onApplyFilters }) => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [dateRange, setDateRange] = useState('');

  const handleApply = () => {
    onApplyFilters({ from, to, subject, dateRange });
  };

  return (
    <Box p={3}>
      <Typography variant="h6">Filter Emails</Typography>
      <TextField
        label="From"
        fullWidth
        margin="normal"
        value={from}
        onChange={(e) => setFrom(e.target.value)}
      />
      <TextField
        label="To"
        fullWidth
        margin="normal"
        value={to}
        onChange={(e) => setTo(e.target.value)}
      />
      <TextField
        label="Subject"
        fullWidth
        margin="normal"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <TextField
        label="Date Range"
        type="date"
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: true }}
        value={dateRange}
        onChange={(e) => setDateRange(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleApply}>
        Apply Filters
      </Button>
    </Box>
  );
};

export default EmailFilters;
