// client.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ApplyPage from './Jobs/ApplyPage'; // Import the same ApplyPage

ReactDOM.hydrate(
  <Router>
    <Routes>
      <Route path="/apply/:roleName" element={<ApplyPage />} />
    </Routes>
  </Router>,
  document.getElementById('root') 
);  // Render the ApplyPage component