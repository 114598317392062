import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const roles = ['Admin', 'Recruiter', 'Interviewer','Leads','Client','Associate','Manager']; // Example roles, replace with actual roles

const StatusManagement = () => {
  const [statuses, setStatuses] = useState([]);
  const [existingStages, setExistingStages] = useState([]);
  const [existingChangeOptions, setExistingChangeOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({
    name: '',
    stage: [],
    allowedRoles: [],
    changeOptions: [],
  });

  useEffect(() => {
    fetchStatuses();
  }, []);

  const fetchStatuses = async () => {
    try {
      const response = await axios.get('/api/status');
      const updatedStatuses = response.data.map(status => ({
        ...status,
        stage: Array.isArray(status.stage) ? status.stage : [status.stage],
      }));
      setStatuses(updatedStatuses);
      const stages = [...new Set(response.data.map(status => status.stage))];
      const changeOptions = [...new Set(response.data.flatMap(status => status.status))];
      setExistingStages(stages);
      setExistingChangeOptions(changeOptions);
    } catch (err) {
      console.error('Error fetching statuses:', err);
    }
  };

  const handleChange = (field) => (event) => {
    setSelectedStatus({ ...selectedStatus, [field]: event.target.value });
  };

  const handleSave = async () => {
    try {
      const statusToSave = {
        ...selectedStatus,
        stage: Array.isArray(selectedStatus.stage) ? selectedStatus.stage[0] : selectedStatus.stage,
      };

      if (selectedStatus._id) {
        // Update existing status
        await axios.put(`/api/status/${selectedStatus._id}`, statusToSave);
      } else {
        // Create new status
        await axios.post('/api/status', statusToSave);
      }
      setSelectedStatus({ name: '', stage: [], allowedRoles: [], changeOptions: [] });
      fetchStatuses();
    } catch (err) {
      console.error('Error saving status:', err);
    }
  };

  const handleEdit = (status) => {
    setSelectedStatus(status);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/status/${id}`);
      fetchStatuses();
    } catch (err) {
      console.error('Error deleting status:', err);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Status Management
      </Typography>
      <Box component="form" sx={{ mb: 3 }} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Status Name"
              value={selectedStatus.name}
              onChange={handleChange('name')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Stage</InputLabel>
              <Select
                multiple={false}
                value={selectedStatus.stage}
                onChange={handleChange('stage')}
                input={<OutlinedInput label="Stage" />}
                renderValue={(selected) => selected}
                MenuProps={MenuProps}
              >
                {existingStages.map((stage) => (
                  <MenuItem key={stage} value={stage}>
                    <Checkbox checked={selectedStatus.stage.includes(stage)} />
                    <ListItemText primary={stage} />
                  </MenuItem>
                ))}
              </Select>
              <TextField
                fullWidth
                label="Add New Stage"
                placeholder="Enter new stage"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    const newStage = e.target.value.trim();
                    if (newStage && !existingStages.includes(newStage)) {
                      setExistingStages([...existingStages, newStage]);
                      e.target.value = ''; // Clear input
                    }
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Allowed Roles</InputLabel>
              <Select
                multiple
                value={selectedStatus.allowedRoles}
                onChange={handleChange('allowedRoles')}
                input={<OutlinedInput label="Allowed Roles" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    <Checkbox checked={selectedStatus.allowedRoles.includes(role)} />
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Change Options</InputLabel>
              <Select
                multiple
                value={selectedStatus.changeOptions}
                onChange={handleChange('changeOptions')}
                input={<OutlinedInput label="Change Options" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {statuses.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    <Checkbox checked={selectedStatus.changeOptions.includes(option.name)} />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              {selectedStatus._id ? 'Update' : 'Add'} Status
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h6" gutterBottom>
        Existing Statuses
      </Typography>
      <Box>
        {statuses.map((status) => (
          <Box key={status._id} sx={{ mb: 2, borderBottom: '1px solid #ccc', pb: 2 }}>
            <Typography variant="subtitle1">{status.name}</Typography>
            <Typography variant="body2">Stage: {Array.isArray(status.stage) ? status.stage.join(', ') : status.stage}</Typography>
            <Typography variant="body2">Allowed Roles: {status.allowedRoles.join(', ')}</Typography>
            <Typography variant="body2">Change Options: {status.changeOptions.join(', ')}</Typography>
            <Box sx={{ mt: 1 }}>
              <Button size="small" onClick={() => handleEdit(status)}>
                Edit
              </Button>
              <Button size="small" color="error" onClick={() => handleDelete(status._id)}>
                Delete
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default StatusManagement;
