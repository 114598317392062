import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Box,
  Container,
  Drawer,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,Autocomplete,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { VariableSizeList } from 'react-window';
import { createEditor } from 'slate';
function RoleManagement() {
  const [roles, setRoles] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState({
    RoleName: '',
    Location: '',
    Skills: [],
    Budget: '',
    RoleType: '',
    Status: 'Active',
    POC: [{ Name: '', Email: '', Role: '' }],
    Client: '', // Change to simple text
    Openings: '',
    SourcingCriteria: '',
    RejectionFeedback: '',
    CandidateFeedback: '',
    Exclusive: false,
    TargetIndustry: '',
    Details: '',
    JD: '',
    Assessment: [{ Question: '', type: 'Descriptive', options: []}],
  });

  const [clients, setClients] = useState([]);
  const [skills, setSkills] = useState([]);
  const [editMode, setEditMode] = useState(false);

  // In the state, JD will store the Slate editor's value (as a JSON structure)
const [value, setValue] = useState([
  {
    type: 'paragraph',
    children: [{ text: 'A line of text in a paragraph.' }],
  },
]);


  // If JD is available, update the Slate editor's value
  useEffect(() => {
    if (selectedRole.JD) {
      setValue(selectedRole.JD);
    }
  }, [selectedRole.JD]);

  // Update JD value
  const handleJDChange = (newValue) => {
    setValue(newValue);
    setSelectedRole((prevRole) => ({ ...prevRole, JD: newValue }));
  };
  
  useEffect(() => {
    fetchRoles();
    fetchSkills();
  }, []);

  const fetchRoles = async () => {
    try {
      const result = await axios.get('/api/role'); // Your endpoint for fetching roles
      setRoles(result.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const fetchSkills = async () => {
    try {
      const result = await axios.get('/api/skills');
      setSkills(result.data.map(skill => skill.name));
    } catch (error) {
      console.error('Error fetching skills:', error);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedRole({ ...selectedRole, [name]: value });
  };
  const handleAddPOC = () => {
    // Add a new POC entry to the selectedRole state
    setSelectedRole((prevRole) => ({
      ...prevRole,
      POC: [...prevRole.POC, { Name: '', Email: '', Role: '' }],
    }));
  };
  
  const handleAddAssessment = () => {
    // Add a new Assessment entry to the selectedRole state
    setSelectedRole((prevRole) => ({
      ...prevRole,
      Assessment: [
        ...prevRole.Assessment,
        { Question: '', type: 'Descriptive' }, // Default new assessment entry
      ],
    }));
  };
  
  const handleEdit = (role) => {
    setEditMode(true);
    setSelectedRole({
      ...role,
      JD: role.JD || [{ type: 'paragraph', children: [{ text: '' }] }], // Ensure JD is always initialized correctly
      Skills: role.Skills || [],
      POC: role.POC || [{ Name: '', Email: '', Role: '' }],
      Assessment: role.Assessment || [{ Question: '', type: 'Descriptive' }],
    });
    setDrawerOpen(true);
  };
  
  const handleAddNew = () => {
    setEditMode(false);
    setSelectedRole({
      RoleName: '',
      Location: '',
      Skills: [],
      Budget: '',
      RoleType: '',
      Status: 'Active',
      POC: [{ Name: '', Email: '', Role: '' }],
      Client: '', // Change to simple text
      Openings: '',
      SourcingCriteria: '',
      RejectionFeedback: '',
      CandidateFeedback: '',
      Exclusive: false,
      TargetIndustry: '',
      Details: '',
      Assessment: [{ Question: '', type: 'Descriptive' }],
    });
    setDrawerOpen(true);
  };

  const handleAddOrUpdateRole = async () => {
    const method = editMode ? 'put' : 'post';
    const url = `/api/role${editMode ? `/${selectedRole._id}` : ''}`;
    try {
      await axios[method](url, selectedRole);
      fetchRoles();
      setDrawerOpen(false);
    } catch (error) {
      console.error('Error managing role:', error.response ? error.response.data : error.message);
    }
  };

  const syncRoles = async () => {
    try {
      await axios.post('/api/sync-roles'); // Trigger the backend sync
      fetchRoles(); // Refresh roles after sync
    } catch (error) {
      console.error('Error syncing roles:', error.message);
    }
  };

  const handlePOCChange = (index, field, value) => {
    const newPOC = [...selectedRole.POC];
    newPOC[index][field] = value;
    setSelectedRole({ ...selectedRole, POC: newPOC });
  };


  const handleRemovePOC = (index) => {
    const newPOC = selectedRole.POC.filter((_, i) => i !== index);
    setSelectedRole({ ...selectedRole, POC: newPOC });
  };

  const handleAssessmentChange = (index, field, value) => {
    const newAssessment = [...selectedRole.Assessment];
    if (field === 'options') {
      newAssessment[index].options = typeof value === 'string' ? value.split('\n') : [];
    } else {
      newAssessment[index][field] = value;
    }
    setSelectedRole({ ...selectedRole, Assessment: newAssessment });
  };

  const handleRemoveAssessment = (index) => {
    const newAssessment = selectedRole.Assessment.filter((_, i) => i !== index);
    setSelectedRole({ ...selectedRole, Assessment: newAssessment });
  };

  // Virtualized List Component
  const LISTBOX_PADDING = 8; // px
  const renderRow = (props) => {
    const { data, index, style } = props;
    const dataSet = data[index] || [{}, { label: 'Unknown' }];
    const inlineStyle = {
      ...style,
      top: style.top + LISTBOX_PADDING,
      whiteSpace: 'nowrap', // Use CSS for no wrap
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };

    return (
      <MenuItem
        {...dataSet[0]}
        style={inlineStyle}
      >
        {dataSet[1].label}
      </MenuItem>
    );
  };
/*
  const OuterElementContext = React.createContext({});
  const outerProps = React.useContext(OuterElementContext);
  const OuterElementType = React.forwardRef((props, ref) => {
      const outerProps = React.useContext(OuterElementContext);
      return <div ref={ref} {...props} {...outerProps} />;
    });
*/
  const useResetCache = (data) => {
    const ref = React.useRef(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  };

  const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;
    const itemSize = 36;

    const getChildSize = (child) => {
      if (child.hasOwnProperty('group')) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType="ul"
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </div>
    );
    /*
    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );*/
  });

  const roleForm = (
    <Box sx={{ width: 350, p: 2 }}>
      <Typography variant="h6">{editMode ? 'Edit Role' : 'Add New Role'}</Typography>
      <TextField
        fullWidth
        margin="normal"
        label="Role Name"
        name="RoleName"
        value={selectedRole.RoleName}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Location"
        name="Location"
        value={selectedRole.Location}
        onChange={handleInputChange}
      />
      <Autocomplete
        multiple
        options={skills}
        getOptionLabel={(option) => option}
        value={selectedRole.Skills}
        onChange={(event, newValue) => setSelectedRole({ ...selectedRole, Skills: newValue })}
        renderInput={(params) => <TextField {...params} label="Skills" margin="normal" />}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Budget"
        name="Budget"
        type="number"
        value={selectedRole.Budget}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Client"
        name="Client"
        value={selectedRole.Client}
        onChange={handleInputChange}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Role Type</InputLabel>
        <Select
          name="RoleType"
          value={selectedRole.RoleType}
          onChange={handleInputChange}
        >
          <MenuItem value="Technology">Technology</MenuItem>
          <MenuItem value="Analytics">Analytics</MenuItem>
          <MenuItem value="Product">Product</MenuItem>
          <MenuItem value="Design">Design</MenuItem>
          <MenuItem value="Sales/Marketing">Sales/Marketing</MenuItem>
          <MenuItem value="Finance/IB">Finance/IB</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Status</InputLabel>
        <Select
          name="Status"
          value={selectedRole.Status}
          onChange={handleInputChange}
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        margin="normal"
        label="Openings"
        name="Openings"
        type="number"
        value={selectedRole.Openings}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Sourcing Criteria"
        name="SourcingCriteria"
        value={selectedRole.SourcingCriteria}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Rejection Feedback"
        name="RejectionFeedback"
        value={selectedRole.RejectionFeedback}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Candidate Feedback"
        name="CandidateFeedback"
        value={selectedRole.CandidateFeedback}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Target Industry"
        name="TargetIndustry"
        value={selectedRole.TargetIndustry}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Details"
        name="Details"
        value={selectedRole.Details}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Job Description"
        name="JD"
        multiline
        rows={4}
        value={JSON.stringify(selectedRole.JD)}
        onChange={(e) => handleJDChange(JSON.parse(e.target.value))}
      />

      <Typography variant="subtitle1" gutterBottom>POC</Typography>
      {selectedRole.POC.map((poc, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            value={poc.Name}
            onChange={(e) => handlePOCChange(index, 'Name', e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            value={poc.Email}
            onChange={(e) => handlePOCChange(index, 'Email', e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Role</InputLabel>
            <Select
              value={poc.Role}
              onChange={(e) => handlePOCChange(index, 'Role', e.target.value)}
            >
              <MenuItem value="Partner">Partner</MenuItem>
              <MenuItem value="Delivery Lead">Delivery Lead</MenuItem>
              <MenuItem value="Consultant">Consultant</MenuItem>
              <MenuItem value="HR">HR</MenuItem>
              <MenuItem value="Hiring Manager">Hiring Manager</MenuItem>
              <MenuItem value="Interviewer">Interviewer</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={() => handleRemovePOC(index)}>Remove POC</Button>
        </Box>
      ))}
      <Button onClick={handleAddPOC}>Add POC</Button>

      <Typography variant="subtitle1" gutterBottom>Assessment</Typography>
      {selectedRole.Assessment && selectedRole.Assessment.map((assessment, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <TextField
            fullWidth
            margin="normal"
            label="Question"
            value={assessment.Question}
            onChange={(e) => handleAssessmentChange(index, 'Question', e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Type</InputLabel>
            <Select
              value={assessment.type}
              onChange={(e) => handleAssessmentChange(index, 'type', e.target.value)}
            >
              <MenuItem value="Descriptive">Descriptive</MenuItem>
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="Multi Select">Multi Select</MenuItem>
              <MenuItem value="Check Box (Y/N)">Check Box (Y/N)</MenuItem>
            </Select>
          </FormControl>
          {['Select', 'Multi Select'].includes(assessment.type) && (
            <TextField
              fullWidth
              margin="normal"
              label="Options (one per line)"
              multiline
              rows={4}
              value={(assessment.options || []).join('\n')}
              onChange={(e) => {
                const newOptions = e.target.value.split('\n');
                handleAssessmentChange(index, 'options', newOptions);
              }}
            />
          )}
          <Button onClick={() => handleRemoveAssessment(index)}>Remove Assessment</Button>
        </Box>
      ))}
      <Button onClick={handleAddAssessment}>Add Assessment Question</Button>
      <Button variant="contained" color="primary" onClick={handleAddOrUpdateRole}>
        {editMode ? 'Update' : 'Add'} Role
      </Button>
    </Box>
  );


  const columns = [
    { field: 'RoleName', headerName: 'Role Name', width: 150 },
    { field: 'Location', headerName: 'Location', width: 120 },
    {
      field: 'Skills',
      headerName: 'Skills',
      width: 150
    },
    { field: 'Budget', headerName: 'Budget', type: 'number', width: 100 },
    { field: 'RoleType', headerName: 'Role Type', width: 150 },
    { field: 'Status', headerName: 'Status', width: 120 },
    {
      field: 'Client',
      headerName: 'Client',
      width: 150
    
    },
    { field: 'Openings', headerName: 'Openings', type: 'number', width: 100 },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Button onClick={() => handleEdit(params.row)}>Edit</Button>
      ),
    },
  ];
  
  return  roles.length > 0 ? (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h4">Role Management</Typography>
        <Button variant="contained" onClick={handleAddNew}>Add New Role</Button>
      </Box>
      <Button variant="contained" color="secondary" onClick={syncRoles}>
        Sync Roles
      </Button>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {roleForm}
      </Drawer>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={roles.map((role, index) => ({ ...role, id: role._id || index }))}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
    </Container>
  ) : (
    <p>No roles available.</p>
  );
}

export default RoleManagement;
