import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, Divider } from '@mui/material';
import ComposeEmail from './ComposeEmail';
import AnalyticsDashboard from './AnalyticsDashboard';
import EmailThread from './EmailThread';
import EmailFilters from './EmailFilters';
import axios from 'axios';

const EmailCampaignDashboard = () => {
  const [emails, setEmails] = useState([]);
  const [filters, setFilters] = useState({});
  const [analyticsData, setAnalyticsData] = useState([]);

  // Fetch emails with filters
  const fetchEmails = async () => {
    try {
      const response = await axios.get('/api/emails', { params: filters });
      setEmails(response.data.emails || []);
    } catch (error) {
      console.error('Error fetching emails:', error);
    }
  };

  // Fetch analytics data
  const fetchAnalytics = async () => {
    try {
      const response = await axios.get('/api/emails/analytics');
      setAnalyticsData(response.data.analytics || []);
    } catch (error) {
      console.error('Error fetching analytics:', error);
    }
  };

  // Apply filters to emails
  const handleApplyFilters = (filterValues) => {
    setFilters(filterValues);
    fetchEmails();
  };

  // Effect to fetch emails and analytics on load
  useEffect(() => {
    fetchEmails();
    fetchAnalytics();
  }, []);

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Email Campaign Dashboard
      </Typography>
      <Divider />

      {/* Compose Email Section */}
      <Box mt={4}>
        <Typography variant="h5">Compose Email</Typography>
        <ComposeEmail />
      </Box>

      {/* Analytics Section */}
      <Box mt={4}>
        <Typography variant="h5">Analytics</Typography>
        <AnalyticsDashboard data={analyticsData} />
      </Box>

      {/* Filters and Email Threads */}
      <Grid container spacing={4} mt={4}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Filters</Typography>
          <EmailFilters onApplyFilters={handleApplyFilters} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h6">Email Threads</Typography>
          <EmailThread emails={emails} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailCampaignDashboard;
