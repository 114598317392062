// CandidateDrawer.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  List,ListItem,
  Avatar,
  Divider,
  Typography,
  Button,
  Chip,
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import {
  LinkedIn,
  Phone,
  Mail,
} from '@mui/icons-material';
import CompanyLogo from '../CompanyLogo';

const CandidateDrawer = ({ visible = false, onClose = () => {}, candidate }) => {
  const [expandedDesc, setExpandedDesc] = useState({});
  if (!candidate) return null;

  const formatDate = (dateStr) => dateStr ? new Date(dateStr).toLocaleDateString('en-US', { month: 'long', year: 'numeric' }) : 'Present';

  const toggleExpand = (key) => {
    setExpandedDesc((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const renderDescription = (text, key) => {
    const isExpanded = expandedDesc[key];
    const MAX_LENGTH = 100; // Maximum characters before showing "Show More"

    if (typeof text !== 'string') {
      return <Typography>No description available.</Typography>;
    }

    if (text.length <= MAX_LENGTH || isExpanded) {
      return <Typography>{text.replace('\n','<br>')}</Typography>;
    }
    return (
      <Typography>
        {text.substring(0, MAX_LENGTH)}...
        <Button onClick={() => toggleExpand(key)}>Show More</Button>
      </Typography>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={visible}
      onClose={onClose}
      PaperProps={{ style: { width: 480 } }}
    >
      <Box textAlign="center" m={2}>
        <Avatar src={candidate.dp || '/default_avatar.png'} sx={{ width: 64, height: 64, margin: 'auto' }} />
        <Typography variant="h4">{candidate.name}</Typography>
        <Typography variant="body2" color="textSecondary">{candidate.headline}</Typography>
        {candidate.experience_yrs && (
          <Typography variant="body2" color="textSecondary" sx={{ display: 'block', marginTop: 1 }}>
            {Math.floor(candidate.experience_yrs)} yr {Math.round(candidate.experience_yrs % 1 * 12)} mo
          </Typography>
        )}
      </Box>
      <Divider />
      <List>
        <ListItem>
          <IconButton>
            <Mail />
          </IconButton>
          <Typography>{candidate.email}</Typography>
        </ListItem>
        <ListItem>
          <IconButton>
            <Phone />
          </IconButton>
          <Typography>{candidate.phone}</Typography>
        </ListItem>
        <ListItem>
          <Typography variant="h5">Links</Typography>
          {candidate.LinkedIn && (
            <a href={candidate.LinkedIn} target="_blank" rel="noopener noreferrer">{candidate.Linkedin}</a>
          )}
        </ListItem>
        {candidate.links && candidate.links.map((link, index) => (
          <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
            <IconButton>
              <LinkedIn />
            </IconButton>
          </a>
        ))}
      </List>
      <Divider />
      {candidate.experience && candidate.experience.length > 0 && (
        <>
          <Typography variant="h5">Experience</Typography>
          {candidate.experience.map((exp, index) => (
            <Box key={index} mb={2}>
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <CompanyLogo companyName={exp.organization} style={{ width: '40px', marginRight: '10px' }} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body1" component="div"><strong>{exp.designation}</strong> {exp.organization}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    ({formatDate(exp.startDate)} - {formatDate(exp.endDate)})
                  </Typography>
                </Grid>
              </Grid>
              {renderDescription(exp.description, `exp-${index}`)}
              <Box mt={1}>
                {exp.skills && exp.skills.map((skill) => (
                  <Chip key={skill} label={skill} style={{ marginRight: '5px' }} />
                ))}
              </Box>
            </Box>
          ))}
          <Divider />
        </>
      )}
      {candidate.education && candidate.education.length > 0 && (
        <>
          <Typography variant="h5">Education</Typography>
          {candidate.education.map((edu, index) => (
            <Box key={index} mb={2}>
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <CompanyLogo companyName={edu.institution} style={{ width: '40px', marginRight: '10px' }} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body1" component="div"><strong>{edu.degree}</strong> from {edu.institution}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    ({formatDate(edu.startDate)} - {formatDate(edu.endDate)})
                  </Typography>
                </Grid>
              </Grid>
              {renderDescription(edu.description, `edu-${index}`)}
            </Box>
          ))}
          <Divider />
        </>
      )}
      {candidate.resume_text && (
        <>
          <Typography variant="h5">Resume</Typography>
          <Typography variant="body2" color="textSecondary">
            <pre>{candidate.resume_text}</pre>
          </Typography>
          <Divider />
        </>
      )}
      {candidate.skills && candidate.skills.length > 0 && (
        <>
          <Typography variant="h5">Skills</Typography>
          <Box>
            {candidate.skills.map((skill) => (
              <Chip key={skill} label={skill} color="primary" style={{ margin: '2px' }} />
            ))}
          </Box>
        </>
      )}
      {candidate.resume_text && (
        <>
          <Typography variant="h5">CV Text</Typography>
          <Box>
            {candidate.resume_text}
          </Box>
        </>
      )}
      { true && (
        <>
      <Divider />
      <Box m={2}>
        <Typography variant="h5">Candidate JSON</Typography>
        <pre>{JSON.stringify(candidate, null, 2)}</pre>
      </Box>
      </>
      )}
    </Drawer>
  );
};
CandidateDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  candidate: PropTypes.shape({
    dp: PropTypes.string,
    name: PropTypes.string,
    headline: PropTypes.string,
    experience_yrs: PropTypes.number,
    email: PropTypes.string,
    phone: PropTypes.string,
    LinkedIn: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
    })),
    experience: PropTypes.arrayOf(PropTypes.shape({
      organization: PropTypes.string,
      designation: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      description: PropTypes.string,
      skills: PropTypes.arrayOf(PropTypes.string),
    })),
    education: PropTypes.arrayOf(PropTypes.shape({
      institution: PropTypes.string,
      degree: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      description: PropTypes.string,
    })),
    resume_text: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default CandidateDrawer;