// src/analytics.js
import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-PPBN9RBQ8L');
};

export const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};
