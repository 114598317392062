// HeaderMenu.js
import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Collapse, Menu, MenuItem,Divider, Box, Typography  } from '@mui/material';
import { Menu as MenuIcon, AccountCircle, Brightness4, Logout, ExpandLess, ExpandMore } from '@mui/icons-material';
import routeConfig from '../routeConfig';

const HeaderMenu = ({ user, handleLogout, toggleDarkMode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubmenus, setOpenSubmenus] = useState({}); // Tracks submenu open state

  const [openMenuKey, setOpenMenuKey] = React.useState(null); // Track the open menu key
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const openUserMenu = (event) => setAnchorEl(event.currentTarget);
  const closeUserMenu = () => setAnchorEl(null);
  const location = useLocation(); // Get current location

  
  // Filter menu items based on user's role
  const filteredMenuItems = routeConfig.filter(route => route.menu && route.roles.includes(user?.role));

  // Toggle submenu visibility
  const toggleSubmenu = (key) => {
    setOpenSubmenus(prev => ({ ...prev, [key]: !prev[key] }));
  };


  const handleMenuOpen = (event, key) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuKey(key); // Set the key of the opened menu
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenuKey(null); // Reset the key when closing the menu
  };
  
  return (
    <>
      {/* Top Navigation Bar */}
      <AppBar position="static" style={{ padding: '0' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <img
            src="https://hireveda.com/content/images/2024/02/HV-Logo-White.png"
            alt="Hireveda Logo"
            style={{ height: '20px', marginRight: 'auto' }}
          />


<Box sx={{ display: "flex", gap: 2 }}>
  {filteredMenuItems.map((item, index) => {
    const isActive = location.pathname === item.path;

    return (
      <React.Fragment key={`${item.key}-${index}`}>
        <Box>
          {/* Menu Item */}
          <IconButton
            color={isActive ? "primary" : "inherit"} // Highlight icon if active
            onClick={(e) => (item.children ? handleMenuOpen(e, item.key) : null)}
            component={!item.children ? Link : "button"}
            to={!item.children ? item.path : undefined}
            sx={{
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "&:hover": { color: "primary.main" }, // Highlight on hover
            }}
          >
            {/* Render Icon */}
            {item.menu?.icon &&
              React.createElement(item.menu.icon, {
                style: {
                  fontSize: "1.8rem", // Make icon larger
                  marginBottom: 4,
                  fontWeight: isActive ? "bold" : "normal", // Bold if active
                  color: isActive ? "#1976d2" : "inherit", // Highlight active icon
                },
              })}
            <Typography
              variant="caption"
              sx={{
                fontSize: "0.7rem", // Make text smaller
                fontWeight: isActive ? "bold" : "normal", // Bold if active
                color: isActive ? "#1976d2" : "inherit", // Highlight active text
              }}
            >
              {item.menu?.name}
            </Typography>
            {item.children && <ExpandMore />}
          </IconButton>

          {/* Dropdown Menu */}
          {item.children && (
            <Menu
              anchorEl={anchorEl}
              open={openMenuKey === item.key} // Only open the menu if the key matches
              onClose={handleMenuClose}
              MenuListProps={{ onMouseLeave: handleMenuClose }}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              {item.children
                .filter((subItem) => subItem.roles.includes(user?.role))
                .map((subItem) => (
                  <MenuItem
                    key={subItem.key}
                    component={Link}
                    to={item.path + subItem.path}
                    onClick={handleMenuClose}
                  >
                    {/* Render Submenu Icon */}
                    {subItem.menu?.icon &&
                      React.createElement(subItem.menu.icon, {
                        style: { fontSize: "1rem", marginRight: 8 },
                      })}
                    {subItem.menu?.name}
                  </MenuItem>
                ))}
              <MenuItem button onClick={handleLogout}>
              <Logout /> Logout
              </MenuItem>

            </Menu>
          )}
        </Box>
      </React.Fragment>
    );
  })}
</Box>

          {/*
          <IconButton color="inherit" onClick={toggleDarkMode}>
            <Brightness4 />
          </IconButton>
          <IconButton color="inherit" onClick={openUserMenu}>
            <AccountCircle />
          </IconButton> */}
        </Toolbar>
      </AppBar>



      {/* Sidebar Drawer for Menu Items */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
  <List style={{ width: 250 }}>
    {filteredMenuItems.map((item, index) => (
      <React.Fragment key={`${item.key}-${index}`}>
        <ListItem
          button
          onClick={() => (item.children ? toggleSubmenu(item.key) : setDrawerOpen(false))}
          component={item.children ? "div" : Link}
          to={!item.children ? item.path : null}
        >
          {item.menu?.icon && React.createElement(item.menu.icon, { style: { marginRight: 16 } })}
          <ListItemText primary={item.menu.name} />
          {item.children && (openSubmenus[item.key] ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {/* Submenu Items */}
        {item.children && (
          <Collapse in={openSubmenus[item.key]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.children
                .filter((subItem) => subItem.menu && subItem.roles.includes(user?.role))
                .map((subItem, subIndex) => (
                  <ListItem
                    button
                    component={Link}
                    to={item.path + subItem.path}
                    key={`${subItem.key}-${index}-${subIndex}`}
                    onClick={toggleDrawer}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary={subItem.menu.name} />
                  </ListItem>
                ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ))}
    <Divider />
    <ListItem button onClick={toggleDarkMode}>
      <Brightness4 />
      <ListItemText primary="Toggle Dark Mode" />
    </ListItem>
    <ListItem button onClick={handleLogout}>
      <Logout />
      <ListItemText primary="Logout" />
    </ListItem>
  </List>
</Drawer>


      {/* User Account Dropdown
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeUserMenu}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={closeUserMenu} component={Link} to="/edit-profile">
          Edit Profile
        </MenuItem>
        <MenuItem onClick={toggleDarkMode}>Toggle Dark Mode</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu> */}
    </>
  );
};
HeaderMenu.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
  handleLogout: PropTypes.func.isRequired,
  toggleDarkMode: PropTypes.func.isRequired,
};

export default HeaderMenu;
