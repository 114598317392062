//import './ClientDashboard.css';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import qs from 'qs';
import {
  Button,
  Drawer,
  Grid,
  Typography,
  List,
  CircularProgress,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Filters from './Filters';
import CandidateCard from './CandidateCard';
import CandidateDrawer from './CandidateDrawer';


const ClientDashboard = () => {
  const initialFilters = JSON.parse(localStorage.getItem('selectedFilters')) || {};
  const initialSelectedIds = JSON.parse(localStorage.getItem('selectedCandidateIds')) || [];

  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [selectedCandidateIds, setSelectedCandidateIds] = useState(new Set(initialSelectedIds));
  const [candidates, setCandidates] = useState([]);
  const [clients, setClients] = useState([]);
  const [roles, setRoles] = useState([]);
  const [compensationRange, setCompensationRange] = useState([0, 200]);
  const [experienceRange, setExperienceRange] = useState([0, 30]);
  const [loading, setLoading] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [candidateDrawerVisible, setCandidateDrawerVisible] = useState(false);
  const [minDuration, setMinDuration] = useState(0);
  const [noticePeriod, setNoticePeriod] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedRole, setSelectedRole] = useState('');

  const loader = useRef(null);

  useEffect(() => {
    localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));
  }, [selectedFilters]);

  useEffect(() => {
    localStorage.setItem('selectedCandidateIds', JSON.stringify([...selectedCandidateIds]));
  }, [selectedCandidateIds]);

  useEffect(() => {
    fetchClientsWithActiveRoles();
  }, []);

  const fetchClientsWithActiveRoles = async () => {
    try {
      const response = await axios.get('/api/associations/clients-with-active-roles');
      setClients(response.data || []);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleClientChange = (event) => {
    const clientName = event.target.value;
    setSelectedClient(clientName);
    setSelectedRole('');
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  

  const fetchRolesForClient = async (clientId) => {
    try {
      const response = await axios.get(`/api/roles?clientId=${clientId}`);
      setRoles(response.data.roles || []);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const resetAllFilters = () => {
    setSelectedFilters({});
    setCompensationRange([0, 200]);
    setExperienceRange([0, 30]);
    setMinDuration(0);
    setNoticePeriod('');
    fetchData({}, 1);
    setPage(1);
  };

  const fetchData = useCallback(async (filters, nextPage) => {
    setLoading(true);
    
    const urlParams = new URLSearchParams(window.location.search);
    const createdBy = urlParams.get('CreatedBy');
    const createdAt = urlParams.get('CreatedAt');
    const Source = urlParams.get('Source');
    
    try {
      const response = await axios.get('/api/candidates', {
        params: { ...filters, page: nextPage, pageSize: 10, CreatedBy: createdBy, CreatedAt: createdAt, Source: Source },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
      });
      const newCandidates = response.data.candidates || [];
      if (nextPage === 1) {
        setCandidates(newCandidates);
      } else {
        setCandidates(prev => [...prev, ...newCandidates]);
      }
      setTotalCandidates(response.data.total || 0);
      setHasMore(newCandidates.length > 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleFilterChange = useCallback((filterName, value) => {
    setSelectedFilters(prevFilters => {
      const newFilters = { ...prevFilters, [filterName]: value };
      fetchData(newFilters, 1);
      setPage(1);
      return newFilters;
    });
  }, [fetchData]);

  const handleLoadMore = useCallback(() => {
    if (!hasMore || loading) return;
    fetchData(selectedFilters, page + 1);
    setPage(prevPage => prevPage + 1);
  }, [fetchData, hasMore, loading, page, selectedFilters]);

  const handleObserver = useCallback(entries => {
    const target = entries[0];
    if (target.isIntersecting && hasMore && !loading) {
      handleLoadMore();
    }
  }, [handleLoadMore, hasMore, loading]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 0
    });
    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [handleObserver]);

  const showCandidateDrawer = (candidate) => {
    setSelectedCandidate(candidate);
    setCandidateDrawerVisible(true);
  };

  const onCloseCandidateDrawer = () => {
    setCandidateDrawerVisible(false);
  };

  const onMinDurationChange = useCallback((value) => {
    setMinDuration(value);
    handleFilterChange('minDuration', value);
  }, [handleFilterChange]);

  const onNoticePeriodChange = useCallback((value) => {
    setNoticePeriod(value);
    handleFilterChange('noticePeriod', value);
  }, [handleFilterChange]);

  const toggleCandidateSelection = (candidateId) => {
    setSelectedCandidateIds(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(candidateId)) {
        newSelection.delete(candidateId);
      } else {
        newSelection.add(candidateId);
      }
      console.log("newSelection");
      return newSelection;
    });
  };

  const addCandidatesToRole = async () => {
    try {
      const selectedCandidatesArray = Array.from(selectedCandidateIds);
      await axios.post('/api/associations/add-candidates-to-role', {
        roleId: selectedRole,
        candidateIds: selectedCandidatesArray,
        creator: "hv app", // The name or ID of the creator

      });
      alert('Candidates successfully added to the role!');
      setSelectedCandidateIds(new Set());
      setDrawerVisible(false);
    } catch (error) {
      console.error('Error adding candidates to role:', error);
      alert('Failed to add candidates to the role. Please try again.');
    }
  };
  

  return (
    <div>
      <div         
      style = {{display: 'flex', justifyContent: 'top', flexWrap: 'wrap', position: 'sticky', top: '0', zIndex: '1000'}}>

      <Button
        variant="contained"
        startIcon={<MenuIcon />}
        onClick={() => setFiltersVisible(true)}        
      >
        {totalCandidates} Candidates
      </Button><br />
      <Button
        variant="contained"
        onClick={() => setDrawerVisible(true)}
        style={{ marginLeft: '10px' }}        
      >
        Show Selected Candidates
      </Button></div>
      <Drawer
        anchor="left"
        open={filtersVisible}
        onClose={() => setFiltersVisible(false)}
      >
        <Filters
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
          compensationRange={compensationRange}
          onCompensationChange={(range) => setCompensationRange(range)}
          experienceRange={experienceRange}
          onExperienceChange={(range) => setExperienceRange(range)}
          onMinDurationChange={onMinDurationChange}
          onNoticePeriodChange={onNoticePeriodChange}
          resetAllFilters={resetAllFilters}
        />
      </Drawer>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <List>
            {candidates.map(candidate => (
              <div key={candidate._id}  >
                
                <CandidateCard 
                candidate={candidate} 
                isAdmin={true} 
                onShowDrawer={showCandidateDrawer} 
                toggleCandidateSel={toggleCandidateSelection} 
                selected={[...selectedCandidateIds].some(id => id._id === candidate._id) ? 'checked' : ''}/>
              </div>
            ))}
            <div ref={loader} style={{ height: 50, margin: '10px 0', textAlign: 'center' }}>
              {loading && <CircularProgress />}
            </div>
          </List>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={candidateDrawerVisible}
        onClose={onCloseCandidateDrawer}
      >
        {selectedCandidate && <CandidateDrawer candidate={selectedCandidate} visible={candidateDrawerVisible} onClose={onCloseCandidateDrawer} />}
      </Drawer>
      <Drawer
        anchor="right"
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
      >
        <div style={{ padding: '20px', width: '300px' }}>
          <Typography variant="h6">Selected Candidates</Typography>
          <List>
            {[...selectedCandidateIds].map(id => {
              return (
                <div key={id._id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <Typography variant="body1" style={{ flexGrow: 1 }}>{id ? id.name : 'Unknown'}</Typography>
                  <IconButton onClick={() => toggleCandidateSelection(id)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              );
            })}
          </List>
          
          <FormControl fullWidth margin="normal">
        <InputLabel>Client</InputLabel>
        <Select value={selectedClient} onChange={handleClientChange}>
          {clients.map(client => (
            <MenuItem key={client.name} value={client.name}>
              {client.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" disabled={!selectedClient}>
        <InputLabel>Role</InputLabel>
        <Select value={selectedRole} onChange={handleRoleChange}>
          {clients
            .find(client => client.name === selectedClient)?.roles
            .map(role => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <div>
        {selectedClient && (
          <Typography variant="h6">
            Current Sprint: {clients.find(client => client.name === selectedClient)?.currentSprint?.name || 'None'}
          </Typography>
        )}
      </div>

          <Button
            variant="contained"
            color="primary"
            onClick={addCandidatesToRole}
            disabled={!selectedRole || selectedCandidateIds.size === 0}
            style={{ marginTop: '20px' }}
          >
            Add Candidates to Role
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default ClientDashboard;
