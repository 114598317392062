import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Box,
  TextField,
  Fab,
  MenuItem,
  Select,
  Alert,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
} from '@mui/material';
import { Check as CheckIcon, Delete as DeleteIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useAuth } from '../AuthContext';

const Setup = ({ Project }) => {
  const { user } = useAuth();
  const { _id: ProjectId } = Project || {};
  const [templates, setTemplates] = useState([]);
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);
  const [alert, setAlert] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const quillRef = React.useRef(null);
  const [body, setBody] = useState('');

  const placeholders = ["{{Recipient's First Name}}", "{{Recipient's Company Name}}"];


  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`/api/emailTemplates/project/${ProjectId}`);
      const fetchedTemplates = response.data || [];
      setTemplates(fetchedTemplates);      
      setAttachments(fetchedTemplates[currentTemplateIndex]?.attachments || []);
      setBody(fetchedTemplates[currentTemplateIndex]?.body || '');
      if (fetchedTemplates.length > 0) {
//        setCurrentTemplateIndex(0);
//        setAttachments(fetchedTemplates[0].attachments || []);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
      setAlert({ severity: 'error', message: 'Failed to fetch templates.' });
    }
  };

  useEffect(() => {
    if (ProjectId) fetchTemplates();
  }, [ProjectId]);

  const insertPlaceholder = (placeholder) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      if (range) quill.insertText(range.index, placeholder);
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    const formData = new FormData();
    files.forEach((file) => formData.append('attachments', file));

    try {
      const response = await axios.post(
        `/api/emailTemplates/upload-attachments/${templates[currentTemplateIndex]._id}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      const updatedTemplate = response.data.template;
      const updatedTemplates = [...templates];
      updatedTemplates[currentTemplateIndex] = updatedTemplate;
      setTemplates(updatedTemplates);
      setAttachments(updatedTemplate.attachments || []);
    } catch (error) {
      console.error('Error uploading files:', error);
      setAlert({ severity: 'error', message: 'Failed to upload attachments.' });
    }
  };
  
  const sanitizeEmailBody = (html) => {

  // Replace <p> tags with <div> tags
  let cleanedHtml = html.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>');

  // Remove <br> tags that are alone in a <div> (unnecessary breaks)
  //cleanedHtml = cleanedHtml.replace(/<div><br><\/div>/g, '<div></div>');

  // Preserve intentional line breaks (e.g., two <br> tags for signatures)
  // Replace single <br> tags with a space, but leave double <br> tags intact
  //cleanedHtml = cleanedHtml.replace(/(<br>\s*){2,}/g, '<br><br>'); // Preserve double <br> for spacing
  //cleanedHtml = cleanedHtml.replace(/(<br>\s*){1}/g, ' '); // Replace single <br> with a space

  return cleanedHtml; 

  };
  
  const handleRemoveFile = async (index) => {
    try {
      const updatedAttachments = attachments.filter((_, i) => i !== index);
      const response = await axios.put(`/api/emailTemplates/update/${templates[currentTemplateIndex]._id}`, {
        ...templates[currentTemplateIndex],
        attachments: updatedAttachments,
      });
      const updatedTemplate = response.data;
      const updatedTemplates = [...templates];
      updatedTemplates[currentTemplateIndex] = updatedTemplate;
      setTemplates(updatedTemplates);
      setAttachments(updatedTemplate.attachments || []);
    } catch (error) {
      console.error('Error removing attachment:', error);
      setAlert({ severity: 'error', message: 'Failed to remove attachment.' });
    }
  };

  const saveTemplate = async () => {
    if (!user) {
      setAlert({ severity: 'error', message: 'User not authenticated.' });
      return;
    }
    try { 
      const updatedTemplate = {
      ...templates[currentTemplateIndex],
      body: sanitizeEmailBody(body), // Sanitize here
     // body: templates[currentTemplateIndex].body, // Sanitize here
      attachments,
    };
      await axios.put(`/api/emailTemplates/update/${updatedTemplate._id}`, updatedTemplate);
      setAlert({ severity: 'success', message: 'Template saved successfully!' });
      fetchTemplates();
    } catch (error) {
      console.error('Error saving template:', error);
      setAlert({ severity: 'error', message: 'Failed to save template.' });
    }
  };

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(null);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  return (
    <Box sx={{ margin: '0 auto', width: '100%', padding: 3 }}>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Email Templates
      </Typography>
      <Tabs
        value={currentTemplateIndex}
        onChange={(event, newIndex) => {
          setCurrentTemplateIndex(newIndex);
          setAttachments(templates[newIndex]?.attachments || []);
          setBody(templates[newIndex]?.body || '');
        }}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{ style: { backgroundColor: '#1976d2' } }}
            >
        {templates.map((temp, index) => (
          <Tab key={temp._id} label={temp.name} />
        ))}
            </Tabs>
            {templates.length > 0 && (
        <Box mt={3} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          
            {alert && (
        <Alert
          icon={<CheckIcon fontSize="inherit" />}
          severity={alert.severity}
          sx={{ mt: 2 }}
          onClose={() => setAlert(null)}
        >
          {alert.message}
        </Alert>
            )}
          <TextField
            label="Subject"
            variant="outlined"
            size="small"
            value={templates[currentTemplateIndex]?.subject || ''}
            onChange={(e) =>
              setTemplates((prev) =>
          prev.map((template) => ({ ...template, subject: e.target.value }))
              )
            }
            fullWidth
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              backgroundColor: '#f9f9f9',
              padding: 1,
              borderRadius: '8px',
            }}
          >
            <Select
              displayEmpty
              size="small"
              value=""
              onChange={(event) => insertPlaceholder(event.target.value)}
              style={{ minWidth: '200px' }}
            >
              <MenuItem value="" disabled>
          Insert Placeholder
              </MenuItem>
              {placeholders.map((placeholder) => (
          <MenuItem key={placeholder} value={placeholder}>
            {placeholder}
          </MenuItem>
              ))}
            </Select>
          </Box>
          <ReactQuill
            ref={quillRef}
            value={body || ''}
            onChange={(value) => setBody(value)}/*
              setTemplates((prev) =>
          prev.map((template, idx) =>
            idx === currentTemplateIndex ? { ...template, body: value } : template
          )
              )
            */
            style={{ height: '250px', border: '1px solid #ececec', borderRadius: '8px' }}
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link', 'image'],
                ['clean'],
              ],
              clipboard: {
                matchVisual: false, // Prevents Quill from adding <p> tags on paste
              },
            }}
            formats={[
              'header',
              'bold',
              'italic',
              'underline',
              'strike',
              'list',
              'bullet',
              'link',
              'image',
              'div', // Allow <div> tags
            ]}
          />
          <br/>
          {/*
          <Box sx={{ mt: 2 }}>
            <input
              type="file"
              id="file-upload"
              multiple
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <label htmlFor="file-upload">
              <Button variant="outlined" component="span" startIcon={<AttachFileIcon />}>
                Upload Attachments
              </Button>
            </label>
            {attachments.length > 0 && (
              <Paper elevation={1} sx={{ mt: 2, p: 2 }}>
                <List>
                  {attachments.map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={file.name} />
                      <IconButton onClick={() => handleRemoveFile(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
              
          </Box> {//*/}
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Fab variant="extended" color="primary" onClick={saveTemplate}>
              Save Template
            </Fab>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Setup;
