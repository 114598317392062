import React, { useState , useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Link,  useNavigate, useLocation } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { initGA, logPageView } from './utils/analytics';
import Theme from './theme';
import { GoogleOAuthProvider } from '@react-oauth/google';

import HeaderMenu from './utils/HeaderMenu';
import routeConfig from './routeConfig';
import { AuthProvider, useAuth } from './AuthContext';
import JobPage from './Jobs/JobPage';
import JobDetailPage from './Jobs/JobDetailPage';
import ApplyPage from './Jobs/ApplyPage';
import componentsMap from './utils/componentsMap';
import Login from './Login';
import './client.js';  // Import your client-side script


const App = () => (
  <GoogleOAuthProvider clientId="14388555053-la7s7phrsfaoemqnrusr4njrlijhd3ke.apps.googleusercontent.com">
  <AuthProvider>
    <Router>
      <MainApp />
    </Router>
  </AuthProvider>
  </GoogleOAuthProvider>
);

const MainApp = () => {
  var { user, logout } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [darkMode, setDarkMode] = useState(false);
  

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await fetch('https://api.hireveda.com/api/auth/emails', {
          method: 'GET', // Change to 'POST' if necessary
          headers: {
            'Content-Type': 'application/json',
            // Add authorization if needed
            // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN'
          }
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Failed to fetch emails:', error);
      }
    };

    //fetchEmails();
  }, []); // Empty dependency array ensures this runs only once when the component mounts


  useEffect(() => {
    initGA(); // Initialize GA when app loads
  }, []);

  useEffect(() => {
    logPageView(location.pathname); // Log page view on route change
  }, [location]);

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);
  const handleLogout = () => {
    logout();
    navigate('/login');
  };
  const handleToggleDarkMode = () => setDarkMode(!darkMode);
  if (!user) {
    user = { role: 'guest' };
  }
  useEffect(() => {
    console.log(user);
    if (user) {      
      const targetRoute = (() => {
        switch (user.role || '') {
          case 'admin': return '/admin/dashboard';
          case 'candidate': return '/candidate/profile';
          case 'client': return '/client/dashboard';
          case 'recruiter': return '/Candidates';
          default: return '/login';
        }
      })();
      
      if (location.pathname !== targetRoute && location.pathname === "/") {
        navigate(targetRoute);
      }
    }
  }, [user, navigate, location]);

  useEffect(() => {
    if (!user || !user.role) 
      user.role = 'guest';

    const redirectPath = {
      admin: '/admin/dashboard',
      candidate: '/candidate/profile',
      client: '/client/dashboard',
      recruiter: '/Candidates',
      guest: '/login',
    }[user.role] || '/login';

    if (location.pathname === '/') navigate(redirectPath);
  }, [user, navigate, location]);

  


  

  const renderRoute = (route, basePath = '') => {
    const fullPath = `${basePath}${route.path || ''}`.replace(/\/\//g, '/');
    const Component = componentsMap[route.component];

    if (route.children && route.roles.includes(user.role)) {
      return (
        <Route key={fullPath} path={fullPath} element={Component ? <Component /> : <Outlet />}>
          {route.children.map((childRoute) => renderRoute(childRoute, fullPath))}
        </Route>
      );
    } else if (Component && route.roles.includes(user.role)) {
      return <Route key={fullPath} path={fullPath} element={<Component />} />;
    } else if (Component) {
      return <Route key={fullPath} path={fullPath} element={<Login />} />;
    }
      else {
      return null;
      }
  };

  return (
    <>
       <ThemeProvider theme={Theme(darkMode)}>
      <CssBaseline />

          {user && <HeaderMenu user={user} handleLogout={handleLogout} toggleDarkMode={handleToggleDarkMode} />}
       

      <Drawer open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          {routeConfig.map((route) => (
            <ListItem button component={Link} to={route.path} key={route.path}>
              <ListItemText primary={route.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <div style={{ padding: '16px' }}>
        <Routes>
          {routeConfig.map((route) => renderRoute(route))}
          <Route path="/" element={<Login />} />
          <Route path="/jobs" element={<JobPage />} />
          <Route path="/jobs/:roleName" element={<JobDetailPage />} />
          <Route path="/apply/:roleName" element={<ApplyPage />} />
        </Routes>
      </div>
    </ThemeProvider>
    </>
  );
};

export default App;
