import React, { useState, useEffect } from 'react';

const EmailReaderDashboard = () => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchEmails = async () => {
    try {
      const response = await fetch('/api/emails'); // Replace with your API endpoint
      const data = await response.json();
      setEmails(data || []);
    } catch (err) {
      setError('Failed to fetch emails.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  if (loading) return <p>Loading emails...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h1>Email Dashboard</h1>
      {emails.length > 0 ? (
        emails.map((email) => (
          email.body && (
            <div key={email._id} className="email-card">
              <h3>{email.subject}</h3>
              <p><strong>From:</strong> {email.from}</p>
              <div dangerouslySetInnerHTML={{ __html: email.body.replace(/\n/g, '<br>') }} />
            </div>
          )
        ))
      ) : (
        <p>No emails found!</p>
      )}
    </div>
  );
};

export default EmailReaderDashboard;
