import './App.css';

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Container, Grid, Paper, Typography,TableContainer ,Table , TableHead, TableCell, TableRow,TableBody} from '@mui/material';

const AdminDashboard = () => {
  const [kpis, setKpis] = useState({});
  const [candidates, setCandidates] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({});


  const [counts, setCounts] = useState({
    withResume: {},
    withoutResumeWithLinkedinAndExperience: {},
    withoutResumeWithLinkedinOrExperience: {}
  });


   // Total sum for all categories (Grand Total)
   const grandTotal = 
   (counts.withResume.total || 0) + 
   (counts.withoutResumeWithLinkedinAndExperience.total || 0) + 
   (counts.withoutResumeWithLinkedinOrExperience.total || 0);

 // Helper function to calculate percentage and round it to 0 decimal places
 const calculatePercentage = (value, total) => {
   return total ? Math.round((value / total) * 100) : 0;
 };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/candidate-count'); // API endpoint
        setCounts(response.data);
      } catch (err) {
        console.error('Error fetching the data', err);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only once


  useEffect(() => {
    const fetchKPIs = async () => {
      try {
        const response = await axios.get('/api/kpis');
        setKpis(response.data);
      } catch (error) {
        console.error('Error fetching KPI data:', error);
      }
    };

    fetchKPIs();
  }, []);

  const fetchCandidates = useCallback(async () => {
    try {
      const sortField = sortModel.length ? sortModel[0].colId : null;
      const sortOrder = sortModel.length ? sortModel[0].sort : null;
      
      const filters = {};
      for (const field in filterModel) {
        if (filterModel[field].filter) {
          filters[field] = filterModel[field].filter;
        }
      }

      const response = await axios.get('/api/candidates', {
        params: {
          page,
          pageSize,
          sortField,
          sortOrder,
          filters: JSON.stringify(filters)
        }
      });

      setCandidates(response.data.candidates || []);
      setTotal(response.data.total || 0);
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  }, [page, pageSize, sortModel, filterModel]);

  useEffect(() => {
    fetchCandidates();
  }, [fetchCandidates]);

  const columnDefs = [
    { headerName: 'Name', field: 'name', sortable: true, filter: true },
    { headerName: 'Email', field: 'email', sortable: true, filter: true },
    { headerName: 'Phone', field: 'phone', sortable: true, filter: true },
    { headerName: 'Current Company', field: 'currentCompany', sortable: true, filter: true },
    { headerName: 'Current Role', field: 'currentRole', sortable: true, filter: true },
    { headerName: 'Experience (Years)', field: 'experience_yrs', sortable: true, filter: true },
    { headerName: 'Location', field: 'currentLocation', sortable: true, filter: true },
    { headerName: 'Compensation', field: 'compensation', sortable: true, filter: true },
    { headerName: 'Notice Period', field: 'noticePeriod', sortable: true, filter: true },
    { headerName: 'LinkedIn', field: 'linkedin', sortable: true, filter: true },
    { headerName: 'Resume', field: 'resume', sortable: true, filter: true },
    { headerName: 'Resume Text', field: 'resume_text', sortable: true, filter: true },
    { headerName: 'Skills', field: 'skills', valueGetter: params => params.data.skills.join(', '), sortable: true, filter: true },
    // Add more columns as needed
  ];

  const defaultColDef = {
    flex: 1,
    minWidth: 150,
    filter: true,
    sortable: true,
    resizable: true,
  };

  const onSortChanged = params => {
    setSortModel(params.api.getSortModel());
  };

  const onFilterChanged = params => {
    setFilterModel(params.api.getFilterModel());
  };

  const onPaginationChanged = params => {
    setPage(params.api.paginationGetCurrentPage() + 1);
    setPageSize(params.api.paginationGetPageSize());
  };

  return (    
  <Container>

<div>
      <h1>Candidate Table</h1>
      <table border="1" cellPadding="10" cellSpacing="0">
        <thead>
          <tr>
            <th>Criteria</th>
            <th>With Resume</th>
            <th>%</th>
            <th>Without Resume but linkedn</th>
            <th>%</th>
            <th>Without Resume and Linkedin</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {/* Total Count Row */}
          <tr>
            <td>Total Count</td>
            <td>{counts.withResume.total}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withResume.total, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinAndExperience.total}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinAndExperience.total, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinOrExperience.total}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinOrExperience.total, grandTotal)}%
            </td>
          </tr>
          <tr>
            <td>Years of Experience Available</td>
            <td>{counts.withResume.yearsOfExperience}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withResume.yearsOfExperience, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinAndExperience.yearsOfExperience}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinAndExperience.yearsOfExperience, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinOrExperience.yearsOfExperience}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinOrExperience.yearsOfExperience, grandTotal)}%
            </td>
          </tr>
          <tr>
            <td>Phone Number Available</td>
            <td>{counts.withResume.phone}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withResume.phone, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinAndExperience.phone}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinAndExperience.phone, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinOrExperience.phone}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinOrExperience.phone, grandTotal)}%
            </td>
          </tr>
          <tr>
            <td>Email ID Available</td>
            <td>{counts.withResume.email}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withResume.email, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinAndExperience.email}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinAndExperience.email, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinOrExperience.email}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinOrExperience.email, grandTotal)}%
            </td>
          </tr>
          <tr>
            <td>Current Company Available</td>
            <td>{counts.withResume.currentCompany}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withResume.currentCompany, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinAndExperience.currentCompany}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinAndExperience.currentCompany, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinOrExperience.currentCompany}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinOrExperience.currentCompany, grandTotal)}%
            </td>
          </tr>
          <tr>
            <td>Location Available</td>
            <td>{counts.withResume.location}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withResume.location, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinAndExperience.location}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinAndExperience.location, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinOrExperience.location}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinOrExperience.location, grandTotal)}%
            </td>
          </tr>
          <tr>
            <td>Current CTC Available</td>
            <td>{counts.withResume.currentCTC}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withResume.currentCTC, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinAndExperience.currentCTC}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinAndExperience.currentCTC, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinOrExperience.currentCTC}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinOrExperience.currentCTC, grandTotal)}%
            </td>
          </tr>
          <tr>
            <td>Notice Period Available</td>
            <td>{counts.withResume.noticePeriod}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withResume.noticePeriod, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinAndExperience.noticePeriod}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinAndExperience.noticePeriod, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinOrExperience.noticePeriod}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinOrExperience.noticePeriod, grandTotal)}%
            </td>
          </tr>
          <tr>
            <td>LinkedIn Profile Available</td>
            <td>{counts.withResume.linkedin}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withResume.linkedin, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinAndExperience.linkedin}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinAndExperience.linkedin, grandTotal)}%
            </td>
            <td>{counts.withoutResumeWithLinkedinOrExperience.linkedin}</td>
            <td style={{ fontSize: 'small' }}>
              {calculatePercentage(counts.withoutResumeWithLinkedinOrExperience.linkedin, grandTotal)}%
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>KPI Name</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(kpis).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                {key}
              </TableCell>
              <TableCell align="right">{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
      <Typography variant="h4" gutterBottom>
        KPI Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="kpi-item">
            <Typography variant="h6">Total</Typography>
            <Typography variant="body1">{kpis.total}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="kpi-item">
            <Typography variant="h6">CVs</Typography>
            <Typography variant="body1">{kpis.cvCount}</Typography>
            <Typography variant="body2">{Math.round(kpis.cvCount / kpis.total * 100, 1)}%</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="kpi-item">
            <Typography variant="h6">No CV/not LinkedIn</Typography>
            <Typography variant="body1">{kpis.noCvCount}</Typography>
            <Typography variant="body2">{Math.round(kpis.noCvCount / kpis.total * 100, 1)}%</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="kpi-item">
            <Typography variant="h6">Keyword Search (Scraped)</Typography>
            <Typography variant="body1">{kpis.keywordSearchCount}</Typography>
            <Typography variant="body2">{Math.round(kpis.keywordSearchCount / kpis.total * 100, 1)}%</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="kpi-item">
            <Typography variant="h6">Years of Exp</Typography>
            <Typography variant="body1">{kpis.expCount}</Typography>
            <Typography variant="body2">{Math.round(kpis.expCount / kpis.total * 100, 1)}%</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="kpi-item">
            <Typography variant="h6">Education Information</Typography>
            <Typography variant="body1">{kpis.eduCount}</Typography>
            <Typography variant="body2">{Math.round(kpis.eduCount / kpis.total * 100, 1)}%</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="kpi-item">
            <Typography variant="h6">Designation/Function</Typography>
            <Typography variant="body1">{kpis.designationCount}</Typography>
            <Typography variant="body2">{Math.round(kpis.designationCount / kpis.total * 100, 1)}%</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper           elevation={3} className="kpi-item">
            <Typography variant="h6">Stability</Typography>
            <Typography variant="body1">{kpis.stabilityCount}</Typography>
            <Typography variant="body2">{Math.round(kpis.stabilityCount / kpis.total * 100, 1)}%</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="kpi-item">
            <Typography variant="h6">CTC</Typography>
            <Typography variant="body1">{kpis.ctcCount}</Typography>
            <Typography variant="body2">{Math.round(kpis.ctcCount / kpis.total * 100, 1)}%</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="kpi-item">
            <Typography variant="h6">Email ID</Typography>
            <Typography variant="body1">{kpis.emailCount}</Typography>
            <Typography variant="body2">{Math.round(kpis.emailCount / kpis.total * 100, 1)}%</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="kpi-item">
            <Typography variant="h6">Phone No.</Typography>
            <Typography variant="body1">{kpis.phoneCount}</Typography>
            <Typography variant="body2">{Math.round(kpis.phoneCount / kpis.total * 100, 1)}%</Typography>
          </Paper>
        </Grid>
      </Grid>

    </Container>
  );
};

export default AdminDashboard;

