import React, { useState, useRef } from 'react';
import { Card, Grid, Avatar, Typography, IconButton, Box, Paper,  Checkbox } from '@mui/material';
import { LinkedIn, Edit, Description, Work, School } from '@mui/icons-material';
import CompanyLogo from '../CompanyLogo';
import CandidateDrawer from './CandidateDrawer'; // Ensure this import is correct
//import { highlightKeywords } from '../utils/highlight'; // Adjust the path as needed

const CandidateCard = ({ candidate, isAdmin, toggleCandidateSel,selected }) => {
  const cardRef = useRef(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const toggleDrawer = () => setDrawerVisible(!drawerVisible);

  const formatDate = (dateStr) => {
    if (!dateStr) return 'Present'; // Handle null or undefined dates
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { month: 'short', year: '2-digit' });
  };
 
  const formatDateYear = (dateStr) => {
    if (!dateStr) return 'Present'; // Handle null or undefined dates
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { year: 'numeric' });
  };

  

  return (
    <Card
      ref={cardRef}
      tabIndex={0}
      className={`candidate-card ${drawerVisible ? 'selected' : ''}`}
      sx={{
        cursor: 'pointer',
        boxShadow: drawerVisible ? '0 0 8px rgba(33, 150, 243, 0.5)' : '0 4px 6px rgba(0, 0, 0, 0.1)',
        margin: '8px',
        transition: 'box-shadow 0.3s ease',
        borderColor: drawerVisible ? '#2196f3' : '#f0f1f2'
      }}
    >
      <Grid container spacing={2} alignItems="center" padding={2}>
        <Grid item xs={3}>
          <Avatar
            src={candidate.dp || ''}
            sx={{ width: 90, height: 90, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}
          />
        </Grid>
        <Grid item xs={7} onClick={toggleDrawer}>
          <Typography variant="h6">{candidate.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {candidate.experience_yrs && `${Math.floor(candidate.experience_yrs)} yr ${Math.round(candidate.experience_yrs % 1 * 12)} mo`}
            {candidate.currentLocation && <><br />{candidate.currentLocation}</>}
          </Typography>
          
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {candidate.linkedin && (
            <IconButton href={candidate.linkedin} target="_blank" rel="noopener noreferrer">
              <LinkedIn />
            </IconButton>
          )}
          {candidate.resume && (
            <IconButton href={candidate.resume} target="_blank" rel="noopener noreferrer">
              <Description />
            </IconButton>
          )}
          {isAdmin && (
            <IconButton href={`/edit/${candidate._id}`} sx={{ marginLeft: 1 }}>
              <Edit />
            </IconButton>
          )}
          {(candidate.naukri_id && isAdmin) && (
            <IconButton href={`https://resdex.naukri.com/v3/preview?uniqId=${candidate.naukri_id}`} sx={{ marginLeft: 1 }}>
          <img src="https://resdex.naukri.com/favicon.ico" alt="Naukri" style={{ width: 24, height: 24 }} />
          </IconButton>
          )}
          {(candidate.iimjobs_id && isAdmin) && (
            <IconButton href= {`https://recruit.iimjobs.com/CAndidate/${candidate.iimjobs_id}/profile`} sx={{ marginLeft: 1 }}>
          <img src="https://recruit.iimjobs.com/static/images/favicon.ico" alt="Monster" style={{ width: 24, height: 24 }} />
          </IconButton>
          )}
            <IconButton onClick={() => {toggleCandidateSel(candidate); }} sx={{ marginLeft: 1 }} >
            <Checkbox checked= {selected} />
            </IconButton>
        </Grid>
        <Typography variant="body2" color="textSecondary">{candidate.headline}</Typography>
      </Grid>

      
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
            <Work sx={{ mr: 1 }} /> Work Experience
          </Typography>          
          <Paper elevation={2} sx={{ padding: 2, marginBottom: 1, display: 'flex', alignItems: 'left', flexFlow: 'column' }}>
            {candidate.experience && candidate.experience.slice(0, 3).map((exp, index) => (
              <div style={{ clear: 'both', width: '100%' }} key={index}>
                <Box>
                  <CompanyLogo companyName={exp.organization} style={{ marginRight: '10px', marginLeft: '10px', float: 'left', width: '40px' }} />             
                  <Typography variant="body2" style={{ float: 'left' }}>
                    <strong>{exp.designation && exp.designation.length >40 ? exp.designation.substring(0,40) : exp.designation  }</strong> 
                    <br />{exp.organization && exp.organization.length > 40 ? exp.organization.substring(0,40) : exp.organization}
                  </Typography>
                  {exp.startDate && (
                    <Typography variant="caption" style={{ float: 'right' }}>
                      {formatDate(exp.startDate)} - {formatDate(exp.endDate)} <br/> [{Math.floor(exp.duration)} yr {Math.round(exp.duration % 1 * 12)} mo]
                    </Typography>
                  )}
                </Box>
              </div>
            ))}          
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
            <School sx={{ mr: 1 }} /> Education
          </Typography>
          <Paper elevation={2} sx={{ padding: 2, marginBottom: 1, display: 'flex', alignItems: 'center', flexFlow: 'column' }}>             
            {candidate.education && candidate.education.slice(0, 3).map((edu, index) => (
              <div style={{ clear: 'both', width: '100%' }} key={index}>
                <CompanyLogo companyName={edu.institution} style={{ marginRight: '10px' }} />
                <Box>
                  <Typography 
                    variant="body2" 
                    style={{ float: 'left' }} 
                    title={edu.degree && edu.degree.length > 40 ? edu.degree : ''}
                  >
                    <strong>
                      {edu.degree && edu.degree.length > 40 ? `${edu.degree.substring(0, 40)}...` : edu.degree}
                    </strong> 
                    <br />
                    {edu.institution && edu.institution.length > 40 ? `${edu.institution.substring(0, 40)}...` : edu.institution}
                  </Typography>
                  {edu.startDate && (
                    <Typography variant="caption" style={{ float: 'right' }}>
                      {formatDateYear(edu.startDate)} - {formatDateYear(edu.endDate)}
                    </Typography>
                  )}
                </Box>
              </div>
            ))}          
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ padding: 0 }}>
        <Grid item xs={4}>
          {candidate.noticePeriod && (
            <Typography variant="body2" color="textSecondary">
              Notice Period: <strong>{candidate.noticePeriod}</strong> days
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          {candidate.compensation && (
            <Typography variant="body2" color="textSecondary">
              Current Salary: <strong>₹{candidate.compensation}</strong> LPA
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          {candidate.expectation && (
            <Typography variant="body2" color="textSecondary">
              Expected Salary: <strong>₹{candidate.expectation}</strong> LPA
            </Typography>
          )}
        </Grid>
      </Grid>

      <CandidateDrawer candidate={candidate} visible={drawerVisible} onClose={() => setDrawerVisible(false)} />
    </Card>
  );
};

export default CandidateCard;
