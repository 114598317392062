// routeConfig.js
const routeConfig = [
  {
    path: "/candidate/profile",
    component: "CandidateDashboard",
    roles: ["candidate", "admin"],
    menu: { name: "Profile", path: "/candidate/profile", key: "candidate-profile" }
  },
  {
    path: "/Pipeline",
    component: "Associations",
    roles: ["admin"],
    menu: { name: "Pipeline", path: "/Pipeline", key: "candidate-pipeline" }
  },
  {
    path: "/jobs",
    component: "JobPage",
    roles: ["candidate", "admin", "recruiter"],
    menu: { name: "Jobs", path: "/jobs", key: "jobs" }
  },
  {
    path: "/Candidates",
    component: "ClientDashboard",
    roles: ["recruiter","admin"],
    menu: { name: "Search", path: "/Candidates", key: "Candidates" }
  },
  {
    path: "/edit-profile",
    component: "EditProfile",
    roles: ["admin", "candidate", "client", "recruiter"],
    menu: null // Not shown in menu
  },
  {
    path: "/recover-password",
    component: "RecoverPassword",
    roles: ["guest"],
    menu: null // Not shown in menu
  },
  {
    path: "/login",
    component: "Login",
    roles: ["guest"],
    menu: null // Not shown in menu
  },
  {
    path: "/signup",
    component: "Signup",
    roles: ["guest"],
    menu: null // Not shown in menu
  },
  {
    path: "/oauth-callback",
    component: "OAuthCallback",
    roles: ["guest","admin", "candidate", "client", "recruiter"],
    menu: null // Not shown in menu
  },
  {
    path: "/",
    component: "Home",
    roles: ["admin", "candidate", "client", "recruiter"],
    menu: null // Not shown in menu
  },  
  {
    path: "/apply",
    component: "apply",
    roles: ["guest", "admin", "candidate", "client", "recruiter"],
    menu: "Apply" 
     // Not shown in menu
  },
  {
   path: "/edit/:id",
   component: "EditCandidates",
   roles: ["candidate", "admin"],
   menu: null
 },
 {
  path: "/email",
  component: "EmailCampaignDashboard",
  roles: ["admin"],
  menu: { name: "Email Campaign", path: "/email", key: "email-campaign" }
 },
 
 {
  path: "/admin",
  component: "",
  roles: ["admin"],
  menu: { name: "Admin", path: "/", key: "admin" }, // Not shown in menu
  children: [
{
  path: "/dashboard",
  component: "AdminDashboard",
  roles: ["admin"],
  menu: { name: "Dashboard", path: "/admin/dashboard", key: "admin-dashboard" }
},
{
  path: "/upload",
  component: "FileUploadComponent",
  roles: ["admin"],
  menu: { name: "Import", path: "/admin/upload", key: "admin-upload" }
},
{
  path: "/users",
  component: "AdminUserManagement",
  roles: ["admin"],
  menu: { name: "Users", path: "/admin/users", key: "admin-users" }
},
{
  path: "/merge",
  component: "AdminMergeComponent",
  roles: ["admin"],
  menu: { name: "Database", path: "/admin/merge", key: "admin-database" }
},
{
  path: "/linkedin",
  component: "linkedintab",
  roles: ["admin"],
  menu: { name: "Linkedin", path: "/admin/linkedin", key: "admin-linkedin" }
},
{
  path: "/BulkMerge",
  component: "BulkUpdateComponent",
  roles: ["admin"],
  menu: null // Not shown in menu
},
{
  path: "/Roles",
  component: "RoleManagement",
  roles: ["admin"],
  menu: {name: "Roles", path: "/admin/Roles", key: "admin-roles"} // Not shown in menu
},
{
  path: "/Admin/Email",
  component: "EmailReaderDashboard",
  roles: ["admin"],
  menu: { name: "Email Reader", path: "/admin/Email", key: "admin-email" }
},
{
  path: "/Statuses",
  component: "AdminStatusManager",
  roles: ["admin"],
  menu: { name: "Statuses", path: "/admin/Statuses", key: "admin-statuses" }
}],
}
];

export default routeConfig;
