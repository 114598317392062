// HeaderMenu.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Collapse, Menu, MenuItem,Divider  } from '@mui/material';
import { Menu as MenuIcon, AccountCircle, Brightness4, Logout, ExpandLess, ExpandMore } from '@mui/icons-material';
import routeConfig from '../routeConfig';

const HeaderMenu = ({ user, handleLogout, toggleDarkMode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubmenus, setOpenSubmenus] = useState({}); // Tracks submenu open state

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const openUserMenu = (event) => setAnchorEl(event.currentTarget);
  const closeUserMenu = () => setAnchorEl(null);

  // Filter menu items based on user's role
  const filteredMenuItems = routeConfig.filter(route => route.menu && route.roles.includes(user?.role));

  // Toggle submenu visibility
  const toggleSubmenu = (key) => {
    setOpenSubmenus(prev => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <>
      {/* Top Navigation Bar */}
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <img
            src="https://hireveda.com/content/images/2024/02/HV-Logo-White.png"
            alt="Hireveda Logo"
            style={{ height: '20px', marginRight: 'auto' }}
          />
          <IconButton color="inherit" onClick={toggleDarkMode}>
            <Brightness4 />
          </IconButton>
          <IconButton color="inherit" onClick={openUserMenu}>
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer for Menu Items */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <List style={{ width: 250 }}>
          {filteredMenuItems.map((item, index) => (
            <React.Fragment key={item.key+index}>
              <ListItem button onClick={() => item.children ? toggleSubmenu(item.key) : setDrawerOpen(false)} component={item.children ? 'div' : Link} to={!item.children ? item.path : null}>
                <ListItemText primary={item.menu.name} />
                {item.children && (openSubmenus[item.key] ? <ExpandLess /> : <ExpandMore />)}
              </ListItem>
              {/* Submenu Items */}
              {item.children && (
                <Collapse in={openSubmenus[item.key]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children
                      .filter(subItem => subItem.menu && subItem.roles.includes(user?.role))
                      .map((subItem, subIndex) => (
                        <ListItem button component={Link} to={item.path + subItem.path} key={subItem.key+index+subIndex} onClick={toggleDrawer} sx={{ pl: 4 }}>
                          <ListItemText primary={subItem.menu.name} />
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
          <Divider />
          <ListItem button onClick={toggleDarkMode}>
            <Brightness4 />
            <ListItemText primary="Toggle Dark Mode" />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <Logout />
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>

      {/* User Account Dropdown */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeUserMenu}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={closeUserMenu} component={Link} to="/edit-profile">
          Edit Profile
        </MenuItem>
        <MenuItem onClick={toggleDarkMode}>Toggle Dark Mode</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
HeaderMenu.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
  handleLogout: PropTypes.func.isRequired,
  toggleDarkMode: PropTypes.func.isRequired,
};

export default HeaderMenu;
