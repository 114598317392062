import React, { useState } from 'react';
import { Form, Input, Button, Tabs, message } from 'antd';
import { useAuth } from './AuthContext';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

const Signup = () => {
    const { signup } = useAuth();
    const [form] = Form.useForm();
    const [activeRole, setActiveRole] = useState('recruiter');

    const onFinish = async (values) => {
        try {
            values.role = activeRole; // Add the role to values
            await signup(values);
            message.success('Signup successful! Please check your email to verify your account.');
        } catch (error) {
            message.error('Signup failed: ' + (error.response?.data?.message || error.message));
        }
    };

    const onTabChange = (key) => {
        setActiveRole(key);
        form.resetFields(); // Reset form when role changes
    };

    return (
        <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src="https://hireveda.com/content/images/size/w1000/2023/05/HireVeda-6.png" alt="HireVeda Logo" style={{ width: '100px' }} />
            </div>
            <Tabs defaultActiveKey="recruiter" onChange={onTabChange} centered>
                <TabPane tab="Recruiter" key="recruiter">
                    <Form form={form} onFinish={onFinish} layout="vertical">
                        <Form.Item
                            name="email"
                            rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password placeholder="Password" />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            rules={[{ required: true, message: 'Please input your name!' }]}
                        >
                            <Input placeholder="Full Name" />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            rules={[{ required: true, message: 'Please input your phone number!', pattern: new RegExp(/^[0-9]{10}$/) }]}
                        >
                            <Input placeholder="Phone Number" maxLength={10} />
                        </Form.Item>
                        <Form.Item
                            name="organization"
                            rules={[{ required: true, message: 'Please input your organization!' }]}
                        >
                            <Input placeholder="Organization" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Sign Up
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
                <TabPane tab="Candidate" key="candidate">
                    <Form form={form} onFinish={onFinish} layout="vertical">
                        <Form.Item
                            name="email"
                            rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password placeholder="Password" />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            rules={[{ required: true, message: 'Please input your name!' }]}
                        >
                            <Input placeholder="Full Name" />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            rules={[{ required: true, message: 'Please input your phone number!', pattern: new RegExp(/^[0-9]{10}$/) }]}
                        >
                            <Input placeholder="Phone Number" maxLength={10} />
                        </Form.Item>
                        <Form.Item
                            name="organization"
                            rules={[{ required: true, message: 'Please input your organization!' }]}
                        >
                            <Input placeholder="Organization" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Sign Up
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
            </Tabs>
            <Link to="/login">
                <Button type="link" block style={{ marginTop: '10px' }}>
                    Already have an account? Log in
                </Button>
            </Link>
        </div>
    );
};

export default Signup;
