import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  Grid,
  Paper,
  MenuItem,
  IconButton,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";


const EditProfile = ({ initialData = { dp: "" } }) => {
  const [formData, setFormData] = useState({
    dp: "",
    name: "",
    gender: "",
    marital_status: "",
    DOB: null,
    skills: [],
    experience: [],
    ...initialData,
  });
  // Component logic


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleAddExperience = () => {
    setFormData({
      ...formData,
      experience: [
        ...formData.experience,
        { organization: "", designation: "", startDate: null, endDate: null },
      ],
    });
  };

  const handleRemoveExperience = (index) => {
    const updatedExperience = [...formData.experience];
    updatedExperience.splice(index, 1);
    setFormData({ ...formData, experience: updatedExperience });
  };

  const handleAddSkill = () => {
    setFormData({
      ...formData,
      skills: [...formData.skills, ""],
    });
  };

  const handleRemoveSkill = (index) => {
    const updatedSkills = [...formData.skills];
    updatedSkills.splice(index, 1);
    setFormData({ ...formData, skills: updatedSkills });
  };

  const handleSubmit = () => {
    console.log("Updated Profile Data:", formData);
    // Add your submission logic here
  };

  return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar src={formData.dp} sx={{ width: 100, height: 100, mb: 2 }} />
            <Typography variant="h6">{formData.name || "Your Name"}</Typography>
          </Box>

          <Typography variant="h6" sx={{ mt: 3 }}>
            Personal Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                value={formData.name || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Gender"
                name="gender"
                fullWidth
                value={formData.gender || ""}
                onChange={handleChange}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Marital Status"
                name="marital_status"
                fullWidth
                value={formData.marital_status || ""}
                onChange={handleChange}
              >
                <MenuItem value="Single">Single</MenuItem>
                <MenuItem value="Married">Married</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Date of Birth"
                value={formData.DOB || null}
                onChange={(newValue) => handleDateChange("DOB", newValue)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" sx={{ mt: 3 }}>
            Skills
          </Typography>
          {formData.skills.map((skill, index) => (
            <Grid container spacing={2} key={index} alignItems="center">
              <Grid item xs={10}>
                <TextField
                  label="Skill"
                  fullWidth
                  value={skill}
                  onChange={(e) => {
                    const updatedSkills = [...formData.skills];
                    updatedSkills[index] = e.target.value;
                    setFormData({ ...formData, skills: updatedSkills });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => handleRemoveSkill(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddSkill} sx={{ mt: 2 }}>
            Add Skill
          </Button>

          <Typography variant="h6" sx={{ mt: 3 }}>
            Work Experience
          </Typography>
          {formData.experience.map((exp, index) => (
            <Grid container spacing={2} key={index} alignItems="center">
              <Grid item xs={4}>
                <TextField
                  label="Organization"
                  fullWidth
                  value={exp.organization || ""}
                  onChange={(e) => {
                    const updatedExperience = [...formData.experience];
                    updatedExperience[index].organization = e.target.value;
                    setFormData({ ...formData, experience: updatedExperience });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Designation"
                  fullWidth
                  value={exp.designation || ""}
                  onChange={(e) => {
                    const updatedExperience = [...formData.experience];
                    updatedExperience[index].designation = e.target.value;
                    setFormData({ ...formData, experience: updatedExperience });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePicker
                  label="Start Date"
                  value={exp.startDate || null}
                  onChange={(newValue) => {
                    const updatedExperience = [...formData.experience];
                    updatedExperience[index].startDate = newValue;
                    setFormData({ ...formData, experience: updatedExperience });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePicker
                  label="End Date"
                  value={exp.endDate || null}
                  onChange={(newValue) => {
                    const updatedExperience = [...formData.experience];
                    updatedExperience[index].endDate = newValue;
                    setFormData({ ...formData, experience: updatedExperience });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleRemoveExperience(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddExperience} sx={{ mt: 2 }}>
            Add Experience
          </Button>

          <Box textAlign="center" sx={{ mt: 4 }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save Changes
            </Button>
          </Box>
        </Paper>
      </Container>
  );
};

export default EditProfile;
