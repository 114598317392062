import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Avatar, Container, Card, Button, TextField, Typography, FormControl, CardContent, CardHeader, Dialog , TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Link, Checkbox, List, ListItem, Chip, Box, DialogTitle, DialogContent,DialogActions } from '@mui/material';
import { Attachment as AttachmentIcon, Visibility as VisibilityIcon, Link as LinkIcon, MailOutline as MailOutlineIcon, Settings as SettingsIcon } from '@mui/icons-material';
import CompanyLogo from '../CompanyLogo';
import Linkedin from '@mui/icons-material/LinkedIn';
import { Modal } from 'antd';
import Setup from './Setup';
import { useAuth } from '../AuthContext';

const Reachouts = ({ decisionMakers = [], Project, fetchDecisionMakers }) => {
    const { user } = useAuth();
    const [data, setData] = useState({});
    const [reason, setReason] = useState("");
    const [preview, setPreview] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [i, setI] = useState(0);
    const [OpenPreview, setOpenPreview] = useState(false);
    const [selectedDecisionMakers, setSelectedDecisionMakers] = useState([]);
    const [email, setEmail] = useState({});
    const [emails, setEmails] = useState([]);
    const [status, setStatus] = useState(() => localStorage.getItem('status') || "Initial Outreach Pending");
    const [template, setTemplate] = useState(() => localStorage.getItem('template') || "");
    const DueDays =7;
    const filterstatus = {
        "Initial Outreach Pending": ["Initial Outreach Pending"], 
        "Followup Pending": ["Initial Outreach"], 
        "Closing Outreach Pending": ["Follow up"], 
        "Outreach Completed": ["Closing Outreach"], 
        "Response Received": ["Response Received", "Positive Outcome", "Negative Outcome"],        
        "Rejected": ["Rejected"]
    };
    useEffect(() => {
        localStorage.setItem('status', status);
    }, [status]);

    useEffect(() => {
        localStorage.setItem('template', template);
    }, [template]);
    const [showSettings, setShowSettings] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [ModalDMId, setModalDMId] = useState('');
    const [ModalStatus, setModalStatus] = useState('');
    const [newComment, setNewComment] =useState('');

        useEffect(() => {
            console.log(newComment);
        },[newComment]);
    
    async function fetchemails() {
        try {
            const response = await axios.get(`/api/fetchemails/${Project.email}`);
            setEmails(response.data);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            fetchemails();
        }, 30000); // 10 seconds

        return () => clearInterval(interval);
    }, []);

    const handleCloseModal = () => {
        setOpenModal(false);
    } 

    const handleSubmit = async (newComment) => {
        try {
            // Update connection status
            await axios.post('/api/execedge/updateConnectionStatusByEmail', {
                ExecEdgeId: Project._id,
                candidateId: ModalDMId,
                Status: ModalStatus,
            });
    
            // Push the entire comment block to the backend
            await axios.post('/api/execedge/addAssoComment', {
                execEdgeId: Project._id, // Ensure consistency with backend parameter names
                decisionMakerId: ModalDMId,
                comment: newComment, // Pass the full comment block
            });
    
            // Refresh data and close the modal
            fetchDecisionMakers();
            setOpenModal(false);
        } catch (error) {
            console.error('Error updating connection status:', error);
        }
    };
    

  useEffect(() => {
    const fetchTemplates = async () => {
        try {
            const response = await axios.get(`/api/emailTemplates/project/${Project._id}`);
            setTemplates(response.data);
            setTemplate(response.data.find(t => t.name === "Initial Outreach")._id);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    }
    fetchemails();
    fetchTemplates();
    }, [Project]);

    const handleStatus = async (candidateId, Status) => {
        setOpenModal(true);
        setModalDMId(candidateId);
        setModalStatus(Status);
    }

    const handlePreview = async (templateId, decisionMaker) => {
        let decisionMakersToUse = decisionMaker ? [decisionMaker] : selectedDecisionMakers;
        setSelectedDecisionMakers(decisionMakersToUse);
        let prev = [];
        const previews = await Promise.all(decisionMakersToUse.map(async (dm) => {
            try {
                let dmkr = decisionMakers.find(d => d._id === dm);
                const company = Project.TargetCompany.find(tc => tc.Organization === dmkr.TargetCompanyId);
                console.log(company);
                let data = { ...dmkr, ...Project, "Recipient's Company Name": company ? company.Name : "","Recipient's First Name": dmkr.FirstName };
                const response = await axios.post('/api/emailTemplates/preview', { templateId, data });
                return response.data;
            } catch (error) {
                console.error('Error previewing template', error);
                return null;
            }
        }));
        const validPreviews = previews.filter(p => p !== null);
        console.log(validPreviews);
        setPreview(validPreviews);
        setOpenPreview(true);
    }

    const handelSendEmail = async (templateId) => {
        selectedDecisionMakers.forEach(async (dm) => {       
        try {
            let dmkr = decisionMakers.find(d => d._id === dm);
            const company = Project.TargetCompany.find(tc => tc.Organization === dmkr.TargetCompanyId);
            console.log(company);
            let data = { ...dmkr, ...Project, "Recipient's Company Name": company ? company.Name : "","Recipient's First Name": dmkr.FirstName };
            const response = await axios.post('/api/email/sendusingtemlpate', { templateId, data });
            await axios.post('/api/execedge/updateConnectionStatusByEmail', {
                ExecEdgeId: Project._id,
                candidateId: data.candidateId._id,
                Status: templates.find(t => t._id === templateId).name,
            });
            setSelectedDecisionMakers([]);
            setOpenPreview(false);
            fetchDecisionMakers();
            fetchemails();
        } catch (error) {
            console.error('Error sending email', error);
        }
    });
    }

    return (
        <Container>
            {/* Filters */}
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                            <Box display="flex" justifyContent="left" gap={1} width="70%">
                        {["Initial Outreach Pending", "Followup Pending", "Closing Outreach Pending", "Outreach Completed", "Response Received","Rejected"].map((state) => (
                            <Button
                                key={state}                                                
                                variant="contained"
                                color={status == state ? "info" : "inherit"} 
                                size="small"    
                                onClick={() => {
                        setStatus(state);
                        setShowSettings(false);
                        switch(state)
                            {
                                case "Initial Outreach Pending": 
                                    setTemplate(templates.find(t => t.name === "Initial Outreach")._id);
                                    break;
                                case "Followup Pending": 
                                    setTemplate(templates.find(t => t.name === "Follow up")._id);
                                    break;
                                case "Closing Outreach Pending":
                                    setTemplate(templates.find(t => t.name === "Closing Outreach")._id);
                                    break;
                                case "Outreach Completed": 
                                    setTemplate('');
                                    break;
                                case "Response Received": 
                                    setTemplate('');
                                    break;
                                case "Rejected": 
                                    setTemplate('');
                                    break;
                            }                        
                    }}
                >
                    {state} ({decisionMakers.filter(dm => filterstatus[state].includes(dm.status)).length})
                </Button>
            ))}
            </Box>
            <Box display="flex" justifyContent="right" gap={2}>
            <Button
                variant="contained"
                color="info"
                startIcon={<SettingsIcon />}
                onClick={() => {
                    showSettings ? setShowSettings(false) : setShowSettings(true);
                }}
            >
                {showSettings ? "Hide" :"Setup Emails"} 
            </Button>
            </Box>
            </Box>

            {showSettings && (
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                    <Setup Project={Project} />
                </Box>
            )}

            { !showSettings && (
            <TableContainer component={Paper}>
                        {(selectedDecisionMakers.length > 0 && template !=='') && (
                            <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={2} 
                            sx={{
                            padding: 2,
                            border: "1px solid #ececec",
                            borderRadius: "8px",
                            fontSize: "0.9rem",
                            lineHeight: 1.5,
                            overflowWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            }}>
                                {selectedDecisionMakers.length} Decision Makers selected. <Button variant="contained" onClick={() => handlePreview(template)}>Send {templates.find(t => t._id === template)?.name}</Button>
                            </Box>
                        )}

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    [ "Followup Pending", "Closing Outreach Pending"].includes(status) ?                                                                                                        
                                                    (selectedDecisionMakers.length === decisionMakers.filter(dm => filterstatus[status].includes(dm.status) && new Date(dm.updatedAt) <= new Date(new Date().setDate(new Date().getDate() - DueDays))).length && decisionMakers.filter(dm => filterstatus[status].includes(dm.status) && new Date(dm.updatedAt) <= new Date(new Date().setDate(new Date().getDate() - DueDays))).length > 0)
                                                    : (selectedDecisionMakers.length === decisionMakers.filter(dm => filterstatus[status].includes(dm.status)).length && decisionMakers.filter(dm => filterstatus[status].includes(dm.status)).length > 0) 
                                                }
                                                onChange={(e) => {
                                        if (e.target.checked && [ "Followup Pending", "Closing Outreach Pending"].includes(status) ) {
                                            setSelectedDecisionMakers(decisionMakers.filter(dm => filterstatus[status].includes(dm.status) && new Date(dm.updatedAt) <= new Date(new Date().setDate(new Date().getDate() - DueDays))).map(dm => dm._id ));
                                        } 
                                        else if (e.target.checked) {
                                            
                                                setSelectedDecisionMakers(decisionMakers.filter(dm => filterstatus[status].includes(dm.status)).map(dm => dm._id ));
                                            }
                                            else {
                                            setSelectedDecisionMakers([]);
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell>Added on</TableCell>
                            <TableCell>Company</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                            {([ "Followup Pending", "Closing Outreach Pending"].includes(status)) && (
                            <TableCell>Next Followup</TableCell>)}
                            {([ "Followup Pending", "Closing Outreach Pending","Outreach Completed", "Response Received"].includes(status)) && (
                            <TableCell>Email Status</TableCell>)}
                            <TableCell>Action</TableCell>                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { 
                        decisionMakers.filter(dm => filterstatus[status].includes(dm.status)).map(dm => dm._id).map((decisionMakerId) => {
                            const decisionMaker = decisionMakers.find(dm => dm._id === decisionMakerId);
                            if (!decisionMaker) return null;
                            const company = Project.TargetCompany.find(tc => tc.Organization === decisionMaker.TargetCompanyId);
                            return (
                                <TableRow key={decisionMaker._id}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedDecisionMakers.includes(decisionMaker._id)}
                                            disabled={!(["Initial Outreach Pending"].includes(status) || (new Date(decisionMaker.updatedAt) <= new Date(new Date().setDate(new Date().getDate() - DueDays))))}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedDecisionMakers([...selectedDecisionMakers, decisionMaker._id]);
                                                } else {
                                                    setSelectedDecisionMakers(selectedDecisionMakers.filter(id => id !== decisionMaker._id));
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{new Date(decisionMaker.createdAt).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        <CompanyLogo companyName={company?.Name || ""} sx={{ width: '60px', height: '60px' }} />
                                        <br/>
                                        {company ? company.Name : 'Unknown'}
                                    </TableCell>
                                    <TableCell>
                                    <Link href={decisionMaker.candidateId.linkedin} target="_blank" rel="noopener">
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Avatar
                                        src= {decisionMaker.candidateId.dp}
                                        sx={{ width: 50, height: 50, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}
                                        />
                                        <div style={{marginLeft: '10px'}}>
                                        {decisionMaker.candidateId.name}
                                        <br/>{decisionMaker.Designation}
                                        <Linkedin />
                                        </div>
                                        </div>
                                        </Link></TableCell>
                                    <TableCell>
                                        {decisionMaker.status === "Initial Outreach" ? "Followup Pending" : 
                                         decisionMaker.status === "Follow up" ? "Closing Outreach Pending" : 
                                         decisionMaker.status}
                                    </TableCell>                                    
                                    {([ "Followup Pending", "Closing Outreach Pending"].includes(status)) && (
                                    <TableCell>
                                        {(() => {
                                            const daysDifference = Math.floor((new Date() - new Date(decisionMaker.updatedAt)) / (1000 * 60 * 60 * 24)) - DueDays;
                                            if (daysDifference > 0) {
                                                return `${daysDifference} Days overdue`;
                                            } else if (daysDifference < 0) {
                                                return `After ${Math.abs(daysDifference)} Days`;
                                            } else if (daysDifference === 0) {
                                                return "Today";
                                            }
                                        })()}
                                    </TableCell>
                                )}
                                
                                {([ "Followup Pending", "Closing Outreach Pending","Outreach Completed", "Response Received"].includes(status)) && (
                                    <TableCell>
                                        {
                                            emails.filter(e => e.to === decisionMaker.WorkEmail).map((e, index) => (
                                            <>
                                            <br /><Link
                                                key={index}
                                                onClick={() => setEmail(e)}
                                                style={{ cursor: "pointer" }}
                                            >
                                             {e.name ? e.name + " - " :"" }{e.status}
                                            </Link>
                                            </>
                                        ))
                                    }                                    
                                    </TableCell> )}
                                    <TableCell>
                                        {["Initial Outreach Pending", "Followup Pending", "Closing Outreach Pending"].includes(status) && (
                                            <>
                                            { (["Initial Outreach Pending"].includes(status) || (new Date(decisionMaker.updatedAt) <= new Date(new Date().setDate(new Date().getDate() - DueDays))))&& (
                                                <Button 
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        handlePreview(template,decisionMaker._id)
                                                    }}
                                                >
                                                    Send Email
                                                </Button> )}
                                                {status !== "Initial Outreach Pending" && (
                                                    <Button 
                                                        variant="contained"
                                                        color="success"
                                                        size="small"
                                                        onClick={() => handleStatus(decisionMaker.candidateId._id, "Response Received")}
                                                    >
                                                        Mark Response Received
                                                    </Button>
                                                )}
                                                {status === "Initial Outreach Pending" && (
                                                    <Button 
                                                        variant="contained"
                                                        color="error"
                                                        size="small"
                                                        onClick={() => handleStatus(decisionMaker.candidateId._id, "Rejected")}
                                                    >
                                                        Reject Lead
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                        {status === "Outreach Completed" && (
                                            <Button 
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                onClick={() => handleStatus(decisionMaker.candidateId._id, "Response Received")}
                                            >
                                                Response Received
                                            </Button>
                                        )}
                                        {decisionMaker.status === "Response Received" && (
                                            <>
                                                <Button 
                                                    variant="contained"
                                                    color="success"
                                                    size="small"
                                                    onClick={() => handleStatus(decisionMaker.candidateId._id, "Positive Outcome")}
                                                >
                                                    Positive Outcome
                                                </Button>
                                                <Button 
                                                    variant="contained"
                                                    color="warning"
                                                    size="small"
                                                    onClick={() => handleStatus(decisionMaker.candidateId._id, "Negative Outcome")}
                                                >
                                                    Negative Outcome
                                                </Button>
                                            </>
                                        )}
                                        {status === "Rejected" && (
                                            <Button 
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                onClick={() => handleStatus(decisionMaker.candidateId._id, "Initial Outreach Pending")}
                                            >
                                                Send for Outreach
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            )}

            <Modal open={OpenPreview} width="80%" onOk={() => handelSendEmail(preview[0]?.id)} onCancel={() => setOpenPreview(false)} okText="Send Mail">
                {preview.length > 0 && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            // Logic to edit the template
                            setShowSettings(true);
                            setOpenPreview(false);
                        }}
                    >
                        Edit Template
                    </Button>
                    )}    
                        <Card>
                            <CardHeader title="Email Preview" />
                            {(i > 0 && preview.length > 1) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setI((i - 1 + preview.length) % preview.length)}
                                >
                                    Previous
                                </Button>
                            )}
                            {(i < (preview.length -1) && preview.length > 1) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setI((i + 1) % preview.length)}
                                >
                                    Next
                                </Button>
                            )}
                            <CardContent>
                                <Typography variant="h6">Subject: {preview[i]?.subject}</Typography>
                                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: preview[i]?.body }} />
                            </CardContent>
                        </Card>
                    
                
            </Modal>

            <Modal open={!!email.to} width="70%" onOk={() => setEmail({})} onCancel={() => setEmail({})}>
            <Card
            sx={{
            padding: 2,
            borderRadius: "10px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
            maxHeight: "75vh",
            overflowY: "auto",
            }}
            >
            <CardHeader
            title={email.subject || "No Subject"}
            titleTypographyProps={{
            variant: "h6",
            fontWeight: "bold",
            color: "primary",
            textAlign: "center",
            }}
            sx={{ paddingBottom: 0 }}
            />
            <CardContent>
            <Box
            sx={{
            padding: 2,
            border: "1px solid #ececec",
            borderRadius: "8px",
            fontSize: "0.9rem",
            lineHeight: 1.5,
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            }}
            dangerouslySetInnerHTML={{
            __html: email.body
            ? email.body.replace(
            /https:\/\/vibrant-catbird-399009\.el\.r\.appspot\.com\/click\/\S+\?url=(\S+)&source=\S+/g,
            (match, p1) => `<a href="${decodeURIComponent(p1)}" target="_blank" style="color: #1e88e5;">${decodeURIComponent(p1)}</a>`
            )
            : "<p>No email body available.</p>",
            }}
            />

            {email.attachments && email.attachments.length > 0 && (
            <Box mt={2}>
            <Typography
            variant="subtitle2"
            color="textSecondary"
            fontWeight="bold"
            gutterBottom
            >
            Attachments:
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
            {email.attachments.map((attachment, index) => (
            <Button
            key={index}
            variant="outlined"
            color="primary"
            href="#"
            target="_blank"
            startIcon={<AttachmentIcon />}
            sx={{
                borderRadius: "8px",
                fontSize: "0.8rem",
                textTransform: "none",
            }}
            >
            {attachment.name}
            </Button>
            ))}
            </Box>
            </Box>
            )}

            {email.logs && email.logs.length > 0 && (
            <Box mt={2}>
            <Typography
            variant="subtitle2"
            color="textSecondary"
            fontWeight="bold"
            gutterBottom
            >
            Activity Logs:
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
            {email.logs.map((log, index) => (
            <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                padding: "8px",
                borderRadius: "6px",
            }}
            >
            <Typography
                variant="body2"
                sx={{ fontWeight: "bold", color: log.event === "Opened" ? "green" : "black" }}
            >
                {log.event}
            </Typography>
            <Typography variant="caption" color="textSecondary">
                {new Date(log.timestamp).toLocaleString()}
            </Typography>
            </Box>
            ))}
            </Box>
            </Box>
            )}
            </CardContent>
            </Card>
            </Modal>

            <Dialog open={openModal} onClose={handleCloseModal}>
                    <DialogTitle>Tell us more</DialogTitle>
                    <DialogContent>
                        <TextField
                            label=""
                            value= {reason}
                            onChange={(e) => {
                                setReason(e.target.value);
                                setNewComment({
                                    comment: ModalStatus + " : " + e.target.value,
                                    user: Project._id,
                                    userName: user.name,
                                    timestamp: new Date()
                                });
                                
                            }}                          
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal}>Cancel</Button>
                        <Button onClick={() => handleSubmit(newComment)}  color="primary">Submit</Button>
                    </DialogActions>
                </Dialog>
        </Container>
    );
}

export default Reachouts;