import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';


const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
      console.log("AuthProvider mounted and context provided");
    } else {
      localStorage.removeItem('user');
      setUser({ role: 'guest' });
    }
  }, [user]);

  const updateProfile = async (values) => {
    if (!user || !user.id) {
      console.error('No user id available for update'+ JSON.stringify(values));
  //    alert('You must be logged in to update your profile.');
      return;
    }

    try {
      const updates = { ...values };
      const response = await axios.post('/api/users/update', {
        id: user.id,
        updates
      });

      setUser(response.data);
      console.log('Profile updated successfully');
    } catch (error) {
      console.error('Failed to update profile:', error);
      alert('Update failed: ' + error.message);
    }
  };


  const isAdmin = user && user.role === 'admin';
  const isCandidate = user && user.role === 'candidate';
  const isClient = user && user.role === 'client';


  const login = async (email, password) => {
    try {
      const response = await axios.post('/api/login', { email, password });
      if (response.data && response.data.user) {
        setUser(response.data.user);
        return response.data.user;
      } else {
        throw new Error('User data is incomplete.');
      }
    } catch (error) {
      console.error('Login failed:', error.response?.data?.message || error.message);
      throw error;
    }
  };


  const oauthLogin = async (idToken, accessToken) => {
    try {
      console.log('OAuth login with ID Token:', idToken);
      console.log('OAuth login with Access Token:', accessToken);
      const response = await axios.post('/api/oauth-login', { token: idToken, accessToken });
      if (response.data && response.data.user) {
        setUser(response.data.user);
        localStorage.setItem('user', JSON.stringify(response.data.user)); // Store the user
        return response.data.user;
      } else {
        throw new Error('OAuth login failed');
      }
    } catch (error) {
      console.error('OAuth login failed:', error.response?.data?.message || error.message);
      throw error;
    }
  };
  
  const ooauthLogin = async (token, accessToken) => {
    try {
      const response = await axios.post('/api/oauth-login', { token, accessToken });
      if (response.data && response.data.user) {
        setUser(response.data.user);
        return response.data.user;
      } else {
        throw new Error('OAuth login failed');
      }
    } catch (error) {
      console.error('OAuth login failed:', error.response?.data?.message || error.message);
      throw error;
    }
  };

  const signup = async (values) => {
    try {
      const response = await axios.post('/api/signup', values);
      setUser(response.data.user);
      return response.data.user;
    } catch (error) {
      console.error('Signup failed:', error.response?.data?.message || error.message);
      throw new Error(error.response?.data?.message || 'Signup failed');
    }
  };

  const logout = async () => {
    try {
      await axios.post('/api/logout');
      setUser(null);
      setUser({ role: 'guest' });
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

 
  
  const OhandleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { id_token: idToken, access_token: accessToken } = tokenResponse;
        console.log('Google ID Token:', idToken);
        console.log('Google Access Token:', accessToken);

        // Send the ID token and access token to your server
        await oauthLogin(idToken, accessToken);
      } catch (error) {
        console.error('Google login failed:', error.message);
      }
    },
    onError: (error) => {
      console.error('Google login error:', error);
    },
    
  });

  return (
    <AuthContext.Provider value={{ user, login, logout, oauthLogin, signup, updateProfile, isAdmin, isCandidate, isClient }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
