import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const OAuthCallback = () => {
    const { setUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const user = queryParams.get('user');

        if (user) {
            const parsedUser = JSON.parse(decodeURIComponent(user));
            setUser(parsedUser);
            const returnTo = localStorage.getItem('returnTo') || '/';
            localStorage.removeItem('returnTo');
            navigate(returnTo);
        } else {
            navigate('/login');
        }
    }, [setUser, navigate]);

    return <div>Loading...</div>;
};

export default OAuthCallback;
