import React from 'react';
import { Box, Typography, Button } from '@mui/material';
//import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
//import { Bar } from 'react-chartjs-2';

// Register Chart.js components
//ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AnalyticsDashboard = () => {
  const data = {
    labels: ['Opened', 'Clicked', 'Bounced'],
    datasets: [
      {
        label: 'Email Stats',
        data: [75, 50, 5],
        backgroundColor: ['#4caf50', '#2196f3', '#f44336'],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: 'Email Campaign Analytics' },
    },
  };

  return (
    <Box p={3}>
      <Typography variant="h5" mb={3}>
        Analytics Dashboard
      </Typography>
      
      <Box mt={3}>
        <Button variant="contained" color="primary" sx={{ mr: 2 }}>
          Resend
        </Button>
        <Button variant="outlined" color="secondary" sx={{ mr: 2 }}>
          View Details
        </Button>
        <Button variant="text" color="error">
          Stop Campaign
        </Button>
      </Box>
    </Box>
  );
};

export default AnalyticsDashboard;