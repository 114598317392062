import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Button, Select, Form, Row, Col, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

const { Option } = Select;

function FileUpload() {
    const [file, setFile] = useState(null);
    const [parsedData, setParsedData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [mapping, setMapping] = useState({});

    const schemaFields = [
      'bb_id',
      'name',
      'email',
      'gender',
      'linkedin',
      'marital_status',
      'phone',
      'headline',
      'currentCompany',
      'currentRole',
      'currentLocation',
      'compensation',
      'expectation',
      'remarks',
      'experience_yrs',
      'noticePeriod',
      'dp',
      'experience-organization-0',
      'experience-designation-0',
      'experience-department-0',
      'education-degree-0',
      'education-institution-0',
      'education-endDate-0',
      'education-degree-1',
      'education-institution-1',
      'education-endDate-1',      
      'education-degree-2',
      'education-institution-2',
      'education-endDate-2',
      'resume_text',
      'Category',
      'Links', // Representing the whole array as a single field
      'experience', // Representing the whole array as a single field
      'rating',
      'HV_Lense',
      'skills', // This could be a CSV string that gets split into an array in the backend
      'education', // Representing the whole array as a single field
      'addition' // Representing the whole array as a single field
  ];

    const handleFileChange = info => {
        const file = info.file.originFileObj;
        console.log("file");
        setFile(file);
    };

    const handleUpload = () => {
        const reader = new FileReader();
        
        reader.onload = (e) => {
            const data = e.target.result;
            let rows;

            if (file.type.includes('csv')) {
                rows = Papa.parse(data, { header: true }).data;
            } else {
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                rows = XLSX.utils.sheet_to_json(worksheet);
            }

            if (rows.length > 0) {
                setParsedData(rows);
                setColumns(Object.keys(rows[0]));
            }
        };

        if (file && file.type.includes('csv')) {
            reader.readAsBinaryString(file);
        } else if (file) {
            reader.readAsArrayBuffer(file);
        }
    };

    useEffect(() => {
      if (columns.length > 0 && Object.keys(mapping).length === 0) {
          const initialMapping = schemaFields.reduce((acc, field) => {
              acc[field] = "";
              return acc;
          }, {});
          setMapping(initialMapping);
      }
    }, [columns, schemaFields]);

    const handleMappingChange = (field, value) => {
        setMapping(prev => ({ ...prev, [field]: value }));
    };

    const handleSubmitData = () => {
        const reformattedData = parsedData.map(row => {
            return schemaFields.reduce((acc, field) => {
                acc[field] = row[mapping[field]];
                return acc;
            }, {});
        });

        axios.post('https://api.hireveda.com/uploadData', { data: reformattedData })
            .then(response => {
                console.log('Data successfully uploaded:', response);
                message.success('Data successfully uploaded');
            })
            .catch(error => {
                console.error('Error uploading data:', error);
                message.error('Error uploading data');
            });
    };

    const uploadProps = {
        beforeUpload: file => false,
        onChange: handleFileChange,
        multiple: false,
        onRemove: () => setFile(null)
    };

    return (
        <div style={{ padding: '20px' }}>
            <Row gutter={16} style={{ marginBottom: '20px' }}>
                <Col span={12}>
                    <Upload {...uploadProps} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                    <Button type="primary" onClick={handleUpload} disabled={!file} style={{ marginLeft: '10px' }}>
                        Parse File
                    </Button>
                </Col>
            </Row>
            {columns.length > 0 && (
                <Form layout="vertical" onFinish={handleSubmitData}>
                    <h3>Map Columns to Database Fields</h3>
                    {schemaFields.map(field => (
                        <Form.Item key={field} label={field}>
                            <Select
                                showSearch
                                placeholder="Select a column"
                                onChange={value => handleMappingChange(field, value)}
                                value={mapping[field]}
                            >
                                {columns.map(column => (
                                    <Option key={column} value={column}>{column}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ))}
                    <Button type="primary" htmlType="submit">
                        Submit Data
                    </Button>
                </Form>
            )}
        </div>
    );
}

export default FileUpload;
