import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Avatar, Typography, Button, IconButton, Tabs, Tab, Grid, Paper,Chip } from '@mui/material';
import { LinkedIn,  Description, Work, Phone, Email, School } from '@mui/icons-material';
import CompanyLogo from '../CompanyLogo';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

const CandidateModal = ({ visible, onClose, candidate, changeOptionsMap, handleChangeStatus, editCdd, handlePriorityChange }) => {
  const [tab, setTab] = React.useState(0);
  const [showPriority, setShowPriority] = React.useState(false);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  
  const formatDate = (dateStr) => {
    if (!dateStr) return 'Present'; // Handle null or undefined dates
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { month: 'short', year: '2-digit' });
  };
 
  const formatDateYear = (dateStr) => {
    if (!dateStr) return 'Present'; // Handle null or undefined dates
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { year: 'numeric' });
  };

  const handlePriority = (event) => {
    handlePriorityChange(candidate.id, event.target.value);
    setShowPriority(false);
  }

  return (
    <Modal open={visible} onClose={onClose}>
      <Box sx={{ width: '80%', margin: 'auto', mt: 5, p: 4, backgroundColor: 'background.paper', borderRadius: 2, maxHeight: '90vh', overflowY: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Avatar src={candidate.dp || ''} sx={{ width: 60, height: 60 }} />
          <Box sx={{ width: '30%'}}>
            <Typography variant="h6">{candidate.name}</Typography>           
            <Typography variant="body2">
              {candidate.currentRole} at {candidate.currentCompany} - {candidate.currentLocation}
              ( {Math.floor(candidate.experience_yrs)} Y {Math.round((candidate.experience_yrs % 1) * 12)} M )
            </Typography>
            {candidate.linkedin && (
              <IconButton component="a" href={candidate.linkedin} target="_blank">
                <LinkedIn />
              </IconButton>
            )}
            {candidate.phone && (
              <IconButton component="a" href={`tel:${candidate.phone}`}>
                <Phone />
              </IconButton>
            )}
            {candidate.email && (
              <IconButton component="a" href={`mailto:${candidate.email}`}>
                <Email />
              </IconButton>
            )}
          </Box>
          <Box>
            
          <Typography variant="body2"> {candidate.Client} - {candidate.RoleName} {candidate.status} 
          {!showPriority && (
            <Chip
              label={candidate.priority}
              sx={{
                backgroundColor: candidate.priority === 'P2' ? 'red' : candidate.priority === 'P1' ? 'orange' : 'green',
                color: 'white',
                mb: 1
              }}
              onClick={() => setShowPriority(true)}
            />
          )}

          {showPriority && (
            <select onChange={handlePriority}>
              <option value=""></option>
              <option value="P0">P0</option>
              <option value="P1">P1</option>
              <option value="P2">P2</option>
              <option value="P3">P3</option>
            </select>
          )} </Typography>
            {changeOptionsMap[candidate.status]?.map((option) => (
              <Button
                key={option}
                size="small"
                variant="outlined"
                onClick={() => handleChangeStatus(candidate.id, option)}
                sx={{ mr: 1, mt: 2 }}
              >
                {option}
              </Button>
            ))}
          </Box>
          <Box sx={{ top: 10, right: 10 }}>
          <IconButton onClick={() => editCdd()}>
          <EditIcon /></IconButton>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          </Box>
        </Box>
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Details" />
          <Tab label="Resume" />
          <Tab label="Log" />
        </Tabs>
        {tab === 0 && (
          <Box>
            <Grid container spacing={2} sx={{ padding: 2 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Work sx={{ mr: 1 }} /> Work Experience
                </Typography>
                

                <Paper elevation={2} sx={{ padding: 2, marginBottom: 1, display: 'flex', alignItems: 'left', flexFlow: 'column' }}>
                  {candidate.experience && candidate.experience.map((exp, index) => (
                    <div style={{ clear: 'both', width: '100%' }} key={index}>
                      <Box>
                        <CompanyLogo companyName={exp.organization} style={{ marginRight: '10px', marginLeft: '10px', float: 'left', width: '40px' }} />
                        <Typography variant="body2" style={{ float: 'left' }}>
                          <strong>{exp.designation && exp.designation.length > 40 ? exp.designation.substring(0, 40) : exp.designation}</strong>
                          <br />{exp.organization && exp.organization.length > 40 ? exp.organization.substring(0, 40) : exp.organization}
                        </Typography>
                        {exp.startDate && (
                          <Typography variant="caption" style={{ float: 'right' }}>
                            {formatDate(exp.startDate)} - {formatDate(exp.endDate)} <br /> [{Math.floor(exp.duration)} yr {Math.round(exp.duration % 1 * 12)} mo]
                          </Typography>
                        )}
                      </Box>
                    </div>
                  ))}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                  <School sx={{ mr: 1 }} /> Education
                </Typography>
                <Paper elevation={2} sx={{ padding: 2, marginBottom: 1, display: 'flex', alignItems: 'center', flexFlow: 'column' }}>
                  {candidate.education && candidate.education.map((edu, index) => (
                    <div style={{ clear: 'both', width: '100%' }} key={index}>
                      <CompanyLogo companyName={edu.institution} style={{ marginRight: '10px' }} />
                      <Box>
                        <Typography
                          variant="body2"
                          style={{ float: 'left' }}
                          title={edu.degree && edu.degree.length > 40 ? edu.degree : ''}
                        >
                          <strong>
                            {edu.degree && edu.degree.length > 40 ? `${edu.degree.substring(0, 40)}...` : edu.degree}
                          </strong>
                          <br />
                          {edu.institution && edu.institution.length > 40 ? `${edu.institution.substring(0, 40)}...` : edu.institution}
                        </Typography>
                        {edu.startDate && (
                          <Typography variant="caption" style={{ float: 'right' }}>
                            {formatDateYear(edu.startDate)} - {formatDateYear(edu.endDate)}
                          </Typography>
                        )}
                      </Box>
                    </div>
                  ))}
                
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={24} md={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="subtitle1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Description sx={{ mr: 1 }} /> Notice Period : {candidate.noticePeriod}
                  </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                  CTC : {candidate.compensation} LPA
                  </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                  Expectation : {candidate.expectation} LPA
                </Typography>
                </Box>
                </Grid>
          </Box>
        )}
        {tab === 1 && candidate.resume && (
          <Box>
            <Typography variant="h6">Resume</Typography>
            <iframe
              src={candidate.resume}
              title="Resume"
              style={{ width: '100%', height: '500px' }}
              frameBorder="0"
            />
          </Box>
        )}
        {tab === 2 && (
          <Box>
            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
              {candidate.log &&
                candidate.log.map((log, index) => (
                  <Box key={index} sx={{ mb: 1 }}>
                    <Typography variant="caption" color="textSecondary">
                      {log.timestamp} - {log.userName} - {log.status}
                    </Typography>
                    <Typography variant="body2">{log.message}</Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        )}
      </Box>
    </Modal>    
  );
};
CandidateModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  candidate: PropTypes.shape({
    dp: PropTypes.string,
    name: PropTypes.string,
    Client: PropTypes.string,
    RoleName: PropTypes.string,
    status: PropTypes.string,
    currentRole: PropTypes.string,
    currentCompany: PropTypes.string,
    currentLocation: PropTypes.string,
    experience_yrs: PropTypes.number,
    linkedin: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    experience: PropTypes.arrayOf(
      PropTypes.shape({
        organization: PropTypes.string,
        designation: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        duration: PropTypes.number,
      })
    ),
    education: PropTypes.arrayOf(
      PropTypes.shape({
        institution: PropTypes.string,
        degree: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
      })
    ),
    noticePeriod: PropTypes.string,
    compensation: PropTypes.number,
    expectation: PropTypes.number,
    resume: PropTypes.string,
  }).isRequired,
  changeOptionsMap: PropTypes.object.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
};

export default CandidateModal;