import React from 'react';
import { Container, Card, CardContent, Typography, Button, Box } from '@mui/material';
import { motion } from 'framer-motion';

const Signup = () => {
    return (
        <Container maxWidth="md" sx={{ py: 6, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
            <Card elevation={3} sx={{ textAlign: 'center', p: 5, borderRadius: 3, backgroundColor: '#ffffff', maxWidth: '700px' }}>
                <CardContent>
                    <Typography variant="h4" fontWeight={500} gutterBottom sx={{ color: '#1a1a1a', fontFamily: 'Roboto Slab' }}>
                        ExecEdge by HireVeda
                    </Typography>
                    
                    <Typography variant="h6" color="text.secondary" gutterBottom>
                        Elevate Your Career with Exclusive Leadership Opportunities
                    </Typography>

                    <Typography variant="body1" color="text.secondary" paragraph>
                        ExecEdge is a tailored career acceleration platform for senior professionals and executives ready to make strategic career moves.
                    </Typography>

                    <Box component="ul" textAlign="left" sx={{ mx: 'auto', maxWidth: '80%', color: 'text.secondary', listStyle: 'none', pl: 0 }}>
                        <Typography component="li" variant="body1" sx={{ mb: 1 }}>
                            • Access <b>exclusive</b> executive roles
                        </Typography>
                        <Typography component="li" variant="body1" sx={{ mb: 1 }}>
                            • Build a <b>high-impact</b> leadership network
                        </Typography>
                        <Typography component="li" variant="body1">
                            • Secure roles that align with your <b>strategic vision</b>
                        </Typography>
                    </Box>

                    <Typography variant="body1" mt={3} sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                        "The right opportunity is not just a job—it's a turning point."
                    </Typography>

                    {/* Subtle Call-to-Action Button */}
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        style={{ marginTop: '30px' }}
                    >
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            size="large" 
                            sx={{ px: 4, py: 1.2, fontSize: '1rem', borderRadius: 3, borderColor: '#1a1a1a', color: '#1a1a1a', textTransform: 'none' }}
                            href="https://hireveda.fillout.com/t/bWc2v4PHWous"
                        >
                            Apply for Exclusive Access
                        </Button>
                    </motion.div>
                </CardContent>
            </Card>
        </Container>
    );
};

export default Signup;
