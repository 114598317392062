import { useState, useEffect } from "react";
import { gapi } from "gapi-script";

const CLIENT_ID = "14388555053-la7s7phrsfaoemqnrusr4njrlijhd3ke.apps.googleusercontent.com"; // Replace with your OAuth client ID
const SCOPES = "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly";

export default function EmailReaderDashboard() {
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState({ to: "", subject: "", body: "" });
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    const storedToken = localStorage.getItem("google_token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const handleAuth = () => {
    const tokenClient = gapi.auth2.getAuthInstance().signIn({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: (response) => {
        setToken(response.access_token);
        localStorage.setItem("google_token", response.access_token);
      },
    });
    tokenClient.requestAccessToken();
  };

  const sendEmail = async () => {
    if (!token) return alert("Please sign in first!");

    const emailContent = `To: ${email.to}\nSubject: ${email.subject}\nContent-Type: text/plain; charset=\"UTF-8\"\n\n${email.body}`;
    const encodedMessage = btoa(unescape(encodeURIComponent(emailContent)));

    await fetch("https://www.googleapis.com/gmail/v1/users/me/messages/send", {
      method: "POST",
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      body: JSON.stringify({ raw: encodedMessage }),
    });

    alert("Email Sent!");
  };

  const fetchEmails = async () => {
    if (!token) return alert("Please sign in first!");

    const response = await fetch("https://www.googleapis.com/gmail/v1/users/me/messages", {
      headers: { Authorization: `Bearer ${token}` },
    });

    if(!response.ok) {
      setToken(null);
      return alert("Failed to fetch emails.");
    }

    const data = await response.json();
    let emails = [];
    data.messages.forEach(async (element) => {
      const emailResponse = await fetch(`https://www.googleapis.com/gmail/v1/users/me/messages/${element.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      emails.push(await emailResponse.json());
    });
    setEmails(emails || []);
  };

  return (
    <div className="p-4 max-w-lg mx-auto bg-white shadow-md rounded-lg">
      <h2 className="text-xl font-bold">Gmail Client Demo</h2>
      {!token ? (
        <button className="p-2 bg-blue-500 text-white rounded" onClick={handleAuth}>
          Sign in with Google
        </button>
      ) : (
        <>
          <div className="mt-4">
            <input
              type="email"
              placeholder="Recipient"
              value={email.to}
              onChange={(e) => setEmail({ ...email, to: e.target.value })}
              className="border p-2 w-full rounded"
            />
            <input
              type="text"
              placeholder="Subject"
              value={email.subject}
              onChange={(e) => setEmail({ ...email, subject: e.target.value })}
              className="border p-2 w-full rounded mt-2"
            />
            <textarea
              placeholder="Email Body"
              value={email.body}
              onChange={(e) => setEmail({ ...email, body: e.target.value })}
              className="border p-2 w-full rounded mt-2"
            />
            <button className="p-2 bg-green-500 text-white rounded mt-2" onClick={sendEmail}>
              Send Email
            </button>
          </div>

          <button className="p-2 bg-gray-500 text-white rounded mt-4" onClick={fetchEmails}>
            Fetch Emails
          </button>

          <ul className="mt-4">
            {emails.map((email) => (
              <li key={email.id} className="p-2 border-b">Email ID: {email.id}</li>
            ))}
          </ul>
          
          <button className="p-2 bg-red-500 text-white rounded mt-4" onClick={() => {
          localStorage.removeItem("google_token");
          setToken(null);
          }}>
          Sign Out
          </button>
        </>
      )}
    </div>
  );
}


/*import React, { useState, useEffect } from 'react';

const EmailReaderDashboard = () => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchEmails = async () => {
    try {
      const response = await fetch('/api/emails'); // Replace with your API endpoint
      const data = await response.json();
      setEmails(data || []);
    } catch (err) {
      setError('Failed to fetch emails.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  if (loading) return <p>Loading emails...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h1>Email Dashboard</h1>
      {emails.length > 0 ? (
        emails.map((email) => (
          email.body && (
            <div key={email._id} className="email-card">
              <h3>{email.subject}</h3>
              <p><strong>From:</strong> {email.from}</p>
              <div dangerouslySetInnerHTML={{ __html: email.body.replace(/\n/g, '<br>') }} />
            </div>
          )
        ))
      ) : (
        <p>No emails found!</p>
      )}
    </div>
  );
};
//*/
//export default EmailReaderDashboard; 