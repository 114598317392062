import React, { useRef, useState, useEffect } from 'react'; 
import axios from 'axios'; 
import CandidateModal from './CandidateModal'; 
import { Container,  Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,  FormControl, Checkbox,  Avatar, IconButton, Drawer, Modal,  FormControlLabel
    , Autocomplete, Chip, TextField
 } from '@mui/material'; 
import FilterListIcon from '@mui/icons-material/FilterList'; 
import CloseIcon from '@mui/icons-material/Close'; 
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import CandidateForm from '../utils/candidateForm';
import { PriorityHigh } from '@mui/icons-material';

const fields = [ 'Name', 'Email', 'Phone', 'Status', 'Current Company', 'Current Role', 'Compensation', 'Gender', 'Marital Status', 'Expectation', 'Experience Years', 'Notice Period', 'LinkedIn', 'Resume', 'Priority', 'Category', 'Skills', 'Education', 'Experience', 'Source', 'Added On' ]; 




/*

add back button from candidate modal to association page\
edit cross button
option to add comments
add remarks to profile 
add new candidate 


- sort by updated Date 
- added on -> updated date

issues/bugs to fix:
- column drawer not working - set save selection locally - done  /default - Name/Curr co ?curr Role. added on / Exp / priority
- issue in selecting experience -done
- add "added on date " created - done
- add priority -done
- show current filter at the Top -done
- priority don't show blank - done
- option to view next and previous -done
- show phone number -done
- show sourced by - creator name 
- hide resume tab when resume not present - done
- bold status on candidate modal -done
- update details from chrom extension Phone- email
*/
const Associations = () => { 
    const [selectedStatus, setSelectedStatus] = useState(''); 
    const [stages, setStages] = useState([]); 
    const [changeOptionsMap, setChangeOptionsMap] = useState({}); 
    const [selectedStage, setSelectedStage] = useState(''); 
    const [visibleFields, setVisibleFields] = useState(['Name', 'Phone', 'Status']); 
    const [associations, setAssociations] = useState([]); 
    const [page, setPage] = useState(0); 
    const [loading, setLoading] = useState(false); 
    const [hasMore, setHasMore] = useState(true); 
    const [clients, setClients] = useState([]); 
    const [selectedClient, setSelectedClient] = useState([]); 
    const [selectedRole, setSelectedRole] = useState([]); 
    const [stageCounts, setStageCounts] = useState({}); 
    const [filtersVisible, setFiltersVisible] = useState(false); 
    const [statusCounts, setStatusCounts] = useState({}); 
    const [columnsVisible, setColumnsVisible] = useState(false); 
    const [modalVisible, setModalVisible] = useState(false); 
    const [currentAssociation, setCurrentAssociation] = useState(null); 
    const [resumeVisible, setResumeVisible] = useState(false); 
    const [editCdd, setEditCdd] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null); 
    const [text, setText] = useState('');
    const [limit, setLimit] = useState(100);
    const [selectedExperienceMin, setSelectedExperienceMin] = useState('');
    const [selectedExperienceMax, setSelectedExperienceMax] = useState('');
    const [selectedCompensationMin, setSelectedCompensationMin] = useState('');
    const [selectedCompensationMax, setSelectedCompensationMax] = useState('');
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [includeFilters, setincludeFilters]  = useState(null);
   
    const observerRef = useRef();
    useEffect(() => {
        const savedColumnsVisible = localStorage.getItem('columnsVisible');
        if (savedColumnsVisible) {
            setColumnsVisible(JSON.parse(savedColumnsVisible));
        }

        const savedSelectedStatus = localStorage.getItem('selectedStatus');
        if (savedSelectedStatus && savedSelectedStatus !== 'undefined') {
            setSelectedStatus(savedSelectedStatus);
        }

        const savedSelectedStage = localStorage.getItem('selectedStage');
        if (savedSelectedStage && savedSelectedStage !== 'undefined') {
            setSelectedStage(savedSelectedStage);
        }

        const savedSelectedClient = localStorage.getItem('selectedClient');
        if (savedSelectedClient) {
            try {
                setSelectedClient(JSON.parse(savedSelectedClient));
            } catch (e) {
                console.error('Error parsing selectedClient from localStorage:', e);
                setSelectedClient([]);
            }
        }

        const savedSelectedRole = localStorage.getItem('selectedRole');
        if (savedSelectedRole) {
            try {
                setSelectedRole(JSON.parse(savedSelectedRole));
            } catch (e) {
                console.error('Error parsing selectedRole from localStorage:', e);
                setSelectedRole([]);
            }
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('columnsVisible', JSON.stringify(columnsVisible));
    }, [columnsVisible]);

    useEffect(() => {
        localStorage.setItem('selectedStatus', selectedStatus);
    }, [selectedStatus]);

    useEffect(() => {
        localStorage.setItem('selectedStage', selectedStage);
    }, [selectedStage]);

    useEffect(() => {
        localStorage.setItem('selectedClient', JSON.stringify(selectedClient));
    }, [selectedClient]);

    useEffect(() => {
        localStorage.setItem('selectedRole', JSON.stringify(selectedRole));
    }, [selectedRole]);
    const fetchAssociations = async () => {
    }
    // Fetch associations with pagination
    const fetchAssociations1 = async () => { 
        //if (loading) return; 
        
        setLoading(true); 
        
        try { 
            //const response = await axios.get('/api/associations/get', { params: { stage: selectedStage, client: selectedClient.join(','), role: selectedRole.join(','), status: selectedStatus, page, text: text, limit: limit } }); 
            const response = await axios.get('/api/associations/get', {
                params: {
                    stage: selectedStage,
                    client: selectedClient.join(','),
                    role: selectedRole.join(','),
                    status: selectedStatus,
                    page,
                    text: text,
                    limit: limit,
                    experienceMin: selectedExperienceMin,
                    experienceMax: selectedExperienceMax,
                    compensationMin: selectedCompensationMin,
                    compensationMax: selectedCompensationMax,
                    company: selectedCompany ? selectedCompany.name : '',
                    location: selectedLocation ? selectedLocation.name : '',
                    includeFilters
                }
            });

            if (Array.isArray(response.data.data)) { 
                setAssociations((prev) => [...prev, ...response.data.data]); 
                setCompanies(response.data.filters.companies || []);
                setLocations(response.data.filters.locations || []);
                setHasMore(response.data.data.length > 0); 
            } else { 
                console.error('Unexpected response data format:', response.data); 
            } 


        } catch (err) { console.error('Error fetching associations:', err); } 
        
        finally { setLoading(false); } 

};
//, [selectedStage, selectedClient, selectedRole, selectedStatus, page, text]);

    // Initial data fetch
    useEffect(() => { 
        fetchExistingData();
        fetchClients(); 
    }, []); 

    // Debounce fetch on filters change with cancellation
    useEffect(() => { 
        const source = axios.CancelToken.source();
        
        const debounceFetch = setTimeout(() => { 
            fetchAssociations1(source.token); 
        }, 300); 
        
        return () => {
            clearTimeout(debounceFetch);
            source.cancel('Operation canceled due to new request.');
        };
        
    }, [selectedStage, selectedClient, selectedRole, page, selectedStatus, text, includeFilters]); 

    // Fetch existing data for stages and options 
    useEffect(() => {
        fetchStausCounts();
    }, [selectedClient, selectedRole, stages]);

    useEffect(() => {
        
        const stageCount = Object.keys(stages).reduce((accumulatedCounts, stage) => {
            const statusList = stages[stage];
            const count = statusList.reduce((sum, status) => sum + (statusCounts[status] || 0), 0);
            accumulatedCounts[stage] = count;
            return accumulatedCounts;
        },{});
        console.log(selectedClient);
        console.log(stageCount);
        setStageCounts(stageCount); 
    }, [statusCounts]);

    const fetchStausCounts = async () => {
        try {
            const response = await axios.get('/api/associations/get-status-count',{ params: { client: selectedClient.join(','), role: selectedRole.join(',') } });     
            
            const statusCount = response.data.reduce((accumulatedCounts, item) => {
                accumulatedCounts[item._id || 'Uncategorized'] = item.count;
                return accumulatedCounts;
            }, {});
            setStatusCounts(statusCount);

        } catch (err) {
            console.error('Error fetching existing data:', err);
        }
    };

    const fetchExistingData = async () => { 
        try { 
            const response = await axios.get('/api/associations/existing-data'); 
            setStages(response.data.stageStatusMap || []);              
            setChangeOptionsMap(response.data.changeOptionsMap || {}); 
            
        
        } catch (err) { console.error('Error fetching existing data:', err); }  
    }; 

    // Fetch clients with active roles
    const fetchClients = async () => { 
        try {  
            const response = await axios.get('/api/associations/clients-with-active-roles');  
            setClients(response.data || []); 
        
        } catch (err) { console.error('Error fetching clients:', err); }  
    }; 

    useEffect(() => {
       
        if (associations.length === 0) {
            if (observerRef.current && observerRef.current instanceof IntersectionObserver) {
                observerRef.current.disconnect();
                observerRef.current = null;
            }
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
            if (entries[0].isIntersecting && hasMore && !loading) {
                console.log('Intersection observed' + associations.length);                           
                if (associations.length % limit === 0) {
                setPage((prevPage) => prevPage + 1); // Triggers fetching new data
                }
            }
            },
            { root: null, rootMargin: '0px', threshold: 1.0 } // Threshold adjusted to 1.0
        );
    
        const target = observerRef.current; // Attach to observerRef
    
        if (target) observer.observe(target);
    
        return () => {
            if (target) observer.unobserve(target); // Cleanup observer
        };
    }, [hasMore, loading, selectedStage, selectedClient, selectedRole, selectedStatus, text]); // Ensure observer reattaches properly
    
   // Handle stage filter change
   const handleStageFilter = (stage) => {
       resetPagination();
       setSelectedStage(stage);
       setSelectedStatus();
   };

   // Handle status filter change
   const handleStatusFilter = (status) => {
       resetPagination();
       setSelectedStatus(status);
       setSelectedStage();
    
   };

    useEffect(() => {
        console.log('Page reset');
    setAssociations([]); // Clear previous data
    setPage(0); // Reset to first page
    setHasMore(true); // Assume there are more pages
   }, [selectedClient, selectedRole, selectedStage, selectedStatus, text]);

   // Reset pagination and associations
const resetPagination = () => {
    setAssociations([]);
    setPage(0);
    setHasMore(true);
};


   // Handle visible fields change
   const handleVisibleFieldsChange = (event) => {
       setVisibleFields(event.target.value);
       localStorage.setItem('visibleFields', JSON.stringify(event.target.value));
       console.log(event.target.value);
   };

const toggleEditCdd = () => {
    setEditCdd(!editCdd);
};

useEffect(() => {
    if (editCdd) {
        // Open the modal when editCdd is true
        setModalVisible(false); // Close the main modal if open
    }
}, [editCdd]);

const syncAssoc = async () => {    
    try {
        const response = await axios.get('/api/associations/sync-associations');
        if (response.status === 200) {
            console.log('Associations synced successfully');
            fetchAssociations(); // Refresh associations after sync
        } else {
            console.error('Failed to sync associations:', response);
        }
    } catch (err) {
        console.error('Error syncing associations:', err);
    }
}

    
const handlePriorityChange = async (associationId, newStatus) => {
    setAssociations((prev) =>
        prev.map((assoc) =>
            assoc.id === associationId ? { ...assoc, priority: newStatus } : assoc
        )
    );
    try {
        // Send request to update the status
        await axios.put(`/api/associations/${associationId}/priority`, { priority: newStatus });

        // If currentAssociation is being displayed in the modal, update it as well
        if (currentAssociation && currentAssociation.id === associationId) {
            setCurrentAssociation((prev) => ({ ...prev, priority: newStatus }));
        }
    } catch (err) {
        console.error('Error changing status:', err);
        // Optionally revert optimistic update if there's an error
        setAssociations((prev) =>
            prev.map((assoc) =>
                assoc.id === associationId ? { ...assoc, priority: prev.find(a => a.id === associationId).priority } : assoc
            )
        );
    }

};

   // Change status of an association
   const handleChangeStatus = async (associationId, newStatus) => {
    // Optimistically update the status in local state
    setAssociations((prev) =>
        prev.map((assoc) =>
            assoc.id === associationId ? { ...assoc, status: newStatus } : assoc
        )
        .filter((assoc) => {
            const matchesStage = !selectedStage || assoc.stage === selectedStage;
            const matchesClient = selectedClient.length === 0 || selectedClient.includes(assoc.client);
            const matchesRole = selectedRole.length === 0 || selectedRole.includes(assoc.role);
            const matchesStatus = !selectedStatus || assoc.status === selectedStatus;
            const matchesText = !text || assoc.name.includes(text) || assoc.email.includes(text) || assoc.phone.includes(text);

            return matchesStage && matchesClient && matchesRole && matchesStatus && matchesText;
        })
    );


    try {
        // Send request to update the status
        await axios.put(`/api/associations/${associationId}/status`, { status: newStatus });

        // If currentAssociation is being displayed in the modal, update it as well
        if (currentAssociation && currentAssociation.id === associationId) {
            setCurrentAssociation((prev) => ({ ...prev, status: newStatus }));
            fetchAssociations1();
        }
    } catch (err) {

        console.error('Error changing status:', err);
        // Optionally revert optimistic update if there's an error
        setAssociations((prev) =>
            prev.map((assoc) =>
                assoc.id === associationId ? { ...assoc, status: prev.find(a => a.id === associationId).status } : assoc
            )
        );
    }
};
   // Load saved visible fields from local storage
   useEffect(() => {
       const savedFields = localStorage.getItem('visibleFields');
       if (savedFields) {
           try {
               const parsedFields = JSON.parse(savedFields);
               setVisibleFields(parsedFields);
           } catch (e) {
               console.error('Error parsing visibleFields from localStorage:', e);
           }
       }
   }, []);

   // Clear all filters
   const clearFilters = () => {
       resetPagination();
       setSelectedClient([]);
       setSelectedRole([]);
       setSelectedStage('');
   };

   // Open modal for candidate details
   const handleOpenModal = (association) => {
       setCurrentAssociation(association);
       setModalVisible(true);
   };

   const handleNext = () => {
         setCurrentAssociation(associations[associations.indexOf(currentAssociation) + 1]);
    };

    const handlePrevious = () => {
        setCurrentAssociation(associations[associations.indexOf(currentAssociation) - 1]);
    };


   // Close modal for candidate details
   const handleCloseModal = () => {
       setModalVisible(false);
       setCurrentAssociation(null);
   };

   // Open resume view
   const handleOpenResume = () => {
       setResumeVisible(true);
   };

   // Close resume view
   const handleCloseResume = () => {
       setResumeVisible(false);
   };

   // Handle action menu click for more options
   const handleActionMenuClick = (event) => {
       setAnchorEl(event.currentTarget);
   };

   // Close action menu
   const handleActionMenuClose = () => {
       setAnchorEl(null);
   };

   // Count active filters for display
   const activeFiltersCount = () => {
       return selectedClient.length + selectedRole.length;
   };

   return (
       <Container maxWidth="lg"> 
           Pipeline
           <input type="text" placeholder="Search" onChange={(e) => {  const newValue = e.target.value;
            if (newValue.trim() === '') {
                setLimit(100);
                setAssociations([]);                
                resetPagination();
                setText("");
                fetchAssociations();
                console.log("newValue is empty"+text);
            } else {
                setLimit(1000);
                setText(newValue); resetPagination(); console.log(newValue);fetchAssociations();
            }
            
            }}/>

            { selectedClient && selectedClient.length > 0 && ( <Chip label={selectedClient.join(', ')} onDelete={() => { setSelectedClient([]); resetPagination(); }} /> ) }
            { selectedRole && selectedRole.length > 0 && ( <Chip label={selectedRole.join(', ')} onDelete={() => { setSelectedRole([]); resetPagination(); }} /> ) }
            { selectedStage && ( <Chip label={selectedStage} onDelete={() => { setSelectedStage(''); resetPagination(); }} /> ) }
            { selectedStatus && ( <Chip label={selectedStatus} onDelete={() => { setSelectedStatus(''); resetPagination(); }} /> ) }
           <Box sx={{ display: 'flex', float: 'right', gap: 2 }}>
               <Button variant="outlined" startIcon={<FilterListIcon />} onClick={() =>setFiltersVisible(true)}>
                   {activeFiltersCount() === 0 ? '' : `(${activeFiltersCount()})`}
               </Button>
                <Button variant="outlined" startIcon={<ViewColumnIcon />} onClick={() =>setColumnsVisible(true)}>                </Button>
                <Button variant="outlined" startIcon={<PriorityHigh />} onClick={syncAssoc}>Sync</Button>

               
           </Box>
        <Drawer anchor="right" open={filtersVisible} onClose={() => setFiltersVisible(false)}>
            <Box sx={{ width: 300, p: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6">Filters</Typography>
                    <IconButton onClick={() => setFiltersVisible(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <FormControl fullWidth sx={{ my: 2 }}>
                    <Typography variant="subtitle1">Client</Typography>
                    <Autocomplete
                        multiple
                        options={clients}
                        getOptionLabel={(option) => option.name}
                        value={selectedClient.map(clientName => clients.find(client => client.name === clientName))}
                        onChange={(event, newValue) => {
                            resetPagination();
                            setSelectedClient(newValue.map(client => client.name));                            
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Select Client" />}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                                const tagProps = getTagProps({ index });
                                return <Chip key={option.name} label={option.name} {...tagProps} />;
                            })
                        }
                    />
                </FormControl>
                <FormControl fullWidth sx={{ my: 2 }} visible={selectedClient.length}>
                    <Typography variant="subtitle1">Role</Typography>
                    <Autocomplete
                        multiple
                        options={clients.filter(client => selectedClient.includes(client.name)).flatMap(client => client.roles || [])}
                        getOptionLabel={(option) => option.name}
                        value={selectedRole.map(roleName => clients.flatMap(client => client.roles || []).find(role => role.name === roleName))}
                        onChange={(event, newValue) => {
                            setSelectedRole(newValue.map(role => role.name));
                            resetPagination();
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Select Role" />}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip key={option.name} label={option.name} {...getTagProps({ index })} />
                            ))
                        }
                    />
                </FormControl>
                
                <Typography variant="subtitle1" sx={{ my: 2 }}>Stage</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {Object.keys(stages).map((stage) => (
                        <Box key={stage}>
                            <Button variant={selectedStage === stage ? 'contained' : 'outlined'} onClick={() =>
                                handleStageFilter(stage)} fullWidth >
                                {`${stage} (${stageCounts[stage] || 0})`}
                            </Button>
                            {selectedStage === stage && stages[stage] && (
                                <Box sx={{ ml: 2 }}>
                                    {stages[stage].map((status) => (
                                        statusCounts[status] && (
                                            <Button key={status} variant="text" onClick={() =>
                                                handleStatusFilter(status)} sx={{ display: 'block', textAlign: 'left' }}>
                                                {status} ({statusCounts[status] || 0})
                                            </Button>
                                        )
                                    ))}
                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
                <Typography>Candidate</Typography>
                <FormControl fullWidth sx={{ my: 2 }}>
                    <Typography variant="subtitle1">Experience (Years)</Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label="Min"
                            type="number"
                            onChange={(e) => {
                                resetPagination();
                                setSelectedExperienceMin(e.target.value);
                            }}
                        />
                        <TextField
                            label="Max"
                            type="number"
                            onChange={(e) => {
                                resetPagination();
                                setSelectedExperienceMax(e.target.value);
                            }}
                        />
                    </Box>
                </FormControl>

                <FormControl fullWidth sx={{ my: 2 }}>
                    <Typography variant="subtitle1">Compensation</Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label="Min"
                            type="number"
                            value={selectedCompensationMin}
                            onChange={(e) => {
                                resetPagination();
                                setSelectedCompensationMin(e.target.value);
                            }}
                        />
                        <TextField
                            label="Max"
                            type="number"
                            value={selectedCompensationMax}
                            onChange={(e) => {
                                resetPagination();
                                setSelectedCompensationMax(e.target.value);
                            }}
                        />
                    </Box>
                </FormControl>

                <FormControl fullWidth sx={{ my: 2 }}>
                    {includeFilters===false &&(
                <Button onClick={()=>{
                    setincludeFilters(true); 
                    fetchAssociations1();
                }}> Fetch </Button>)}
                    <Typography variant="subtitle1">Current Company</Typography>
                    <Autocomplete
                        options={companies}
                        getOptionLabel={(option) => option.name}
                        value={selectedCompany}
                        onChange={(event, newValue) => {
                            resetPagination();
                            setSelectedCompany(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Select Company" />}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ my: 2 }}>
                    
                
                    <Typography variant="subtitle1">Current Location</Typography>
                    <Autocomplete
                        options={locations}
                        getOptionLabel={(option) => option.name}
                        value={selectedLocation}
                        onChange={(event, newValue) => {                            
                            resetPagination();
                            setSelectedLocation(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Select Location" />}
                    />
                </FormControl>
                <Button variant="contained" color="secondary" onClick={clearFilters} sx={{ mt: 2 }}> Clear Filters </Button>
            </Box>
        </Drawer>
           <Drawer anchor="right" open={columnsVisible} onClose={() =>setColumnsVisible(false)}>
               <Box sx={{ width: 300,p: 3 }}>
                   <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                       <Typography variant="h6">Columns</Typography>
                       <IconButton onClick={() =>setColumnsVisible(false)}>
                           <CloseIcon />
                       </IconButton>
                   </Box>
                   <FormControl fullWidth>
                       {fields.map((field) => (
                        <FormControlLabel key={field} control={
                            <Checkbox checked={visibleFields.includes(field)} onChange={(e) => {
                                const newVisibleFields = e.target.checked
                                    ? [...visibleFields, field]
                                    : visibleFields.filter(f => f !== field);
                                handleVisibleFieldsChange({ target: { value: newVisibleFields } });
                            }} />
                        } label={field} />
                       ))}
                   </FormControl>
               </Box>
           </Drawer>

           {/* Associations Table */}
           <TableContainer component={Paper}>
               <Table>
                   <TableHead>
                       <TableRow>
                           <TableCell>Avatar</TableCell>
                           {Array.isArray(visibleFields) && fields.map((field) => (
                               visibleFields.includes(field) && <TableCell key={field}>{field}</TableCell>
                           ))}                           
                       </TableRow>
                   </TableHead>

                   {/* Table Body */}
                <TableBody>{associations.map((association,index) =>
                    (<TableRow key={`${association._id}-${index}` } onClick={(e) =>  {e.stopPropagation();handleOpenModal(association);}}>
                                                <TableCell><Avatar src={association.dp || ''} /></TableCell>                          
                                                {visibleFields.includes('Name') &&  (<TableCell>{association.name || ''}</TableCell>)}
                                                {visibleFields.includes('Email') && (<TableCell >{association.email || ''}</TableCell>)}
                                                {visibleFields.includes('Phone') && (<TableCell >{association.phone || ''}</TableCell>)}
                                                {visibleFields.includes('Status') &&  (<TableCell >{association.status || ''}</TableCell>)}
                                                {visibleFields.includes('Current Company') && (<TableCell >{association.currentCompany || ''}</TableCell>)}
                                                {visibleFields.includes('Current Role') && (<TableCell >{association.currentRole || ''}</TableCell>)}
                                                {visibleFields.includes('Compensation') && (<TableCell >{association.compensation || ''}</TableCell>)}
                                                {visibleFields.includes('Gender') && (<TableCell >{association.gender || ''}</TableCell>)}
                                                {visibleFields.includes('Marital Status')  && (<TableCell >{association.maritalStatus || ''}</TableCell>)}
                                                {visibleFields.includes('Expectation')  && (<TableCell >{association.expectation || ''}</TableCell>)}
                                                {visibleFields.includes('Experience Years')  && (<TableCell > {Math.floor(association.experience_yrs)}-{Math.round((association.experience_yrs % 1) * 12)} </TableCell>)}
                                                {visibleFields.includes('Notice Period')  && (<TableCell >{association.noticePeriod || ''}</TableCell>)}
                                                {visibleFields.includes('LinkedIn')  && (<TableCell >{association.linkedIn || ''}</TableCell>)}
                                                {visibleFields.includes('Resume')  && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenResume(); }}>View Resume</TableCell>)}
                                                {visibleFields.includes('Priority') && (<TableCell >{association.priority|| ' '}</TableCell>)}
                                                {visibleFields.includes('Category')  && (<TableCell >{association.category || ''}</TableCell>)}
                                                {visibleFields.includes('Skills')  && (<TableCell >{association.skills || ''}</TableCell>)}
                                                {visibleFields.includes('Source')  && (<TableCell >{association.source || ''}</TableCell>)}
                                                {visibleFields.includes('Added On') && (<TableCell >{new Date(association.updatedAt).toLocaleString('en-IN', { day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit', hour12: true })}</TableCell>)}

                     </TableRow>)
                 )}</TableBody>  
                </Table>  
            </TableContainer>  
            <div id="scroll-anchor" ref={observerRef} style={{ height: '1px' }} />

            {/* Candidate Modal */}
            
            {currentAssociation && (<CandidateModal visible={modalVisible} onClose={handleCloseModal} candidate={currentAssociation} changeOptionsMap={changeOptionsMap} handleChangeStatus={handleChangeStatus} editCdd={toggleEditCdd} handlePriorityChange={handlePriorityChange} handleNext={handleNext} handlePrevious={handlePrevious} ></CandidateModal>)}
                 
    <>
        {editCdd && (
            <Modal open={editCdd} onClose={() => setEditCdd(false)}>
                 <Box sx={{  width: '80%', margin: 'auto', mt: 5, p: 4, backgroundColor: 'background.paper', borderRadius: 2, maxHeight: '90vh', overflowY: 'auto'  }}>
                    <IconButton onClick={() => setEditCdd(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                        <CloseIcon />
                    </IconButton>
                <CandidateForm user={currentAssociation} />
                </Box>
            </Modal>
        )}
    </>
        </Container>  
     );  
};  

export default Associations;