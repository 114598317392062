import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, Paper, Typography, IconButton, MenuItem, Select, FormControl, InputLabel, CircularProgress, Snackbar, Autocomplete, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { LoadingOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useForm, Controller } from 'react-hook-form';
import { Alert } from '@mui/material';
import { Input, notification, Form } from 'antd';
import { useParams } from 'react-router-dom';

const { TextArea } = Input;

const EditCandidate = () => {
  const { control, handleSubmit, setValue, watch } = useForm();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [degrees, setDegrees] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [filteredDesignations, setFilteredDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [skills, setSkills] = useState([]);
  const [candidate, setCandidate] = useState({});
  const [previewImage, setPreviewImage] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);


  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          axios.get('https://api.hireveda.com/degrees'),
          axios.get('https://api.hireveda.com/institutions'),
          axios.get('https://api.hireveda.com/organizations'),
          axios.get('https://api.hireveda.com/designations'),
          axios.get('https://api.hireveda.com/departments'),
          axios.get('https://api.hireveda.com/skills'),
          axios.get(`https://api.hireveda.com/candidates/${id}`)
        ]);
        setDegrees(responses[0].data.filter(item => item && item.name));
        setInstitutions(responses[1].data.filter(item => item && item.name));
        setOrganizations(responses[2].data.filter(item => item && item.name));
        setFilteredOrganizations(responses[2].data.filter(item => item && item.name));
        setDesignations(responses[3].data.filter(item => item && item.name));
        setFilteredDesignations(responses[3].data.filter(item => item && item.name));
        setDepartments(responses[4].data.filter(item => item && item.name));
        setFilteredDepartments(responses[4].data.filter(item => item && item.name));
        setSkills(responses[5].data.filter(item => item && item.name));
        setCandidate(responses[6].data);
        setFormData(responses[6].data);
      } catch (error) {
            console.log('Failed to fetch data:', error);
            notification.error({
          message: 'Error Loading Data',
          description: 'There was a problem loading initial data.', });
      }
    };
      
    fetchOptions();
  }, [id]);

  const setFormData = (data) => {
    const formattedData = {
      ...data,
      education: data.education.map(edu => ({
        ...edu,
        startDate: edu.startDate ? new Date(edu.startDate) : null,
        endDate: edu.endDate ? new Date(edu.endDate) : null
      })),
      experience: data.experience.map(exp => ({
        ...exp,
        startDate: exp.startDate ? new Date(exp.startDate) : null,
        endDate: exp.endDate ? new Date(exp.endDate) : null
      }))
    };
    form.setFieldsValue(formattedData);
  };

  const onFinish = async (values) => {
    const updatedValues = {
      ...values,
      education: values.education.map(edu => ({
        ...edu,
        startDate: edu.startDate ? edu.startDate.toISOString() : null,
        endDate: edu.endDate ? edu.endDate.toISOString() : null
      })),
      experience: values.experience.map(exp => ({
        ...exp,
        startDate: exp.startDate ? exp.startDate.toISOString() : null,
        endDate: exp.endDate ? exp.endDate.toISOString() : null
      }))
    };
    try {
      await axios.put(`https://api.hireveda.com/candidates/${id}`, updatedValues);
      
      Alert.success({
        message: 'Update Successful',
        description: 'The candidate details have been successfully updated.',
      });
    } catch (error) {
      console.error('Failed to update candidate:', error);
      notification.error({
        message: 'Update Failed',
        description: 'There was an error updating the candidate details.',
      });
    }
  };

  const handleUploadChange = info => {
    let newList = [...info.fileList].slice(-1);
    newList = newList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newList);
  };

  const beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({
        message: 'File Type Error',
        description: 'You can only upload JPG/PNG file!'
      });
    }
    return isJpgOrPng;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleSearch = (value, setFiltered, allItems) => {
    if (!value) {
      setFiltered(allItems); // Reset on empty input
      return;
    }
    const filtered = allItems.filter(item => item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFiltered(filtered);
  }
  
  return (
    <div>
    <form onSubmit={handleSubmit(onFinish)}>
        <div className="form-container" style={{ padding: '24px', maxWidth: '960px', margin: '0 auto' }}>
          <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <TextField
              name="name"
              label="Name"
              fullWidth
              required
              {...control.register('name')}
              margin="normal"
              {...form.register('name')}
            />
            <TextField
              name="email"
              label="Email"
              fullWidth
              required
              variant="outlined"
              {...control.register('email')}
              type="email"
              {...form.register('email')}
            />
            <TextField
              name="phone"
              label="Phone"
              fullWidth
              required
              {...control.register('phone')}
              margin="normal"
              {...form.register('phone')}
            />
            <TextField
              name="headline"
              label="Headline"
              fullWidth
              {...control.register('headline')}
              margin="normal"
              {...form.register('headline')}
            />
            <TextField
              name="linkedin"
              label="LinkedIn"
              fullWidth
              {...control.register('linkedin')}
              margin="normal"
              {...form.register('linkedin')}
            />
            <Autocomplete
              options={filteredOrganizations.map(org => org.name)}
              onInputChange={(event, value) => handleSearch(value, setFilteredOrganizations, organizations)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Current Company"
                  variant="outlined"
                  {...control.register('currentCompany')}
                  fullWidth
                  {...form.register('currentCompany')}
                />
              )}
            />
            <Autocomplete
              options={filteredDesignations.map(design => design.name)}
              onInputChange={(event, value) => handleSearch(value, setFilteredDesignations, designations)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Current Role"
                  variant="outlined"
                  {...control.register('currentRole')}
                  fullWidth
                  {...form.register('currentRole')}
                />
              )}
            />
            <TextField
              name="currentLocation"
              label="Current Location"
              fullWidth
              {...control.register('currentLocation')}
              margin="normal"
              {...form.register('currentLocation')}
            />
            <TextField
              name="compensation"
              label="Compensation"
              fullWidth
              variant="outlined"
              {...control.register('compensation')}
              type="number"
              {...form.register('compensation')}
            />
            <TextField
              name="expectation"
              label="Expectation"
              fullWidth
              {...control.register('expectation')}
              margin="normal"
              {...form.register('expectation')}
            />
            <TextField
              name="remarks"
              label="Remarks"
              fullWidth
              variant="outlined"
              margin="normal"
              {...control.register('remarks')}
              rows={4}
              {...form.register('remarks')}
            />
            <TextField
              name="experience_yrs"
              label="Experience Years"
              fullWidth
              variant="outlined"
              {...control.register('experience_yrs')}
              type="number"
              {...form.register('experience_yrs')}
            />
            <TextField
              name="noticePeriod"
              label="Notice Period (days)"
              fullWidth
              variant="outlined"
              {...control.register('noticePeriod')}
              type="number"
              {...form.register('noticePeriod')}
            />
            <TextField
              name="resume_text"
              label="Resume Text"
              fullWidth
              variant="outlined"
              margin="normal"
              {...control.register('resume_text')}
              rows={4}
              {...form.register('resume_text')}
            />
            <TextField
              name="Category"
              label="Category"
              fullWidth
              {...control.register('Category')}
              margin="normal"
              {...form.register('Category')}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Experience</InputLabel>
              <Select
                value={watch('experience') || []}
                onChange={(event) => setValue('experience', event.target.value)}
                renderValue={(selected) => selected.join(', ')}
              >
                {filteredOrganizations.map((org) => (
                  <MenuItem key={org.name} value={org.name}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Education</InputLabel>
              <Select
                value={watch('education') || []}
                onChange={(event) => setValue('education', event.target.value)}                
                renderValue={(selected) => selected.join(', ')}
              >
                {degrees.map((degree) => (
                  <MenuItem key={degree.name} value={degree.name}>
                    {degree.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Additional Details</InputLabel>
              <Select
                value={watch('addition') || []}
                onChange={(event) => setValue('addition', event.target.value)}
                renderValue={(selected) => selected.join(', ')}
              >
                {filteredOrganizations.map((org) => (
                  <MenuItem key={org.name} value={org.name}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Display Picture</InputLabel>
              <input
                type="file"
                onChange={(event) => handleUploadChange(event.target.files)}
                accept="image/jpeg,image/png"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary">
          Save Changes
        </Button>
      </div>
    </div>
  </form>
</div>
  );
};

export default EditCandidate;
