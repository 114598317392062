import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';

const ComposeEmail = () => {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [file, setFile] = useState(null);

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSend = () => {
    console.log({ subject, body, file });
    // API call to send email campaign
  };

  return (
    <Box p={3}>
      <Typography variant="h5">Compose Email</Typography>
      <TextField
        label="Subject"
        fullWidth
        margin="normal"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <TextField
        label="Body"
        fullWidth
        margin="normal"
        multiline
        rows={6}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
      <Button variant="outlined" component="label">
        Upload CSV
        <input type="file" hidden onChange={handleFileUpload} />
      </Button>
      {file && <Typography>{file.name}</Typography>}
      <Button variant="contained" color="primary" onClick={handleSend} sx={{ mt: 2 }}>
        Send Campaign
      </Button>
    </Box>
  );
};

export default ComposeEmail;
