import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
  Grid,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Autocomplete,
  InputAdornment,
  TextareaAutosize,
  Select,
  MenuItem,
} from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

import TextArea from 'antd/es/input/TextArea';

const CandidateForm = ({user}) => {

    const [degrees, setDegrees] = useState([]);
    const [skills, setSkills] = useState([]);
    const [candidate, setCandidate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
    const [tabIndex, setTabIndex] = useState(0);
    const [institutions, setInstitutions] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [location, setLocation] = useState([]);
    const [uploadedFileName, setUploadedFileName] = useState('');
  
    
    const handleFileUpload = async (event) => {
      const file = event.target.files[0];
      if (file) {
        setUploadedFileName(file.name);
        setCandidate({ ...candidate, resume: file });
  
        // Upload the file to the backend immediately
        const formData = new FormData();
        formData.append('resume', file);
        try {
          const response = await axios.post('/api/upload/resume', formData);
          console.log('Resume uploaded successfully', response.data);
          const filePath = response.data.filePath; // Adjust according to your API response structure        
          setCandidate({ ...candidate, resume: filePath });
        } catch (error) {
          console.error('Error uploading resume:', error);
        }
      }
    };
  
    const fetchInitialData = async () => {
      try {
        const responses = await Promise.all([
          axios.get('/api/degrees'),
          axios.get('/api/skills'),
          axios.get(`/api/candidates/email/${user.email}`), // Fetch using user email        
          axios.get('/api/institutions'),
          axios.get('/api/organizations'),
          axios.get('/api/designations'),
          axios.get('/api/departments'),
          axios.get('/api/locations'),
        ]);
  
        const cleanData = (data) => [...new Set(data.map(item => item.name))];
  
        setDegrees(cleanData(responses[0].data));
        setSkills(cleanData(responses[1].data));
        setInstitutions(cleanData(responses[3].data));
        setOrganizations(cleanData(responses[4].data));
        setDesignations(cleanData(responses[5].data));
        setDepartments(cleanData(responses[6].data));      
        setCandidate(responses[2].data[0] || {});
        setLocation(cleanData(responses[7].data));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setAlert({
          open: true,
          message: 'Error loading data. Please try again.',
          severity: 'error',
        });
        setLoading(false);
      }
    };
  
    useEffect(() => {
      console.log("ID " + user);
      if (user) fetchInitialData();

    }, [user]);
  
    const handleSave = async () => {
      try {
        await axios.put(`/api/candidates/${candidate._id}`, candidate);
        setAlert({
          open: true,
          message: 'Profile updated successfully!',
          severity: 'success',
        });
      } catch (error) {
        setAlert({
          open: true,
          message: 'Error updating profile.',
          severity: 'error',
        });
      }
    };
  
    const handleFieldChange = (field, value) => {
      setCandidate({ ...candidate, [field]: value });
    };
  
    if (loading)
      return (
        <Box display="flex" justifyContent="center" marginTop={4}>
          <CircularProgress />
        </Box>
      );
  
    return (
      <Box padding={4} maxWidth="960px" margin="auto">
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={() => setAlert({ ...alert, open: false })}
        >
          <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
  
        <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)} centered>
          <Tab label="Personal Info" />
          <Tab label="Education" />
          <Tab label="Experience" />
          <Tab label="Resume & Salary" />
        </Tabs>
  
        {tabIndex === 0 && (
          <Box mt={3}>
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              value={candidate?.name || ''}
              onChange={(e) => handleFieldChange('name', e.target.value)}
            />
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              value={candidate?.email || ''}
              disabled
            />
            <TextField
              label="Phone"
              fullWidth
              margin="normal"
              value={candidate?.phone || ''}
              onChange={(e) => handleFieldChange('phone', e.target.value)}
            />
            <TextField
              label="LinkedIn URL"
              fullWidth
              margin="normal"
              value={candidate?.linkedin || ''}
              onChange={(e) => handleFieldChange('linkedin', e.target.value)}
            />
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                      label="Current Company"
                      fullWidth
                      margin="normal"
                      value={candidate?.currentCompany || ''}
                      onChange={(e) => handleFieldChange('currentCompany', e.target.value)}
                    />
                    <TextField
                      label="Current Role"
                      fullWidth
                      margin="normal"
                      value={candidate?.currentRole || ''}
                      onChange={(e) => handleFieldChange('currentRole', e.target.value)}
                    />
                    <TextField
                      label="Current Location"
                      fullWidth
                      margin="normal"
                      value={candidate?.currentLocation || ''}
                      onChange={(e) => handleFieldChange('currentLocation', e.target.value)}
                    />
  
                    
                  <Box display="flex" alignItems="spaced-out">
                    <Select
                      value={candidate?.gender || ''}
                      onChange={(e) => handleFieldChange('gender', e.target.value)}
                      sx={{ minWidth: 120 }}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                    <Select
                      value={candidate?.marital_status || ''}
                      onChange={(e) => handleFieldChange('marital_status', e.target.value)}
                      sx={{ minWidth: 120 }}
                      label="Marital Status"
                    >
                      <MenuItem value="single">Single</MenuItem>
                      <MenuItem value="married">Married</MenuItem>
                      <MenuItem value="divorced">Divorced</MenuItem>
                      <MenuItem value="widowed">Widowed</MenuItem>
                    </Select>
                    <TextField
                      label="Date of Birth"
                      type="date"                  
                      value={candidate?.DOB ? new Date(candidate.DOB).toISOString().split('T')[0] : ''}
                      onChange={(e) => handleFieldChange('DOB', e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
  
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
  
        {tabIndex === 1 && (
          <Box mt={3}>
            {candidate?.education?.map((edu, index) => (
              <Box key={`${edu.degree}-${index}`} mb={3}>
                <Autocomplete
                  options={degrees}
                  value={edu.degree || ''}
                  getOptionLabel={(option) => option || ''}
                  onChange={(e, newValue) =>
                    handleFieldChange('education', [
                      ...candidate.education.slice(0, index),
                      { ...edu, degree: newValue },
                      ...candidate.education.slice(index + 1),
                    ])
                  }
                  renderInput={(params) => <TextField {...params} label="Degree" fullWidth />}
                  freeSolo
                />
                <Autocomplete
                  options={institutions}
                  value={edu.institution || ''}
                  getOptionLabel={(option) => option || ''}
                  onChange={(e, newValue) =>
                    handleFieldChange('education', [
                      ...candidate.education.slice(0, index),
                      { ...edu, institution: newValue },
                      ...candidate.education.slice(index + 1),
                    ])
                  }
                  renderInput={(params) => <TextField {...params} label="Institution" fullWidth />}
                  freeSolo
                />
                <Box display="flex">
                  <TextField
                    label="Start Date"
                    type="date"
                    fullWidth
                    value={edu.startDate ? new Date(edu.startDate).toISOString().split('T')[0] : ''}
                    onChange={(e) =>
                      handleFieldChange('education', [
                        ...candidate.education.slice(0, index),
                        { ...edu, startDate: e.target.value },
                        ...candidate.education.slice(index + 1),
                      ])
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    fullWidth
                    value={edu.endDate ? new Date(edu.endDate).toISOString().split('T')[0] : ''}
                    onChange={(e) =>
                      handleFieldChange('education', [
                        ...candidate.education.slice(0, index),
                        { ...edu, endDate: e.target.value },
                        ...candidate.education.slice(index + 1),
                      ])
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                  />
                </Box>
                <TextArea
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Description"
                  value={edu.description || ''}
                  onChange={(e) =>
                    handleFieldChange('education', [
                      ...candidate.education.slice(0, index),
                      { ...edu, description: e.target.value },
                      ...candidate.education.slice(index + 1),
                    ])
                  }
                  style={{ width: '100%', marginTop: 10 }}
                />
                <IconButton
                  color="error"
                  onClick={() =>
                    handleFieldChange('education', [
                      ...candidate.education.slice(0, index),
                      ...candidate.education.slice(index + 1),
                    ])
                  }
                >
                  <RemoveCircleOutline />
                </IconButton>
              </Box>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddCircleOutline />}
              onClick={() =>
                handleFieldChange('education', [...(candidate.education || []), {}])
              }
            >
              Add Education
            </Button>
          </Box>
        )}
  
        {tabIndex === 2 && (
          <Box mt={3}>
            {candidate?.experience?.map((exp, index) => (
              <Box key={`${exp.organization}-${index}`} mb={3}>
                <Autocomplete
                  options={organizations}
                  value={exp.organization || ''}
                  getOptionLabel={(option) => option || ''}
                  onChange={(e, newValue) =>
                    handleFieldChange('experience', [
                      ...candidate.experience.slice(0, index),
                      { ...exp, organization: newValue },
                      ...candidate.experience.slice(index + 1),
                    ])
                  }
                  renderInput={(params) => <TextField {...params} label="Organization" fullWidth />}
                  freeSolo
                />
                <Autocomplete
                  options={designations}
                  value={exp.designation || ''}
                  getOptionLabel={(option) => option || ''}
                  onChange={(e, newValue) =>
                    handleFieldChange('experience', [
                      ...candidate.experience.slice(0, index),
                      { ...exp, designation: newValue },
                      ...candidate.experience.slice(index + 1),
                    ])
                  }
                  renderInput={(params) => <TextField {...params} label="Designation" fullWidth />}
                  freeSolo
                />
                <Autocomplete
                  options={location}
                  value={exp.location || ''}
                  getOptionLabel={(option) => option || ''}
                  onChange={(e, newValue) =>
                    handleFieldChange('experience', [
                      ...candidate.experience.slice(0, index),
                      { ...exp, location: newValue },
                      ...candidate.experience.slice(index + 1),
                    ])
                  }
                  renderInput={(params) => <TextField {...params} label="Location" fullWidth />}
                  freeSolo
                />
                <Box display="flex" >
                <TextField
                  label="Start Date"
                  type="date"
                  value={exp.startDate ? new Date(exp.startDate).toISOString().split('T')[0] : ''}
                  onChange={(e) =>
                    handleFieldChange('experience', [
                      ...candidate.experience.slice(0, index),
                      { ...exp, startDate: e.target.value },
                      ...candidate.experience.slice(index + 1),
                    ])
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
                <TextField
                  label="End Date"
                  type="date"
                  value={exp.endDate ? new Date(exp.endDate).toISOString().split('T')[0] : ''}
                  onChange={(e) =>
                    handleFieldChange('experience', [
                      ...candidate.experience.slice(0, index),
                      { ...exp, endDate: e.target.value },
                      ...candidate.experience.slice(index + 1),
                    ])
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
                </Box>
                <TextArea
                  aria-label="Description"
                  minRows={3} 
                  placeholder="Description"
                  value={exp.description || ''}
                  onChange={(e) =>
                    handleFieldChange('experience', [
                      ...candidate.experience.slice(0, index),
                      { ...exp, description: e.target.value },
                      ...candidate.experience.slice(index + 1), 
                    ])
                  }
                  style={{ width: '100%', marginTop: 10 }}
                />
                <IconButton
                  color="error"
                  onClick={() =>
                    handleFieldChange('experience', [
                      ...candidate.experience.slice(0, index),
                      ...candidate.experience.slice(index + 1),
                    ])
                  }
                >
                  <RemoveCircleOutline />
                </IconButton>
              </Box>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddCircleOutline />}
              onClick={() =>
                handleFieldChange('experience', [...(candidate.experience || []), {}])
              }
            >
              Add Experience
            </Button>
          </Box>
        )}
  
        {tabIndex === 3 && (
          <Box mt={3}>
            <TextField
              label="Resume URL"
              fullWidth
              margin="normal"
              value={candidate?.resume || ''}
              onChange={(e) => handleFieldChange('resume', e.target.value)}
            />
            <Box display="flex" alignItems="center" mt={2}>
              <Button
                variant="contained"
                component="label"
              >
                Upload Resume
                <input
                  type="file"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
              <Button
                variant="outlined"
                href={candidate?.resume}
                target="_blank"
                sx={{ ml: 2 }}
              >
                Download Resume
              </Button>
            </Box>
            <TextField
              label="Compensation"
              fullWidth
              margin="normal"
              value={candidate?.compensation || ''}
              onChange={(e) => handleFieldChange('compensation', e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">INR</InputAdornment>,
                endAdornment: <InputAdornment position="end">Lacs per annum</InputAdornment>,
              }}
            />
            <TextField
              label="Expected Salary"
              fullWidth
              margin="normal"
              value={candidate?.expectation || ''}
              onChange={(e) => handleFieldChange('expectation', e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">INR</InputAdornment>,
                endAdornment: <InputAdornment position="end">Lacs per annum</InputAdornment>,
              }}
            />
            <TextField
              select
              fullWidth
              label="Notice Period (Days)"
              name="noticePeriod"
              value={candidate?.noticePeriod || ''}
              onChange={(e) => handleFieldChange('noticePeriod', e.target.value)}
              margin="normal"
              SelectProps={{
                native: true,
              }}
            >
              <option>Select</option>
              <option value={0}>0 Days</option>
              <option value={15}>15 Days</option>
              <option value={30}>30 Days</option>
              <option value={60}>60 Days</option>
              <option value={90}>90 Days</option>
              <option value={99}>90+ Days</option>
            </TextField>
          </Box>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSave}
          sx={{ mt: 3 }}
        >
          Save Changes
        </Button>
      </Box>
    );
  };
  
  export default CandidateForm;