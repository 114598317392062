import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Container, Typography, Box, Button, Grid, Chip, Paper } from '@mui/material';
import { LocationOn, Work, Business, Money, People } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const JobDetailPage = () => {
  const { roleName } = useParams();
  const [job, setJob] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch job details based on the roleName
    axios.get(`/api/jobs/${roleName}`)
      .then(response => {
        setJob(response.data);
      })
      .catch(error => console.error(error));
  }, [roleName]);

  if (!job) {
    return <p>Loading job details...</p>;
  }

  return (
    <Container maxWidth="md">
      <Helmet>
        <title>{`${job.RoleName} | Your Company`}</title>
        <meta name="description" content={`Apply for the ${job.RoleName} role at Your Company`} />
        <meta property="og:title" content={`${job.RoleName} Role`} />
        <meta property="og:description" content={`Apply for the ${job.RoleName} role at Your Company`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/path/to/image.jpg" />
      </Helmet>

      <Paper elevation={3} sx={{ padding: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          {job.RoleName}
        </Typography>

        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <LocationOn /> {job.Location}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <Business /> {job.Client}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <Work /> {job.RoleType}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <Money /> Budget: ${job.Budget}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <People /> Openings: {job.Openings}
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom>
          Job Description
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {job.JD}
        </Typography>

        <Typography variant="h6" gutterBottom>
          Key Skills
        </Typography>
        <Box sx={{ mb: 3 }}>
          {job.Skills && job.Skills.map(skill => (
            <Chip key={skill} label={skill} sx={{ mr: 1, mb: 1 }} />
          ))}
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          sx={{ mt: 3 }}
          onClick={() => navigate(`/apply/${roleName}`)}
        >Apply Now</Button>
      </Paper>
    </Container>
  );
};

export default JobDetailPage;
