import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Typography, Box, Button } from '@mui/material';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { login, oauthLogin} = useAuth();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await login(values.email, values.password);
      setLoading(false);
      const returnTo = localStorage.getItem('returnTo') || '/';
      localStorage.removeItem('returnTo');
      navigate(returnTo);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleCredentialResponse = async (response) => {    
      try {
        await oauthLogin(response.credential);
    //    navigate('/');
      } catch (error) {
        console.error('OAuth login failed:', error);
      }
    };

    window.google.accounts.id.initialize({
      client_id: '14388555053-la7s7phrsfaoemqnrusr4njrlijhd3ke.apps.googleusercontent.com',
      callback: handleCredentialResponse,
    });

    window.google.accounts.id.renderButton(
      document.getElementById('google-signin-button'),
      { theme: 'filled_blue', size: 'large', width: '320px' } 
    );

  //  window.google.accounts.id.prompt();
  }, [navigate, oauthLogin]);
//*/
  return (
    <Box 
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        bgcolor: '#f5f7fa', // Soft background color
        textAlign: 'center',
      }}
    >
      {/* Logo */}
      <img 
        src="https://hireveda.com/content/images/size/w1000/2023/05/HireVeda-6.png" 
        alt="HireVeda Logo" 
        style={{ width: '250px', marginBottom: '20px' }} 
      />

      {/* Headline */}
      <div>
      <Typography variant="h4" fontWeight="bold" color="primary" gutterBottom>
        Take Your First Step
      </Typography>
      <Typography variant="h6" color="textSecondary" sx={{ maxWidth: '600px' }}>
        Transform your career with HireVeda. Connect with top recruiters and job opportunities.
      </Typography>
      </div>
      <div>
      {/* Google Sign-In Button */}
      <Box id="google-signin-button" sx={{ mt: 3 }}></Box>

      {/* Subtle Footer */}
      <Typography variant="caption" color="gray" sx={{ mt: 4 }}>
        Securely powered by Google Sign-In
      </Typography>
      </div>
      <div>
      {/* Footer */}
      <Typography variant="caption" color="gray" sx={{ mt: 4 }}> <a href="https://hireveda.com/Privacy-Policy/">Privacy Policy</a> | <a href="https://hireveda.com/tnc/">Terms of Service</a> </Typography>
      <br/><Typography variant="caption" color="gray" sx={{ mt: 4 }}>© 2025 HireVeda. All rights reserved.</Typography>
      </div>
    </Box>
  );
};

export default Login;
