import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Card, Button, TextField, Typography, FormControl, CardContent, CardHeader, Autocomplete, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import OrgForm from '../utils/OrgForm';
import CompanyLogo from '../CompanyLogo';
import { useAuth } from '../AuthContext';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SelfAssessment from './SelfAssessment';
import Signup from './signup';
import Reachouts from './Reachouts';
import SetupGmail from '../utils/setupgmail';
import { Modal } from 'antd';

const Execedge = () => {
    const { user } = useAuth();
    const [targetCompanies, setTargetCompanies] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [newCompany, setNewCompany] = useState({ name: '', organization: '', status: '' });
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [OrgEdit, setOrgEdit] = useState('');
    const [Project, setProject] = useState([]);
    const [decisionMakers, setDecisionMakers] = useState([]);
    const [AllProject, setAllProject] = useState([]);
    const [AddNew, setAddNew] = useState(0);
    const [newClient, setNewClient] = useState({ email: '', client: '' });
    const [openSetupGmail, setOpenSetupGmail] =  useState(0);

    const [showComments, setShowComments] = useState('');
    const [newComment, setNewComment] = useState({});
    
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [Cloading, setCLoading] = useState(false);

  // Fetch organizations from the server based on input
  useEffect(() => {
    const fetchOrganizations = async () => {
      if (inputValue.trim() === '') {
        setOptions([]);
        return;
      }

      setCLoading(true);
      try {
        const response = await axios.get('/api/organizations', {
          params: { query: inputValue },
        });
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      } finally {
        setCLoading(false);
      }
    };

    // Debounce the API call to avoid excessive requests
    const debounceTimer = setTimeout(() => {
      fetchOrganizations();
    }, 200);

    return () => clearTimeout(debounceTimer);
  }, [inputValue]);

    const fetchAuthorizedEmails = async () => {
        try {
          const response = await axios.get(`/api/emails/authorized?email=${user.email}`);
          return(response.data);
        } catch (error) {
          console.error('Error fetching authorized emails:', error);
        }
      };

    useEffect(() => {
            setEmail(user.email);    
            if(user.role === "admin"){
                getAllProjects();
            }  
    }, [user]);      

    useEffect(() => {
        const checkAuthorizedEmails = async () => {
            const authorizedEmails = await fetchAuthorizedEmails();
            console.log(Project.email);
            if (
                ((!authorizedEmails || authorizedEmails.length === 0 )&& Project.email) 

               // && activeTab === 'Reachouts'
        ) {
                console.log("Show setup Gmail");
                setOpenSetupGmail(true);
            }
        };

        checkAuthorizedEmails();
    }, [Project.email]);

    const handleAddClient = async () => {
        try {
            await axios.post('/api/execedge/newClient', newClient);
            setNewClient({ email: '', client: '' });
            getAllProjects();
        } catch (error) {
            console.error('Error adding new client:', error);
        }
    };

    const getAllProjects = async () => {
        try {
            const response = await axios.get('/api/execedge/getAll');
            setAllProject(response.data || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching target companies:', error);
        }
    };

    
    const fetchTargetCompanies = useCallback(async () => {
        try {
            if (!email) return;
            const response = await axios.get(`/api/execedge/get?email=${email}`);
            setProject(response.data || []);
            setTargetCompanies(response.data.TargetCompany || []);
            setLoading(false);
        } catch (error) {   
            console.error('Error fetching target companies:', error);
        }
    }, [email]);

    useEffect(() => {
        fetchTargetCompanies();
    }, [fetchTargetCompanies]);


    const fetchDecisionMakers = useCallback(async () => {
        try {
            if (!Project) return;
            if (!Project._id) return;
            const id=Project._id;
            const response = await axios.get(`/api/execedge/Aassociations/${id}`);
            setDecisionMakers(response.data || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching target companies:', error);
        }
    }, [Project]);


    useEffect(() => {
        fetchDecisionMakers();
    }, [fetchDecisionMakers]);

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const response = await axios.get('/api/organizations');
                setOrganizations(response.data);
            } catch (error) {
                console.error('Error fetching organizations:', error);
            }
        };

        fetchOrganizations();
    }, []);

    const handleStatusChange = async (companyName, newStatus, Priority="") => {
        try {
            await axios.post('/api/execedge/updateTargetCompanyStatusByEmail', {
                email,
                Name: companyName,
                Status: newStatus,
                Priority: Priority
            });
            setTargetCompanies(prevCompanies =>
                prevCompanies.map(company =>
                    company.Name === companyName ? { ...company, Status: newStatus } : company
                )
            );
        } catch (error) {
            console.error('Error updating company status:', error);
        }
    };

   
        const [openModal, setOpenModal] = useState(false);
        const [selectedCompany, setSelectedCompany] = useState(null);
        const [newStatus, setNewStatus] = useState('');
        const [reason, setReason] = useState('');
        const [CandidateId, setCandidateId] = useState('');

        const handleOpenModal = (company, status, candidateId="") => {
            setSelectedCompany(company);
            setCandidateId(candidateId);
            setNewStatus(status);
            setOpenModal(true);
        };

        const handleCloseModal = () => {
            setOpenModal(false);
            setSelectedCompany(null);
            setCandidateId('');
            setNewStatus('');
            setReason('');
        };

        const StatusChangeWithComment = async (company, status, Priority="", candidateId="") => {
            handleOpenModal(company, status, candidateId);
        }
        const handleSubmit = async () => {
            try {
                if(CandidateId !== ""){
                    await axios.post('/api/execedge/updateConnectionStatusByEmail', {
                        ExecEdgeId: Project._id,
                        candidateId: CandidateId,
                        Status: newStatus
                    });
                    setDecisionMakers(prevDecisionMakers =>
                        prevDecisionMakers.map(decisionMaker =>
                            decisionMaker.candidateId._id === CandidateId ? { ...decisionMaker, status: newStatus } : decisionMaker
                        )
                    );
                    await handleAddCommentdecisionMaker(CandidateId);
                    
                }
            else
                    {
                await axios.post('/api/execedge/updateTargetCompanyStatusByEmail', {
                    email,
                    Name: selectedCompany.Name,
                    Status: newStatus,
                });
                setTargetCompanies(prevCompanies =>
                    prevCompanies.map(company =>
                        company.Name === selectedCompany.Name ? { ...company, Status: newStatus } : company
                    )
                );
                await handleAddComment(selectedCompany._id);
            }
                handleCloseModal();
            } catch (error) {
                console.error('Error updating company status:', error);
            }
        };


    const handleConnectionStatusChange = async (candidateId, newStatus) => {
        try {
            await axios.post('/api/execedge/updateConnectionStatusByEmail', {
                ExecEdgeId: Project._id,
                candidateId,
                Status: newStatus
            });
            setDecisionMakers(prevDecisionMakers =>
                prevDecisionMakers.map(decisionMaker =>
                    decisionMaker.candidateId._id === candidateId ? { ...decisionMaker, status: newStatus } : decisionMaker
                )
            );
        } catch (error) {
            console.error('Error updating connection status:', error);
        }
    };

    const handleAddCommentdecisionMaker = async (decisionMakerId) => {
        try {
            await axios.post('/api/execedge/addAssoComment', {
                ExecEdgeId: Project._id,
                decisionMakerId,
                comment: newComment
            });
            setNewComment({});
            fetchDecisionMakers();
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    }

    const handleAddCompany = async () => {
        try {
            await axios.post('/api/execedge/addTargetCompanyByEmail', {
                email,
                Name: newCompany.name,
                Organization: newCompany.organization,
                Status: 'Pending Approval'
            });
            setNewCompany({ name: '', organization: '', status: '' });
            fetchTargetCompanies();
        } catch (error) {
            console.error('Error adding new company:', error);
        }
    };
    const [activeTab, setActiveTab] = useState('Target Companies');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };


    const handleAddComment = async (companyId) => {
        try {
            await axios.post('/api/execedge/addComment', {
                email,
                companyId,
                comment: newComment
            });
            setNewComment({});
            fetchTargetCompanies();
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    }


    return (
        <>
           
           <Container maxWidth="lg" sx={{ py: 4 }}>
    { (user.role === "admin") && (
                    <Card elevation={3}>
                        <CardContent>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <Autocomplete
                                    options={AllProject.map(project => project.email)}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => <TextField {...params} label="User Email" />}
                                    onChange={(e, newValue) => setEmail(newValue)}
                                />                                
                            <Button onClick={() => setAddNew(true)}> New Client</Button>
                            </FormControl>
                        </CardContent>
                        <CardContent  style={{ display: AddNew ? 'block' : 'none' }}>
                            <Typography variant="h6" gutterBottom>
                                Add New Client
                            </Typography>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <TextField
                                    label="Email"
                                    value={newClient.email || ''}
                                    onChange={(e) => setNewClient({ ...newClient, email: e.target.value })}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    label="Client"
                                    value={newClient.Client || ''}
                                    onChange={(e) => setNewClient({ ...newClient, Client: e.target.value })}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                            </FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddClient}
                                sx={{ mt: 2 }}
                            >
                                Add Client
                            </Button>
                        </CardContent>
                    </Card>

                )  //*/  
                }
    { (Project.email) && ( <Reachouts decisionMakers={decisionMakers} Project={Project} fetchDecisionMakers={fetchDecisionMakers}/> 
        
     /*       
            <>
                
                <div style={{ 
    display: 'flex', 
    gap: '2rem', 
    flexWrap: 'wrap', 
    justifyContent: 'center', 
    marginTop: '20px', 
    fontFamily: 'Arial, sans-serif', 
    fontSize: '1rem', 
    textAlign: 'center' 
}}>
    {['Self Assessment', 'Target Companies', 'Outreach'].map((tab, index) => (
        <a
            key={index}
            style={{
                textDecoration: 'none',
                color: activeTab === tab ? '#FF5733' : '#333', // Highlight color for active tab
                fontWeight: activeTab === tab ? 'bold' : 'normal',
                padding: '0.5rem 1rem',
                borderRadius: '20px',
                transition: 'all 0.3s ease',
                cursor: 'pointer',
                ':hover': {
                    color: '#FF5733', // Hover color
                    backgroundColor: '#f0f0f0', // Light background on hover
                }
            }}
            onClick={() => handleTabChange(tab)}
        >
            {tab}
        </a>
    ))}
</div>
                    
                {
                activeTab === 'Self Assessment' && (<SelfAssessment email={email} SelfAssessment={Project.SelfAssessment} />)
                }
                {
                (activeTab === 'Target Companies' || activeTab === 'Prospecting') && (
                    <>
                    { 
                    activeTab === 'Target Companies' && (
                    <Card sx={{ mt: 4, p: 3 }}>
                        <Typography variant="h6" gutterBottom>Add New Company</Typography>
                        <FormControl fullWidth sx={{ mt: 2 }}>                            
                            <Autocomplete
        options={options}
        getOptionLabel={(option) => option.name || option}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add a Company"
            placeholder="Start typing to search..."
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {Cloading ? 'Loading...' : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        onInputChange={(event, newValue) => {
          setInputValue(newValue);
        }}
        onChange={(event, newValue) => {
          // Handle selection here
          console.log('Selected Company:', newValue);
        }}
        freeSolo
        loading={Cloading}
      />
                        </FormControl>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleAddCompany}
                            sx={{ mt: 2 }}
                        >
                            Add Company
                        </Button>
                    </Card>
                    )}
                <CardContent>
                    {loading ? (
                        <Typography variant="h6" sx={{ textAlign: 'center', py: 4 }}>Loading...</Typography>
                    ) : (targetCompanies && targetCompanies.length > 0 ? (
                        <div style={{ display: 'grid', gap: '20px' }}>
                            {
                            targetCompanies.map(company => company && (
                                (1 // activeTab === 'Prospecting' ? 
                                   // ['Pending Approval', 'Disapproved', 'Hold for Later'].includes(company.Status) : 
                                   //  !['Pending Approval', 'Disapproved', 'Hold for Later'].includes(company.Status) 
                                ) && 
                                <Card key={company._id} variant="outlined" sx={{ p: 2 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '16px' }}>
                                        <CompanyLogo companyName={company?.Name} sx={{ width: '60px', height: '60px' }} />
                                        <div>
                                            <Typography variant="h6" component="h2">{company?.Name}</Typography>
                                            <Typography color="text.secondary">{company?.Industry || ''}</Typography>
                                            <Typography>{company?.Status}</Typography>
                                            <Typography 
                                                component="a" 
                                                href={company?.Website} 
                                                target="_blank" 
                                                sx={{ textDecoration: 'none', color: 'primary.main' }}
                                            >
                                                {company?.Website}
                                            </Typography>
                                        </div>
                                        {user.role === "admin" && (
                                        <Button 
                                            onClick={() => setOrgEdit(company?.Organization)}
                                            sx={{ marginLeft: 'auto' }}
                                            startIcon={<EditIcon />}
                                        >
                                            Edit
                                        </Button>)}
                                    </div>
                                    <Typography sx={{ mb: 2 }}>{company?.Description}</Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                        {company?.status}
                                    </Typography>



                                    <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                        {(company.Status === 'Pending Approval' || company.Status === 'Disapproved' || company.Status ===`Hold for Later`) && (
                                            <>
                                            <Button                                            
                                            variant='outlined'
                                            size="small"
                                            onClick={() => {
                                                document.getElementById(`div-${company._id}`).style.display = 'block';
                                                document.getElementById(`button-${company._id}`).style.display = 'none';
                                                document.getElementById(`buttonD-${company._id}`).style.display = 'none';
                                            }} 
                                            id={`button-${company._id}`}
                                            > 
                                            Approved 
                                            </Button>
                                            <div id={`div-${company._id}`} style={{ display: 'none' }}>
                                            <Button 
                                            variant='outlined'
                                            size="small"
                                            onClick={() => handleStatusChange(company.Name, 'Sent for Reserch',1)} //Disapproved	Hold for Later
                                            sx={{
                                                borderRadius: '20px',
                                                textTransform: 'none',
                                                minWidth: '120px'
                                            }}
                                        >
                                        Priorty 1 
                                        </Button><Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => handleStatusChange(company.Name, 'Sent for Reserch',2)} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                            Priorty 2 
                                            </Button><Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => handleStatusChange(company.Name, 'Sent for Reserch',3)} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Priorty 3
                                            </Button>
                                            </div>
                                            </>)
                                        }
                                        {
                                            (company.Status === `Pending Approval`) &&
                                            (
                                                <>
                                                <Button                                            
                                            variant='outlined'
                                            size="small"
                                            onClick={() => {
                                                document.getElementById(`divD-${company._id}`).style.display = 'block';
                                                document.getElementById(`button-${company._id}`).style.display = 'none';
                                                document.getElementById(`buttonD-${company._id}`).style.display = 'none';
                                            }} 
                                            id={`buttonD-${company._id}`}
                                            > 
                                            Reject 
                                            </Button>
                                            <div id={`divD-${company._id}`} style={{ display: 'none' }}>
                                            <Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => StatusChangeWithComment(company, 'Disapproved')} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Disapproved
                                            </Button>
                                            <Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => StatusChangeWithComment(company, 'Hold for Later')} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Hold for Later
                                            </Button>     
                                            </div>                                            
                                            </>
                                            )
                                        }
                                        {
                                        (company.Status === `Hold for Later` ) &&
                                            (
                                                <>
                                                
                                            <Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => StatusChangeWithComment(company, 'Disapproved')} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Disapproved
                                            </Button>
                                            </>
                                        )}
                                        {
                                        (company.Status === `Disapproved` ) &&
                                            (
                                                <>
                                            <Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => StatusChangeWithComment(company, 'Hold for Later')} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Hold for Later
                                            </Button>
                                            </>
                                        )}
                                        {company.Status === 'Reserch Compleated' && (
                                            <Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => handleStatusChange(company.Name, 'Reachout Initiated')}
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Reachout Initiated
                                            </Button>
                                        )}
                                        

                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => setShowComments(company._id)}
                                            sx={{
                                                borderRadius: '20px',
                                                textTransform: 'none',
                                                minWidth: '120px',
                                                marginLeft: 'auto'
                                            }}
                                            startIcon={<EditIcon />}
                                        >
                                           {company.comments?.length || '' }  Comments
                                        </Button>
                                    </div>

                                    <div style={{ marginTop: '16px' }}>                                        
                                        {showComments === company._id && (
                                            <div style={{ marginTop: '16px' }}>
                                                {company.comments.map((comment, index) => (
                                                    <Card key={index} variant="outlined" sx={{ p: 2, mb: 2 }}>
                                                        <Typography variant="body2" color="text.secondary">
                                                            <b>{comment.userName}</b> * {new Date(comment.timestamp).toLocaleString()}
                                                        </Typography>
                                                        <Typography variant="body1">{comment.comment}</Typography>
                                                    </Card>
                                                ))}
                                                <div style={{ display: 'flex', gap: '8px', flexDirection: 'row' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Add a comment"
                                                    multiline
                                                    rows={2}
                                                    value={newComment?.comment || ''}
                                                    onChange={(e) => 
                                                    setNewComment({
                                                        comment: e.target.value,
                                                        user: user._id,
                                                        userName: user.name,
                                                        timestamp: new Date()
                                                    })}
                                                    sx={{ mt: 2 }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleAddComment(company._id)}
                                                    sx={{ mt: 2 }}
                                                >
                                                    Add Comment
                                                </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Card>
                            ))}
                        </div>
                    ) : (
                        <Typography variant="h6" sx={{ textAlign: 'center', py: 4 }}>No companies found</Typography>
                    ))}

                    <div style={{
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        width: '400px',
                        height: '100%',
                        backgroundColor: '#fff',
                        boxShadow: '-3px 0 20px rgba(0,0,0,0.1)',
                        padding: '30px',
                        overflowY: 'auto',
                        display: OrgEdit ? 'block' : 'none'
                    }}>
                        <OrgForm orgId={OrgEdit} />
                    </div>
                </CardContent>
                </>
                )}
                {activeTab === 'Outreach' && (
                    <Reachouts decisionMakers={decisionMakers} Project={Project} fetchDecisionMakers={fetchDecisionMakers}/> 
                )}

            </>    
    ) //*/)
    }
    { (!Project.email) && ( <Signup />)}   
        <Modal open={openSetupGmail} footer={null} onCancel={() => setOpenSetupGmail(false)}>
            <SetupGmail />
        </Modal>
                <Dialog open={openModal} onClose={handleCloseModal}>
                    <DialogTitle>Change Status to {newStatus}</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Reason for Change"
                            value={reason}
                            onChange={(e) => {
                                setReason(e.target.value);
                                setNewComment({
                                    comment: newStatus + " : " + e.target.value,
                                    user: user._id,
                                    userName: user.name,
                                    timestamp: new Date()
                                });
                            }}                          
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal}>Cancel</Button>
                        <Button onClick={handleSubmit} color="primary">Submit</Button>
                    </DialogActions>
                </Dialog>
        </Container>
        
        </>
    );
    
};

export default Execedge;