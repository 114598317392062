import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PivotTable = () => {
  const [pivotData, setPivotData] = useState([]);
  const [users, setUsers] = useState([]);
  const [dates, setDates] = useState([]);
  const [sources, setSources] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/pivot-data');
        const data = response.data;

        // Transform data for pivot table
        const userSet = new Set();
        const dateSet = new Set();
        const sourceSet = new Set();
        const dataMap = {};

        data.forEach((entry) => {
          const user = entry._id.user;
          const source = entry._id.Source;
          const date = entry._id.date;
          const count = entry.count;

          userSet.add(user);
          dateSet.add(date);
          sourceSet.add(source);

          if (!dataMap[source]) {
            dataMap[source] = {};
          }
          if (!dataMap[source][user]) {
            dataMap[source][user] = {};
          }
          dataMap[source][user][date] = count;
        });

        setUsers([...userSet]);
        setDates([...dateSet].sort());
        setSources([...sourceSet]);
        setPivotData(dataMap);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <table border="1">
        <thead>
          <tr>
            <th>Source</th>
            <th>User</th>
            {dates.map((date) => (
              <th key={date}>{date}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sources.map((source) => {
            let sourceTotal = {};
            return (
              <>
                {users.map((user) => {
                  const hasNonZero = dates.some(date => pivotData[source]?.[user]?.[date] > 0);
                  if (!hasNonZero) return null;
                  return (
                    <tr key={`${source}-${user}`}>
                      <td>{source}</td>
                      <td>{user}</td>
                      {dates.map((date) => {
                        const count = pivotData[source]?.[user]?.[date] || 0;
                        sourceTotal[date] = (sourceTotal[date] || 0) + count;
                        return (
                          <td key={date}>
                            <a href={`/candidates?CreatedBy=${user}&CreatedAt=${date}`}>{count}</a>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr key={`${source}-subtotal`} style={{ fontWeight: 'bold' }}>
                  <td>{source}</td>
                  <td>Subtotal</td>
                  {dates.map((date) => (
                    <td key={date}>{sourceTotal[date] || 0}</td>
                  ))}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PivotTable;
