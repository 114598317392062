import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const Theme = (darkMode) =>
  responsiveFontSizes(
    createTheme({
      palette: {
        mode: darkMode ? 'dark' : 'light',
        primary: {
          main: '#004080', // Refined Navy Blue
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: '#76D7C4', // Soft teal
          contrastText: '#004080',
        },
        background: {
          default: darkMode ? '#181A1B' : '#F4F6F8',
          paper: darkMode ? '#242526' : '#FFFFFF',
        },
        text: {
          primary: darkMode ? '#E0E0E0' : '#2B2D42',
          secondary: darkMode ? '#B0BFC6' : '#4A5568',
        },
        divider: darkMode ? '#3C4043' : '#D1D5DB',
      },
      typography: {
        fontFamily: '"Roboto Slab", "Roboto", "Open Sans", sans-serif',
        h1: { fontSize: '2.5rem', fontWeight: 700 },
        h2: { fontSize: '2rem', fontWeight: 600 },
        h3: { fontSize: '1.75rem', fontWeight: 500 },
        body1: { fontSize: '1rem', lineHeight: 1.6 },
        button: { fontWeight: 600, textTransform: 'none' },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 10,
              padding: '8px 16px',
              textTransform: 'none',
              boxShadow: 'none',
              [createTheme().breakpoints.down('sm')]: {
                fontSize: '0.85rem',
                padding: '6px 12px',
              },
              ':hover': {
                backgroundColor: darkMode ? '#0056B3' : '#003366',
              },
            },
            containedPrimary: {
              backgroundColor: darkMode ? '#004080' : '#0056B3',
            },
            outlinedSecondary: {
              borderColor: '#76D7C4',
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              padding: '20px',
              borderRadius: 12,
              boxShadow: darkMode
                ? '0px 4px 12px rgba(0, 0, 0, 0.3)'
                : '0px 4px 12px rgba(0, 64, 128, 0.1)',
              [createTheme().breakpoints.down('sm')]: {
                padding: '16px',
              },
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            h1: {
              color: darkMode ? '#EAEAEA' : '#004080',
              [createTheme().breakpoints.down('sm')]: {
                fontSize: '2rem',
              },
            },
            h2: {
              [createTheme().breakpoints.down('sm')]: {
                fontSize: '1.8rem',
              },
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 12,
              boxShadow: darkMode
                ? '0px 4px 12px rgba(0, 0, 0, 0.3)'
                : '0px 4px 12px rgba(0, 64, 128, 0.1)',
            },
          },
        },
        MuiTable: {
          styleOverrides: {
            root: {
              borderCollapse: 'separate',
              borderSpacing: '0 8px',
              backgroundColor: darkMode ? '#242526' : '#FFFFFF',
            },
          },
        },
      },
    })
  );

export default Theme;
