import PersonIcon from "@mui/icons-material/Person";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import BarChartIcon from "@mui/icons-material/BarChart";
import EmailIcon from "@mui/icons-material/Email";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import PeopleIcon from "@mui/icons-material/People";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssignmentIcon from "@mui/icons-material/Assignment";
import WorkspacePremium from "@mui/icons-material/WorkspacePremium";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Logout from "@mui/icons-material/Logout";
import { iconButtonClasses } from "@mui/material";
import { Edit } from "@mui/icons-material";

const routeConfig = [  
  {
    path: "/Pipeline",
    component: "Associations",
    roles: ["admin", "recruiter"],
    key: "Pipeline",
    menu: {
      name: "Pipeline",
      path: "/Pipeline",
      key: "candidate-pipeline",
      icon: BarChartIcon,
    },
  },
  {
    path: "/jobs",
    component: "JobPage",
    key:"Jobs",
    roles: [ "admin", "recruiter","candidate"],
    menu: {
      name: "Jobs",
      path: "/jobs",
      key: "jobs",
      icon: WorkOutlineIcon,
    },
  },
  {
    path: "/execedge",
    component: "Execedge",
    roles: ["admin", "recruiter", "candidate"],
    key:"ExecEdge",
    menu: {
      name: "ExecEdge",
      path: "/execedge",
      key: "execedge",
      icon: WorkspacePremium,
    },
  },
  {
    path: "/Candidates",
    component: "ClientDashboard",
    roles: ["recruiter", "admin"],
    key:"Candidates",
    menu: {
      name: "Search",
      path: "/Candidates",
      key: "Candidates",
      icon: AssignmentIcon,
    },
  },/*
  {
    path: "/edit-profile",
    component: "EditProfile",
    roles: ["admin", "candidate", "client", "recruiter"],
    menu: null,
  },*/
  {
    path: "/recover-password",
    component: "RecoverPassword",
    key:"RecoverPassword",
    roles: ["guest"],
    menu: null,
  },
  {
    path: "/login",
    component: "Login",
    key:"Login",
    roles: ["guest"],
    menu: null,
  },
  {
    path: "/signup",
    component: "Signup",
    key:"Signup",
    roles: ["guest"],
    menu: null,
  },
  {
    path: "/oauth-callback",
    component: "OAuthCallback",
    key:"OAuthCallback",
    roles: ["guest", "admin", "candidate", "client", "recruiter"],
    menu: null,
  },
  {
    path: "/",
    component: "Home",
    key:"Home",
    roles: ["admin", "candidate", "client", "recruiter"],
    menu: null,
  },
  {
    path: "/apply",
    component: "apply",
    key:"Apply",
    roles: ["guest", "admin", "candidate", "client", "recruiter"],
    menu: null,
  },
  {
    path: "/edit/:id",
    component: "EditCandidates",
    key:"EditCandidates",
    roles: ["candidate", "admin"],
    menu: null,
  },
  {
    path: "/admin",
    component: "",
    key:"admin",
    roles: ["admin"],
    menu: {
      name: "Admin",
      path: "/",
      key: "admin",
      icon: AdminPanelSettingsIcon,
    },
    children: [
      {
        path: "/dashboard",
        component: "AdminDashboard",
        key: "admin-dashboard",
        roles: ["admin"],
        menu: {
          name: "Dashboard",
          path: "/admin/dashboard",
          key: "admin-dashboard",
          icon: DashboardIcon,
        },
      },
      {
        path: "/upload",
        component: "FileUploadComponent",
        roles: ["admin"],
        key: "admin-upload",
        menu: {
          name: "Import",
          path: "/admin/upload",
          key: "admin-upload",
          icon: ImportExportIcon,
        },
      },
      {
        path: "/users",
        component: "AdminUserManagement",
        roles: ["admin"],
        key: "admin-users",
        menu: {
          name: "Users",
          path: "/admin/users",
          key: "admin-users",
          icon: PeopleIcon,
        },
      },
      {
        path: "/merge",
        component: "AdminMergeComponent",
        roles: ["admin"],
        key: "admin-database",
        menu: {
          name: "Database",
          path: "/admin/merge",
          key: "admin-database",
          icon: MergeTypeIcon,
        },
      },
      {
        path: "/linkedin",
        component: "linkedintab",
        roles: ["admin"],
        key: "admin-linkedin",
        menu: {
          name: "LinkedIn",
          path: "/admin/linkedin",
          key: "admin-linkedin",
          icon: LinkedInIcon,
        },
      },
      {
        path: "/BulkMerge",
        component: "BulkUpdateComponent",
        roles: ["admin"],
        menu: null,
      },
      {
        path: "/Roles",
        component: "RoleManagement",
        roles: ["admin"],
        key: "admin-roles",
        menu: {
          name: "Roles",
          path: "/admin/Roles",
          key: "admin-roles",
          icon: AccountTreeIcon,
        },
      },
      {
        path: "/Admin/Email",
        component: "EmailReaderDashboard",
        roles: ["admin"],
        key: "admin-email",
        menu: {
          name: "Email Reader",
          path: "/admin/Email",
          key: "admin-email",
          icon: EmailIcon,
        },
      },
      {
        path: "/Statuses",
        component: "AdminStatusManager",
        roles: ["admin"],
        key: "admin-statuses",
        menu: {
          name: "Statuses",
          path: "/admin/Statuses",
          key: "admin-statuses",
          icon: AssignmentIcon,
        },
      },
      {
        path: "/email",
        component: "EmailCampaignDashboard",
        key:"EmailCampaignDashboard",
        roles: ["admin"],
        menu: {
          name: "Email Campaign",
          path: "/email",
          key: "email-campaign",
          icon: EmailIcon,
        },
      },
    ],
  },
  {
    path: "/candidate",
    component: "CandidateDashboard",
    key: "candidate",
    roles: ["candidate", "admin"],
    menu: {
      name: "Me",
      path: "/candidate",
      key: "candidate",
      icon: PersonIcon,
    },
    children : [
      {
        path: "/profile",
        key: "candidate-profile",
        component:"ClientDashboard",        
        roles: ["candidate", "admin"],
        icon: PersonIcon,
        menu:{
          name: "Profile",
          key:"Edit-Profile",
          icon: Edit
        }
      }
    ]
  },
];

export default routeConfig;
