import React, { useState, useEffect } from 'react';
import axios from 'axios';

const getRandomColor = (char) => {
    const colors = [
      '#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FF8C33', 
      '#FF3333', '#33FFFC', '#A833FF', '#FFDA33', '#33FFA2'
    ];
    const index = char.charCodeAt(0) % colors.length;
    return colors[index];
};

const CompanyLogo = ({ companyName }) => {
    const [logoUrl, setLogoUrl] = useState('');
    const [bgColor, setBgColor] = useState('');

    useEffect(() => {
        // Fetch logo from the server
        const fetchLogo = async () => {
            try {
                const response = await axios.get(`/api/company/logo`, { params: { name: companyName } });
                if (response.data && response.data.logo) {
                    setLogoUrl(response.data.logo);
                } else {
                    throw new Error('Logo not found in response');
                }
            } catch (error) {
                //console.error('Failed to fetch company logo:', error);
                // Set a random background color if the logo fetch fails or is not found
                setBgColor(getRandomColor(companyName ? companyName[0] : ' '));
            }
        };

        if (companyName) {
            fetchLogo();
        } else {
            setBgColor(getRandomColor(' ')); // Set default color if companyName is undefined or empty
        }
    }, [companyName]);

    return (
        <div style={{ float: "left" }}>
            {logoUrl ? (
                <img src={logoUrl} alt={`${companyName} Logo`} style={{ width: 40, height: 40 ,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '25%',
                    margin: '3px 10px',
                }} />
            ) : (
                <div style={{
                    width: 40,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: bgColor || '#ccc', // Default background color if bgColor is not set
                    color: 'white',
                    fontSize: '30px',
                    borderRadius: '25%',
                    margin: '3px 10px',
                    textAlign: 'center'
                }}>
                    {companyName ? companyName.charAt(0).toUpperCase() : '?'} 
                </div>
            )}
        </div>
    );
};

export default CompanyLogo;
