import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Grid, Typography, Paper, Container, Box, Chip, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { LocationOn, Business, Work, Money, People, Share, Send } from '@mui/icons-material';
import axios from 'axios';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
const JobPage = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    location: '',
    roleType: '',
    industry: ''
  });

  const navigate = useNavigate();

  useEffect(() => {
    axios.get('api/jobs')
      .then(response => {
        setJobs(response.data);
        setFilteredJobs(response.data);
      })
      .catch(error => console.error(error));
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  useEffect(() => {
    const filtered = jobs.filter(job =>
      (!filters.location || job.Location?.includes(filters.location)) &&
      (!filters.roleType || job.RoleType?.includes(filters.roleType)) &&
      (!filters.industry || job.TargetIndustry?.includes(filters.industry))
    );
    setFilteredJobs(filtered);
  }, [filters, jobs]);

  const openModal = (job) => {
    setSelectedJob(job);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedJob(null);
  };

  const handleShare = (job) => {
    const shareUrl = `${window.location.origin}/apply/${job.url}`;
    navigator.clipboard.writeText(shareUrl);
    alert('Job link copied to clipboard!');
  };

  return (
    <div>
      <Helmet>
        <title>Job Listings | Your Company</title>
        <meta name="description" content="Explore job opportunities at Your Company" />
        <meta property="og:title" content="Job Listings" />
        <meta property="og:description" content="Explore job opportunities at Your Company" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/path/to/image.jpg" />
      </Helmet>

      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
          <FormControl variant="outlined" sx={{ minWidth: 150 }}>
            <InputLabel>Location</InputLabel>
            <Select
              value={filters.location}
              onChange={handleFilterChange}
              label="Location"
              name="location"
            >
              <MenuItem value=""><em>All</em></MenuItem>
              {Array.from(new Set(jobs.map(job => job.Location))).map((location, index) => (
                <MenuItem key={index} value={location}>{location}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" sx={{ minWidth: 150 }}>
            <InputLabel>Role Type</InputLabel>
            <Select
              value={filters.roleType}
              onChange={handleFilterChange}
              label="Role Type"
              name="roleType"
            >
              <MenuItem value=""><em>All</em></MenuItem>
              {Array.from(new Set(jobs.map(job => job.RoleType))).map((roleType, index) => (
                <MenuItem key={index} value={roleType}>{roleType}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" sx={{ minWidth: 150 }}>
            <InputLabel>Industry</InputLabel>
            <Select
              value={filters.industry}
              onChange={handleFilterChange}
              label="Industry"
              name="industry"
            >
              <MenuItem value=""><em>All</em></MenuItem>
              {Array.from(new Set(jobs.map(job => job.TargetIndustry))).map((industry, index) => (
                <MenuItem key={index} value={industry}>{industry}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Grid container spacing={4} mt={2}>
          {filteredJobs.length > 0 ? (
            filteredJobs.map(job => (
              <Grid item xs={12} sm={6} md={4} key={job._id}>
                <Card elevation={4} sx={{ padding: 3, cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
                  <Box>
                    <Typography variant="h6" gutterBottom>{job.RoleName}</Typography>
                    <Typography variant="body2" color="textSecondary">{job.Client_proxy}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}><LocationOn /> {job.Location}</Typography>
                    <Typography variant="body2" color="textSecondary"><Work /> {job.RoleType}</Typography>
                    <Typography variant="body2" color="textSecondary"><People /> Openings: {job.Openings}</Typography>
                    <Typography variant="body2" color="textSecondary"> {job.Details?.length > 100 ? `${job.Details.substring(0, 100)}...` : job.Details}</Typography>
                  </Box>
                  <Box mt={2} display="flex" justifyContent="space-between">
                    
                    <Button variant="contained" color="primary" startIcon={<Send />}  onClick={() => navigate(`/apply/${job.url}`)}>Apply</Button>
                    <Button variant="outlined" color="primary" startIcon={<Share />} onClick={() => handleShare(job)}>Share</Button>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="h6" color="textSecondary" align="center" sx={{ mt: 4 }}>No jobs available</Typography>
          )}
        </Grid>
      </Container>

      <Modal open={isModalOpen} onClose={closeModal}>
        <Paper sx={{ padding: 4, maxWidth: 600, margin: 'auto', mt: 5 }}>
          {selectedJob && (
            <>
              <Typography variant="h5" gutterBottom>{selectedJob.RoleName}</Typography>
              <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>{selectedJob.JD}</Typography>
              <Box mb={2}>
                <Typography variant="h6" gutterBottom>Key Skills:</Typography>
                {selectedJob.Skills && selectedJob.Skills.map(skill => (
                  <Chip key={skill} label={skill} sx={{ mr: 1, mb: 1 }} />
                ))}
              </Box>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}><Money /> Budget: ${selectedJob.Budget}</Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}><People /> Openings: {selectedJob.Openings}</Typography>
              <Button variant="contained" color="primary" fullWidth sx={{ mt: 3 }} onClick={() => navigate(`/apply/${selectedJob.url}`)}>Apply Now</Button>
            </>
          )}
        </Paper>
      </Modal>
    </div>
  );
};

export default JobPage;
