import React, { useRef, useState, useEffect, useCallback } from 'react'; 
import axios from 'axios'; 
import CandidateModal from './CandidateModal'; 
import { Container, MenuItem, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, FormControl, Checkbox, ListItemText, OutlinedInput, Avatar, IconButton, Drawer, Modal, Menu, FormControlLabel
    , Autocomplete, Chip, TextField
 } from '@mui/material'; 
import LinkedInIcon from '@mui/icons-material/LinkedIn'; 
import FilterListIcon from '@mui/icons-material/FilterList'; 
import CloseIcon from '@mui/icons-material/Close'; 
import MoreVertIcon from '@mui/icons-material/MoreVert'; 
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import CandidateForm from '../utils/candidateForm';
import { PriorityHigh } from '@mui/icons-material';

const ITEM_HEIGHT = 48; 
const ITEM_PADDING_TOP = 8; 
const MenuProps = { PaperProps: { style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 } } }; 

const fields = [ 'Name', 'Email', 'Phone', 'Current Company', 'Current Role', 'Compensation', 'Gender', 'Marital Status', 'Expectation', 'Experience Years', 'Notice Period', 'LinkedIn', 'Resume', 'Rating', 'Category', 'Skills', 'Education', 'Experience', 'Status' ]; 

const Associations = () => { 
    const [selectedStatus, setSelectedStatus] = useState(''); 
    const [stages, setStages] = useState([]); 
    const [changeOptionsMap, setChangeOptionsMap] = useState({}); 
    const [selectedStage, setSelectedStage] = useState(''); 
    const [visibleFields, setVisibleFields] = useState(['Email', 'Phone', 'Status']); 
    const [associations, setAssociations] = useState([]); 
    const [page, setPage] = useState(0); 
    const [loading, setLoading] = useState(false); 
    const [hasMore, setHasMore] = useState(true); 
    const [clients, setClients] = useState([]); 
    const [roles, setRoles] = useState([]); 
    const [selectedClient, setSelectedClient] = useState([]); 
    const [selectedRole, setSelectedRole] = useState([]); 
    const [stageCounts, setStageCounts] = useState({}); 
    const [filtersVisible, setFiltersVisible] = useState(false); 
    const [statusCounts, setStatusCounts] = useState({}); 
    const [columnsVisible, setColumnsVisible] = useState(false); 
    const [modalVisible, setModalVisible] = useState(false); 
    const [currentAssociation, setCurrentAssociation] = useState(null); 
    const [resumeVisible, setResumeVisible] = useState(false); 
    const [editCdd, setEditCdd] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null); 
    const [text, setText] = useState('');
    const [limit, setLimit] = useState(10);
   
    const observerRef = useRef();

    // Fetch associations with pagination
    const fetchAssociations = useCallback(async () => { 
        //if (loading) return; 
        
        setLoading(true); 
        
        try { 
            const response = await axios.get('/api/associations/get', { params: { stage: selectedStage, client: selectedClient.join(','), role: selectedRole.join(','), status: selectedStatus, page, text: text, limit: limit } }); 

            if (Array.isArray(response.data.data)) { 
                setAssociations((prev) => [...prev, ...response.data.data]); 
                setHasMore(response.data.data.length > 0); 
            } else { 
                console.error('Unexpected response data format:', response.data); 
            } 


        } catch (err) { console.error('Error fetching associations:', err); } 
        
        finally { setLoading(false); } 

}, [selectedStage, selectedClient, selectedRole, selectedStatus, page, text]);

    // Initial data fetch
    useEffect(() => { 
        fetchExistingData();
        fetchClients(); 
    }, []); 

    // Debounce fetch on filters change
    useEffect(() => { 
        const debounceFetch = setTimeout(() => { fetchAssociations(); }, 300); 
        
        return () => clearTimeout(debounceFetch);
        
    }, [selectedStage, selectedClient.join(','), selectedRole.join(','), page]); 

    // Fetch existing data for stages and options 
    useEffect(() => {
        fetchStausCounts();
    }, [selectedClient, selectedRole, stages]);

    useEffect(() => {
        
        const stageCount = Object.keys(stages).reduce((accumulatedCounts, stage) => {
            const statusList = stages[stage];
            const count = statusList.reduce((sum, status) => sum + (statusCounts[status] || 0), 0);
            accumulatedCounts[stage] = count;
            return accumulatedCounts;
        },{});
        console.log(selectedClient);
        console.log(stageCount);
        setStageCounts(stageCount); 
    }, [statusCounts]);

    const fetchStausCounts = async () => {
        try {
            const response = await axios.get('/api/associations/get-status-count',{ params: { client: selectedClient.join(','), role: selectedRole.join(',') } });     
            
            const statusCount = response.data.reduce((accumulatedCounts, item) => {
                accumulatedCounts[item._id || 'Uncategorized'] = item.count;
                return accumulatedCounts;
            }, {});
            setStatusCounts(statusCount);

        } catch (err) {
            console.error('Error fetching existing data:', err);
        }
    };

    const fetchExistingData = async () => { 
        try { 
            const response = await axios.get('/api/associations/existing-data'); 
            setStages(response.data.stageStatusMap || []);              
            setChangeOptionsMap(response.data.changeOptionsMap || {}); 
            
        
        } catch (err) { console.error('Error fetching existing data:', err); }  
    }; 

    // Fetch clients with active roles
    const fetchClients = async () => { 
        try {  
            const response = await axios.get('/api/associations/clients-with-active-roles');  
            setClients(response.data || []); 
        
        } catch (err) { console.error('Error fetching clients:', err); }  
    }; 

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore && !loading) {
                                                     
                    if (associations.length == 0) {
                        setPage(0);
                    }
                    else {
                        if (associations.length % limit == 0) {
                            setPage(Math.ceil(associations.length / limit)); // Triggers fetching new data
                        }
                    }

                }
            },
            { root: null, rootMargin: '0px', threshold: 1.0 } // Threshold adjusted to 1.0
        );
    
        const target = observerRef.current; // Attach to observerRef
    
        if (target) observer.observe(target);
    
        return () => {
            if (target) observer.unobserve(target); // Cleanup observer
        };
    }, [hasMore, loading]); // Ensure observer reattaches properly
    
   // Handle stage filter change
   const handleStageFilter = (stage) => {
       setSelectedStage(stage);
       setSelectedStatus();
       resetPagination();
   };

   // Handle status filter change
   const handleStatusFilter = (status) => {
       setSelectedStatus(status);
       setSelectedStage();
       resetPagination();
   };

   // Reset pagination and associations
   const resetPagination = useCallback(() => {
    setAssociations([]); // Clear previous data
    setPage(0); // Reset to first page
    setHasMore(true); // Assume there are more pages
}, []);


   // Handle visible fields change
   const handleVisibleFieldsChange = (event) => {
       setVisibleFields(event.target.value);
       localStorage.setItem('visibleFields', JSON.stringify(event.target.value));
       console.log(event.target.value);
   };

const toggleEditCdd = () => {
    setEditCdd(!editCdd);
};

useEffect(() => {
    if (editCdd) {
        // Open the modal when editCdd is true
        setModalVisible(false); // Close the main modal if open
    }
}, [editCdd]);


    
const handlePriorityChange = async (associationId, newStatus) => {
    setAssociations((prev) =>
        prev.map((assoc) =>
            assoc.id === associationId ? { ...assoc, priority: newStatus } : assoc
        )
    );
    try {
        // Send request to update the status
        await axios.put(`/api/associations/${associationId}/priority`, { priority: newStatus });

        // If currentAssociation is being displayed in the modal, update it as well
        if (currentAssociation && currentAssociation.id === associationId) {
            setCurrentAssociation((prev) => ({ ...prev, priority: newStatus }));
        }
    } catch (err) {
        console.error('Error changing status:', err);
        // Optionally revert optimistic update if there's an error
        setAssociations((prev) =>
            prev.map((assoc) =>
                assoc.id === associationId ? { ...assoc, priority: prev.find(a => a.id === associationId).priority } : assoc
            )
        );
    }

};

   // Change status of an association
   const handleChangeStatus = async (associationId, newStatus) => {
    // Optimistically update the status in local state
    setAssociations((prev) =>
        prev.map((assoc) =>
            assoc.id === associationId ? { ...assoc, status: newStatus } : assoc
        )
    );


    try {
        // Send request to update the status
        await axios.put(`/api/associations/${associationId}/status`, { status: newStatus });

        // If currentAssociation is being displayed in the modal, update it as well
        if (currentAssociation && currentAssociation.id === associationId) {
            setCurrentAssociation((prev) => ({ ...prev, status: newStatus }));
        }
    } catch (err) {
        console.error('Error changing status:', err);
        // Optionally revert optimistic update if there's an error
        setAssociations((prev) =>
            prev.map((assoc) =>
                assoc.id === associationId ? { ...assoc, status: prev.find(a => a.id === associationId).status } : assoc
            )
        );
    }
};
   // Load saved visible fields from local storage
   useEffect(() => {
       const savedFields = localStorage.getItem('visibleFields');
       if (savedFields) {
           try {
               const parsedFields = JSON.parse(savedFields);
               setVisibleFields(parsedFields);
           } catch (e) {
               console.error('Error parsing visibleFields from localStorage:', e);
           }
       }
   }, []);

   // Clear all filters
   const clearFilters = () => {
       resetPagination();
       setSelectedClient([]);
       setSelectedRole([]);
       setSelectedStage('');
   };

   // Open modal for candidate details
   const handleOpenModal = (association) => {
      console.log(association);
       setCurrentAssociation(association);
       setModalVisible(true);
   };

   // Close modal for candidate details
   const handleCloseModal = () => {
       setModalVisible(false);
       setCurrentAssociation(null);
   };

   // Open resume view
   const handleOpenResume = () => {
       setResumeVisible(true);
   };

   // Close resume view
   const handleCloseResume = () => {
       setResumeVisible(false);
   };

   // Handle action menu click for more options
   const handleActionMenuClick = (event) => {
       setAnchorEl(event.currentTarget);
   };

   // Close action menu
   const handleActionMenuClose = () => {
       setAnchorEl(null);
   };

   // Count active filters for display
   const activeFiltersCount = () => {
       return selectedClient.length + selectedRole.length;
   };

   return (
       <Container maxWidth="lg"> 
           Pipeline
           <input type="text" placeholder="Search" onChange={(e) => {  const newValue = e.target.value;
            if (newValue.trim() === '') {
                setLimit(10);
            } else {
                setLimit(1000);
            }
            setText(newValue); resetPagination(); console.log(newValue);fetchAssociations();}}/>
           <Box sx={{ display: 'flex', float: 'right', gap: 2 }}>
               <Button variant="outlined" startIcon={<FilterListIcon />} onClick={() =>setFiltersVisible(true)}>
                   {activeFiltersCount() === 0 ? '' : `(${activeFiltersCount()})`}
               </Button>
                <Button variant="outlined" startIcon={<ViewColumnIcon />} onClick={() =>setColumnsVisible(true)}>                </Button>
               
           </Box>
        <Drawer anchor="right" open={filtersVisible} onClose={() => setFiltersVisible(false)}>
            <Box sx={{ width: 300, p: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6">Filters</Typography>
                    <IconButton onClick={() => setFiltersVisible(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <FormControl fullWidth sx={{ my: 2 }}>
                    <Typography variant="subtitle1">Client</Typography>
                    <Autocomplete
                        multiple
                        options={clients}
                        getOptionLabel={(option) => option.name}
                        value={selectedClient.map(clientName => clients.find(client => client.name === clientName))}
                        onChange={(event, newValue) => {
                            setSelectedClient(newValue.map(client => client.name));
                            resetPagination();
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Select Client" />}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip key={option.name} label={option.name} {...getTagProps({ index })} />
                            ))
                        }
                    />
                </FormControl>
                <FormControl fullWidth sx={{ my: 2 }} visible={selectedClient.length}>
                    <Typography variant="subtitle1">Role</Typography>
                    <Autocomplete
                        multiple
                        options={clients.filter(client => selectedClient.includes(client.name)).flatMap(client => client.roles || [])}
                        getOptionLabel={(option) => option.name}
                        value={selectedRole.map(roleName => clients.flatMap(client => client.roles || []).find(role => role.name === roleName))}
                        onChange={(event, newValue) => {
                            setSelectedRole(newValue.map(role => role.name));
                            resetPagination();
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Select Role" />}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip key={option.name} label={option.name} {...getTagProps({ index })} />
                            ))
                        }
                    />
                </FormControl>
                <Typography variant="subtitle1" sx={{ my: 2 }}>Stage</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {Object.keys(stages).map((stage) => (
                        <Box key={stage}>
                            <Button variant={selectedStage === stage ? 'contained' : 'outlined'} onClick={() =>
                                handleStageFilter(stage)} fullWidth >
                                {`${stage} (${stageCounts[stage] || 0})`}
                            </Button>
                            {selectedStage === stage && stages[stage] && (
                                <Box sx={{ ml: 2 }}>
                                    {stages[stage].map((status) => (
                                        statusCounts[status] && (
                                            <Button key={status} variant="text" onClick={() =>
                                                handleStatusFilter(status)} sx={{ display: 'block', textAlign: 'left' }}>
                                                {status} ({statusCounts[status] || 0})
                                            </Button>
                                        )
                                    ))}
                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
                <Button variant="contained" color="secondary" onClick={clearFilters} sx={{ mt: 2 }}> Clear Filters </Button>
            </Box>
        </Drawer>
                          

           {/* Columns Drawer */}
           <Drawer anchor="right" open={columnsVisible} onClose={() =>setColumnsVisible(false)}>
               <Box sx={{ width: 300,p: 3 }}>
                   <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                       <Typography variant="h6">Columns</Typography>
                       <IconButton onClick={() =>setColumnsVisible(false)}>
                           <CloseIcon />
                       </IconButton>
                   </Box>
                   <FormControl fullWidth>
                       {fields.map((field) => (
                        <FormControlLabel key={field} control={
                            <Checkbox checked={visibleFields.includes(field)} onChange={(e) => {
                                const newVisibleFields = e.target.checked
                                    ? [...visibleFields, field]
                                    : visibleFields.filter(f => f !== field);
                                handleVisibleFieldsChange({ target: { value: newVisibleFields } });
                            }} />
                        } label={field} />
                       ))}
                   </FormControl>
               </Box>
           </Drawer>

           {/* Associations Table */}
           <TableContainer component={Paper}>
               <Table>
                   <TableHead>
                       <TableRow>
                           <TableCell>Avatar</TableCell>
                           {Array.isArray(visibleFields) && visibleFields.map((field) => (<TableCell key={field}>{field}</TableCell>))}                           
                       </TableRow>
                   </TableHead>

                   {/* Table Body */}
                <TableBody>{associations.map((association,index) =>
                    (<TableRow key={`${association._id}-${index}`}>
                                                <TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}><Avatar src={association.dp || ''} /></TableCell>                          
                                                {visibleFields.includes('Name') && association.name && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.name}</TableCell>)}
                                                {visibleFields.includes('Email') && association.email && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.email}</TableCell>)}
                                                {visibleFields.includes('Phone') && association.phone && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.phone}</TableCell>)}
                                                {visibleFields.includes('Status') && association.status && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.status}</TableCell>)}
                                                {visibleFields.includes('Current Company') && association.currentCompany && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.currentCompany}</TableCell>)}
                                                {visibleFields.includes('Current Role') && association.currentRole && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.currentRole}</TableCell>)}
                                                {visibleFields.includes('Compensation') && association.compensation && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.compensation}</TableCell>)}
                                                {visibleFields.includes('Gender') && association.gender && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.gender}</TableCell>)}
                                                {visibleFields.includes('Marital Status') && association.maritalStatus && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.maritalStatus}</TableCell>)}
                                                {visibleFields.includes('Expectation') && association.expectation && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.expectation}</TableCell>)}
                                                {visibleFields.includes('Experience Years') && association.experienceYears && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.experienceYears}</TableCell>)}
                                                {visibleFields.includes('Notice Period') && association.noticePeriod && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.noticePeriod}</TableCell>)}
                                                {visibleFields.includes('LinkedIn') && association.linkedIn && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.linkedIn}</TableCell>)}
                                                {visibleFields.includes('Resume') && association.resume && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenResume(); }}>View Resume</TableCell>)}
                                                {visibleFields.includes('Rating') && association.rating && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.rating}</TableCell>)}
                                                {visibleFields.includes('Category') && association.category && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.category}</TableCell>)}
                                                {visibleFields.includes('Skills') && association.skills && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.skills}</TableCell>)}
                                                {visibleFields.includes('Education') && association.education && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.education}</TableCell>)}
                                                {visibleFields.includes('Experience') && association.experience && (<TableCell onClick={(e)=>{ e.stopPropagation(); handleOpenModal(association) }}>{association.experience}</TableCell>)}                                                   
                     </TableRow>)
                 )}</TableBody>  
                </Table>  
            </TableContainer>  
            <div id="scroll-anchor" ref={observerRef} style={{ height: '1px' }} />

            {/* Candidate Modal */}
            
            {currentAssociation && (<CandidateModal visible={modalVisible} onClose={handleCloseModal} candidate={currentAssociation} changeOptionsMap={changeOptionsMap} handleChangeStatus={handleChangeStatus} editCdd={toggleEditCdd} handlePriorityChange={handlePriorityChange} ></CandidateModal>)}
                 
    <>
        {editCdd && (
            <Modal open={editCdd} onClose={() => setEditCdd(false)}>
                 <Box sx={{  width: '80%', margin: 'auto', mt: 5, p: 4, backgroundColor: 'background.paper', borderRadius: 2, maxHeight: '90vh', overflowY: 'auto'  }}>
                    <IconButton onClick={() => setEditCdd(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                        <CloseIcon />
                    </IconButton>
                <CandidateForm user={currentAssociation} />
                </Box>
            </Modal>
        )}
    </>
        </Container>  
     );  
};  

export default Associations;