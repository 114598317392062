import React from 'react';
import { List, ListItem, ListItemText, Typography, Box } from '@mui/material';

const EmailThread = ({ emails }) => {
  return (
    <Box p={3}>
      <Typography variant="h5">Email Thread Viewer</Typography>
      <List>
        {emails.map((email, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`From: ${email.from} | To: ${email.to}`}
              secondary={`Subject: ${email.subject} | Date: ${email.date}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default EmailThread;
