import React, { useState } from 'react';
import { Input, Select, Button, message } from 'antd';
import axios from 'axios';

const { TextArea } = Input;
const { Option } = Select;

const BulkUpdateComponent = () => {
  const [type, setType] = useState('institution');
  const [bulkText, setBulkText] = useState('');

  const handleBulkUpdate = async () => {
    try {
      const updates = bulkText.split('\n').map(line => {
        const [oldName, newName] = line.split(',').map(item => item.trim());
        return { oldName, newName };
      });

      await axios.post('/api/bulk-update', { type, updates });
      message.success('Items updated successfully');
    } catch (error) {
      message.error('Error updating items: ' + (error.response?.data?.message || error.message));
    }
  };

  return (
    <div>
      <Select defaultValue="institution" onChange={(value) => setType(value)} style={{ width: 200 }}>
        <Option value="institution">Institution</Option>
        <Option value="organization">Organization</Option>
        <Option value="skill">Skill</Option>
      </Select>
      <TextArea
        rows={10}
        value={bulkText}
        onChange={(e) => setBulkText(e.target.value)}
        placeholder="Enter items in the format: oldName, newName"
        style={{ marginTop: 20, width: '100%' }}
      />
      <Button type="primary" onClick={handleBulkUpdate} style={{ marginTop: 20 }}>
        Update
      </Button>
    </div>
  );
};

export default BulkUpdateComponent;
