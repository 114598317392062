import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CandidateForm = () => {
  const [jobs, setJobs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [formData, setFormData] = useState({
    resume: '',
    phone: '',
    linkedin: '',
    email: '',
    ctc: '',
    noticePeriod: ''
  });

  useEffect(() => {
    // Fetch jobs and categories from the backend
    const fetchJobsAndCategories = async () => {
      const jobResponse = await axios.get('/api/active-jobs');
      const categoryResponse = await axios.get('/api/categories');
      setJobs(jobResponse.data);
      setCategories(categoryResponse.data);
    };
    fetchJobsAndCategories();
  }, []);

  const handleApply = async (jobId) => {
    // Send the application data to the backend
    const response = await axios.post(`/api/apply/${jobId}`, formData);
    if (response.status === 200) {
      alert('Application submitted successfully!');
    } else {
      alert('Failed to submit application.');
    }
  };

  const filteredJobs = selectedCategory
    ? jobs.filter(job => job.category === selectedCategory)
    : jobs;

  return (
    <div>
      <h1>Active Jobs</h1>
      <select
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        <option value="">All Categories</option>
        {categories.map(category => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>

      <div>
        {filteredJobs.map(job => (
          <div key={job.id}>
            <h3>{job.title}</h3>
            <p>{job.description}</p>
            <button onClick={() => handleApply(job.id)}>Apply</button>
          </div>
        ))}
      </div>

      <form>
        <input
          type="file"
          onChange={(e) => setFormData({ ...formData, resume: e.target.files[0] })}
        />
        <input
          type="text"
          placeholder="Phone"
          value={formData.phone}
          onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
        />
        <input
          type="text"
          placeholder="LinkedIn"
          value={formData.linkedin}
          onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
        />
        <input
          type="email"
          placeholder="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        <input
          type="text"
          placeholder="CTC"
          value={formData.ctc}
          onChange={(e) => setFormData({ ...formData, ctc: e.target.value })}
        />
        <input
          type="text"
          placeholder="Notice Period"
          value={formData.noticePeriod}
          onChange={(e) => setFormData({ ...formData, noticePeriod: e.target.value })}
        />
      </form>
    </div>
  );
};

export default CandidateForm;
