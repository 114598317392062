import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Form, Input, Button, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { login, oauthLogin, handleGoogleLogin  } = useAuth();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const user = await login(values.email, values.password);
      setLoading(false);
      const returnTo = localStorage.getItem('returnTo') || '/';
      localStorage.removeItem('returnTo');
      navigate(returnTo);
    } catch (error) {
      setLoading(false);
    }
  };
/*
  useEffect(() => {
    const handleCredentialResponse = async (response) => {
      console.log('Encoded JWT ID token: ' + response.credential);
      try {
        // Send the ID token to your server to get an access token (and possibly a refresh token)
        const tokenResponse = await axios.post('/email/api/oauth2/token', {
          id_token: response.credential,
        });
  
        const { access_token: accessToken } = tokenResponse.data;
        console.log('Google Access Token:', accessToken);
  
        // Now perform the login using the OAuth token (or both tokens)
        await oauthLogin(response.credential, accessToken); // This sends both tokens to your backend
        navigate('/'); // Redirect after successful login
      } catch (error) {
        console.error('OAuth login failed:', error);
      }
    };
  
    window.google.accounts.id.initialize({
      client_id: '14388555053-la7s7phrsfaoemqnrusr4njrlijhd3ke.apps.googleusercontent.com',
      callback: handleCredentialResponse,
    });
  
    window.google.accounts.id.renderButton(
      document.getElementById('google-signin-button'),
      { theme: 'outline', size: 'large' }
    );
  
    window.google.accounts.id.prompt();
  }, [navigate, oauthLogin]);

  /*/ 
  useEffect(() => {
    const handleCredentialResponse = async (response) => {
      console.log('Encoded JWT ID token: ' + response.credential);
      try {
        await oauthLogin(response.credential);
        navigate('/');
      } catch (error) {
        console.error('OAuth login failed:', error);
      }
    };

    window.google.accounts.id.initialize({
      client_id: '14388555053-la7s7phrsfaoemqnrusr4njrlijhd3ke.apps.googleusercontent.com',
      callback: handleCredentialResponse,
    });

    window.google.accounts.id.renderButton(
      document.getElementById('google-signin-button'),
      { theme: 'outline', size: 'large' }
    );

    window.google.accounts.id.prompt();
  }, [navigate, oauthLogin]);
//*/
  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <img src="https://hireveda.com/content/images/size/w1000/2023/05/HireVeda-6.png" alt="HireVeda Logo" style={{ width: '100px' }} />
      </div>
      <Card title="Login" style={{ width: 300, margin: 'auto auto' }}>
        <Form onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} block>
              Log in
            </Button>
            <div id="google-signin-button"  onClick={handleGoogleLogin} style={{ marginTop: '10px' }}></div>
            <Link to="/signup">
              <Button type="link" block style={{ marginTop: '10px' }}>
                Don't have an account? Signup
              </Button>
            </Link>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
