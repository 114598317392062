import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tabs, Table, Button, Input, Modal, Form, Select, notification } from 'antd';

const { TabPane } = Tabs;
const { Option } = Select;
const { Search } = Input;

const AdminMergeComponent = () => {
  const [activeTab, setActiveTab] = useState('degrees');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [primaryRecord, setPrimaryRecord] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchCollectionData(activeTab);
  }, [activeTab]);

  const fetchCollectionData = (collection) => {
    axios.get(`/api/${collection}`)
      .then(response => {
        setData(response.data);
        setFilteredData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  const handleSearch = (value) => {
    if (!value) {
      setFilteredData(data);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const filtered = data.filter(item =>
      Object.keys(item).some(key =>
        item[key] && item[key].toString().toLowerCase().includes(lowercasedValue)
      )
    );
    setFilteredData(filtered);
  };

  const handleSync = () => {
    axios.post(`/api/sync/${activeTab}`)
      .then(() => {
        notification.success({ message: 'Synchronization successful' });
        fetchCollectionData(activeTab);
      })
      .catch(error => {
        console.error('Error syncing data:', error);
        notification.error({ message: 'Synchronization failed', description: error.message });
      });
  };

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleSave = async () => {
    const values = await form.validateFields();
    const method = editingRecord ? axios.put : axios.post;
    const url = editingRecord ? `/api/${activeTab}/${editingRecord._id}` : `/api/${activeTab}`;
    method(url, values)
      .then(() => {
        notification.success({ message: 'Operation successful' });
        setIsModalVisible(false);
        setEditingRecord(null);
        fetchCollectionData(activeTab);
      })
      .catch(error => {
        console.error('Error saving data:', error);
        notification.error({ message: 'Operation failed', description: error.message });
      });
  };

  const handleDelete = (recordId) => {
    axios.delete(`/api/${activeTab}/${recordId}`)
      .then(() => {
        notification.success({ message: 'Deletion successful' });
        fetchCollectionData(activeTab);
      })
      .catch(error => {
        console.error('Error deleting record:', error);
        notification.error({ message: 'Deletion failed', description: error.message });
      });
  };

  const handleMerge = () => {
    const mergeIds = selectedRowKeys.filter(key => key !== primaryRecord);
    axios.post(`/api/${activeTab}/merge`, { primaryId: primaryRecord, mergeIds })
      .then(() => {
        notification.success({ message: 'Merge successful' });
        setSelectedRowKeys([]);
        setPrimaryRecord(null);
        fetchCollectionData(activeTab);
      })
      .catch(error => {
        console.error('Error merging records:', error);
        notification.error({ message: 'Merge failed', description: error.message });
      });
  };

  // Dynamically generate columns from data keys
  const columns = Object.keys(data[0] || {}).filter(key => key !== '_id').map(key => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    dataIndex: key,
    sorter: (a, b) => (a[key] || '').localeCompare(b[key] || ''),
    onFilter: (value, record) => record[key] && record[key].toString().toLowerCase().includes(value.toLowerCase()),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder={`Search ${key}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button onClick={confirm} type="primary" size="small" style={{ width: 90, marginRight: 8 }}>Search</Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>Reset</Button>
      </div>
    )
  }));

  // Add the actions column
  columns.push({
    title: 'Actions',
    key: 'actions',
    render: (_, record) => (
      <>
        <Button onClick={() => handleEdit(record)} style={{ marginRight: 8 }}>Edit</Button>
        <Button onClick={() => handleDelete(record._id)} type="danger">Delete</Button>
      </>
    )
  });

  return (
    <div>
      <Tabs defaultActiveKey="degrees" onChange={key => setActiveTab(key)}>
        {['degrees', 'institutions', 'departments', 'designations', 'organizations', 'skills', 'locations'].map(tab => (
          <TabPane tab={tab.charAt(0).toUpperCase() + tab.slice(1)} key={tab}>
            <Button onClick={() => setIsModalVisible(true)} style={{ marginBottom: 16 }}>Add New</Button>
            <Button onClick={handleSync} style={{ marginBottom: 16, marginLeft: 8 }}>Sync Data</Button>
            <Search placeholder="Search..." onSearch={handleSearch} style={{ width: 200, marginBottom: 16 }} />
            <Select
              style={{ width: 200, marginBottom: 16 }}
              placeholder="Select Primary Record"
              onChange={setPrimaryRecord}
              value={primaryRecord}
            >
              {filteredData.filter(item => selectedRowKeys.includes(item._id)).map(item => (
                <Option key={item._id} value={item._id}>{item.name}</Option>
              ))}
            </Select>
            <Button onClick={handleMerge} type="primary" disabled={!primaryRecord || selectedRowKeys.length <= 1}>Merge Selected</Button>
            <Table
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys,
                onChange: setSelectedRowKeys,
              }}
              columns={columns}
              dataSource={filteredData}
              rowKey="_id"
            />
            <Modal
              title={editingRecord ? "Edit Record" : "Add Record"}
              visible={isModalVisible}
              onOk={handleSave}
              onCancel={() => {
                setIsModalVisible(false);
                setEditingRecord(null);  // Clear editing record when closing modal
              }}
            >
              <Form form={form} layout="vertical">
                {columns.filter(col => col.dataIndex !== 'actions').map(col => (
                  <Form.Item name={col.dataIndex} label={col.title} key={col.dataIndex} rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                ))}
              </Form>
            </Modal>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default AdminMergeComponent;
